import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

export default class Toast {
    static error(message: string = "") {
        toast.error(message, {
            position: toast.POSITION.TOP_LEFT,
            autoClose: 15000,
        });
    }
    static success(message: string = "") {
        toast.success(message,{
            position: toast.POSITION.TOP_LEFT,
            autoClose: 15000,
        });
    }
    static info(message: string = "") {
        toast.info(message,{
            position: toast.POSITION.TOP_LEFT,
            autoClose: 15000,
        });
    }
}