import {BigNumber, constants, ContractTransaction, Transaction} from "ethers";
import {
    BatchNftSender__factory,
    HeroesOfMetaverseItemToken,
    HeroesOfMetaverseItemToken__factory
} from "../../../typechain";
import Web3Service from "../web3.service";
import Toast from "../../../utils/toast";

export async function GetItemContract(): Promise<HeroesOfMetaverseItemToken>{
    var signer = await Web3Service.GetSigner();
    return HeroesOfMetaverseItemToken__factory.connect(process.env.REACT_APP_NFT_ADDRESS, signer);
}

export async function GetItemsBalance(): Promise<BigNumber>{
    var contract = await GetItemContract();
    console.log("item contract", contract);
    return contract.balanceOf(await contract.signer.getAddress());
}

export async function ListMyItemIds(): Promise<BigNumber[]> {
    var contract = await GetItemContract();

    var wallet = await contract.signer.getAddress();
    var balance = await contract.balanceOf(wallet);

    var ids: BigNumber[] = [];
    for(var i=constants.Zero;i.lt(balance);i=i.add(constants.One)) {
        do {
            var retry = false;
            try {
                ids.push(await contract.tokenOfOwnerByIndex(wallet, i));
            }
            catch (e) {
                console.log(e);
                retry = true;
            }
        } while (retry);
    }

    return ids;
}

export async function MultisenderIsApprovedForAllItems(): Promise<boolean> {
    let itemContract = await GetItemContract();
    return await itemContract.isApprovedForAll(await itemContract.signer.getAddress(), process.env.REACT_APP_MULTISENDER_ADDRESS)
}

export async function MultisenderApproveForAllItems(): Promise<ContractTransaction>{
    let itemContract = await GetItemContract();
    var tx = await itemContract.setApprovalForAll(process.env.REACT_APP_MULTISENDER_ADDRESS, true);
    //Toast.info("tx submitted. Waiting for confirmation");
    return tx;
}

// enable multisender to manipulate NFTs in the user behalf
export async function EnsureMultisenderIsApprovedForAllItems(): Promise<ContractTransaction> {
    let itemContract = await GetItemContract();

    // approve for all. the multisender needs to be allowed to manipulate the nft for the users
    if(!await itemContract.isApprovedForAll(await itemContract.signer.getAddress(), process.env.REACT_APP_MULTISENDER_ADDRESS)) {
        // todo: emit a message to show the approval is accepted and show the tx to the user
        let tx = await itemContract.setApprovalForAll(process.env.REACT_APP_MULTISENDER_ADDRESS, true);
        //Toast.info("tx submitted. Waiting for confirmation");
        return tx;
    }
}

export async function MultiSenderDepositItems(): Promise<ContractTransaction> {
    await EnsureMultisenderIsApprovedForAllItems();
    let signer = await Web3Service.GetSigner();

    let ids = await ListMyItemIds();

    Toast.info("Transferring your items");
    let multisender = BatchNftSender__factory.connect(process.env.REACT_APP_MULTISENDER_ADDRESS, signer);
    let tx = await multisender.SendERC721ToBank(process.env.REACT_APP_NFT_ADDRESS, ids);
    return tx;
}