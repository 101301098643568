import "./login.scss";
import React, { Component } from "react";
import LangController from "../../../controllers/language/lang";

import TitleEffectComponent from "../../elements/title-effect/title-effect";
import LogotipoComponent from "../../elements/logotipo/logotipo";

const language = LangController.getLanguage(null);

export default class LoginDashLoginPageComponent extends Component {
    private constructor(readonly props: any) {
        super(props);
    }
    public readonly state = {
        language: LangController.getLanguage(null)
    };

    componentDidMount() { }

    sendLogin(url: string) {
        console.log(url);
        window.location.href = url;
    }
    backPage() {
        window.location.href = "/";
    }

    render() {
        const data = language.pages.dash_login;
        return (
            <section className="banner-login-page-component">
                <div className="banner-video-box">
                    <video
                        className="banner-video"
                        preload="true"
                        autoPlay
                        muted
                        loop
                        playsInline
                    >
                        <source
                            src={require(`../../../assets/video/${data.banner.video}`)}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className="banner-content">
                    <div className="banner-title">
                        <TitleEffectComponent
                            language={data.banner.title}
                            fontSize={"55px"}
                            fontSizeEffect={"70px"}
                        />
                    </div>
                    <div className="banner-frame noselect">
                        <div className="box-frame">
                            <LogotipoComponent />
                            <div className="login-title Ethnocentric">
                                {data.login.title}
                            </div>
                            <div className="login-resume Exo">
                                {data.login.resume}
                            </div>
                            {
                                data.login.buttons.google.active ?
                                    (
                                        <button
                                            className="btn btn-social-login Exo"
                                            onClick={() => this.sendLogin(data.login.buttons.google.url)}
                                        >
                                            <img src={require("../../../assets/images/google-logo.png")} alt='google' />
                                            {data.login.buttons.google.title}
                                        </button>
                                    ) : ("")
                            }
                            {
                                data.login.buttons.facebook.active ?
                                    (
                                        <button
                                            className="btn btn-social-login Exo"
                                            onClick={() => this.sendLogin(data.login.buttons.facebook.url)}
                                        >
                                            <img src={require("../../../assets/images/facebook-logo.png")} alt='facebook' />
                                            {data.login.buttons.facebook.title}
                                        </button>
                                    ) : ("")
                            }
                            {
                                data.login.buttons.apple.active ?
                                    (
                                        <button
                                            className="btn btn-social-login Exo"
                                            onClick={() => this.sendLogin(data.login.buttons.apple.url)}
                                        >
                                            <img src={require("../../../assets/images/apple-logo.png")} alt='apple' />
                                            {data.login.buttons.apple.title}
                                        </button>
                                    ) : ("")
                            }
                            <div className="login-back" onClick={() => this.backPage()}>
                                {data.login.back}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
