import "./in-game.scss";
import React, { Component } from "react";
import LangController from "../../../controllers/language/lang";
import TitleEffectComponent from "../../elements/title-effect/title-effect";
import CarouselComponent from "../../elements/carousel/carousel";

export default class InGameComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {
    language: LangController.getLanguage(null)
  };

  async componentDidMount() {}

  render() {
    const data: any = this.state.language.pages.home.game;
    return (
      <section className="in-game-component pt-5">
        <div className="row m-0">
          <div className="col-12 in-title">
            <TitleEffectComponent
              language={data.title}
              fontSize={"45px"}
              fontSizeEffect={"1.8em"}
            />
            <div className="resume">{data.resume}</div>
          </div>
          <div className="col-12 p-0 middle">
            <div className="carousel-box my-5 py-5">
              <CarouselComponent language={data.carousel} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
