import "./footer.scss";
import React, { Component } from "react";
import LangController from "../../../controllers/language/lang";

export default class FooterComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {
    language: LangController.getLanguage(null)
  };

  async componentDidMount() {}

  render() {
    const data: any = this.state.language.pages.home.footer;
    return (
      <section className="footer-component">
        <div className="menu middle">
          {data.menu.map((item: any, index: any) => (
            <div key={index} className="item noselect">
              <a href={item.url} target={item.target}>
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </section>
    );
  }
}
