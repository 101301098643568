import "./header.scss";
import React, { Component } from "react";
import $ from "jquery";

import LangController from "../../../controllers/language/lang";

import enviroment from "../../../configuration/enviroment";
import StartGameButtonComponent from "../../elements/buttons/start-game/start-game";
import LanguageComponent from "../../elements/language/language";
import LogotipoComponent from "../../elements/logotipo/logotipo";

export default class HeaderLandingPageComponent extends Component {
  private constructor(readonly props: any) {
    super(props);

    this.changeMenuMobile = this.changeMenuMobile.bind(this);
    this.checkMenuMobile = this.checkMenuMobile.bind(this);
  }
  public readonly state = {
    showMenu: false,
    language: LangController.getLanguage(null)
  };

  async componentDidMount() { }

  changeMenuMobile() {
    const show = !this.state.showMenu;
    this.setState({ showMenu: show });
    this.checkMenuMobile(show);
  }
  checkMenuMobile(show: boolean) {
    if (show) {
      $("body").css({ height: "100%", overflow: "hidden" });
    } else {
      $("body").css({ height: "auto", overflow: "auto" });
    }
  }

  render() {
    const data = this.state.language.pages.home.header;
    return (
      <section className="header-landing-page-component">
        <div className="content">
          <div className="col-auto">
            <div className="row">
              <LanguageComponent language={enviroment.LANGUAGE} />
                {/* Whitepaper link was removed temporarily */}
            </div>
          </div>
          <div className="col">
            <div className="col-logo middle">
              <LogotipoComponent />
            </div>
          </div>
        </div>
        <div className="button">
          <StartGameButtonComponent />
          <div className="menu-mobile" onClick={() => this.changeMenuMobile()}>
            <span className="fa fa-bars"></span>
          </div>
        </div>
        {!this.state.showMenu ? (
          ""
        ) : (
          <div className="mobile-box-menu">
            <div className="row">
              <div className="col-12 middle mt-5">
                <LanguageComponent language={enviroment.LANGUAGE} />
              </div>
              <div className="col-12 middle my-5">
                {/* Whitepaper link was removed temporarily */}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
