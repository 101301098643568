import React, { Component } from "react";

import Emitter from "../../../utils/emitter";
import DialogComponent from "../../elements/dialog/dialog";
import DialogConfirmComponent from "../../elements/dialog-confirm/dialog-confirm";

import LangController from "../../../controllers/language/lang";
import MetamaskController from "../../../controllers/metamask/metamask";
import CratesController from "../../../controllers/crates/crates";
import GameController from "../../../controllers/game/game";
import SocialLoginController from "../../../controllers/social-login/social-login";

const language = LangController.getLanguage(null).shared.dialog;

export default class DialogsDashboardComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.changeDialog = this.changeDialog.bind(this);
        this.changeButtonDialog = this.changeButtonDialog.bind(this);
        this.changeData = this.changeData.bind(this);
        this.changeButtonLoad = this.changeButtonLoad.bind(this);
        this.changeRetry = this.changeRetry.bind(this);

        MetamaskController.ctx = this;
        CratesController.ctx = this;
        GameController.ctx = this;
    }
    public readonly state: any = {
        openDialog: "",
        data: null,
        loading: false,
        retry: false,
        awaitMessage: ""
    }

    componentDidMount() {
        Emitter.on("dialog-button-retry", (data: boolean) => {
            this.changeRetry(data);
        });
        Emitter.on("dialog-button-load", (data: boolean) => {
            this.changeButtonLoad(data);
        });
        Emitter.on("dialog-await-message", (message: string) => {
            this.changeAwaitMessage(message);
        })
        Emitter.on("dialog", (data: any) => {
            switch (data.type) {
                case "open":
                    this.changeData(data.item);
                    this.changeDialog(data.name);
                    break;
                case "close":
                    this.changeDialog("");
                    break;
                case "button":
                    this.changeButtonDialog(data.name);
                    break;
            }
        });
        this.changeAwaitMessage(null);
    }

    changeRetry(data: boolean) {
        this.setState({ retry: data });
    }

    changeButtonLoad(data: any) {
        this.setState({ loading: data });
    }

    changeAwaitMessage(data: any) {
        if (data === null) {
            data = LangController.getLanguage(null).shared.await;
        }
        this.setState({ awaitMessage: data })
    }

    changeData(data: any) {
        this.setState({ data: data });
    }

    changeDialog(id: any) {
        this.setState({ openDialog: id, retry: false });
    }
    async changeButtonDialog(type: any) {
        switch (type) {
            case "connect":
                if (!MetamaskController.connected && !MetamaskController.isBinance) {
                    if (await MetamaskController.ConnectWallet())
                        this.changeDialog("");
                }
                else if (MetamaskController.connected && !MetamaskController.isBinance) {
                    if (await MetamaskController.SwitchToBinance()) {
                        this.changeDialog("");
                    }
                }
                break;
            case "deposit":
                console.log("signing up");
                if ((SocialLoginController.getUser() != null)) {
                    if (await CratesController.DepositCrates()) {
                        this.changeDialog("");
                    }
                }

                break;
            case "download":
                if (!GameController.checkGameDownload()) {
                    await GameController.GameDownload(language.download);
                }
                this.changeDialog("");
                break;
            case "start":
                GameController.StartGame();
                break;
            case "send_wallet":
                if (!this.state.loading) {
                    this.changeButtonLoad(true);
                    Emitter.emit("confirm_send_wallet", this.state.data);
                }
                break;
            case "send_game":
                if (!this.state.loading) {
                    this.changeButtonLoad(true);
                    Emitter.emit("confirm_send_game", this.state.data);
                }
                break;
        }
        this.setState({});
    }

    render() {
        const data = language;
        return (
            <div className="all-dialogs">
                {
                    this.state.openDialog === "connect" ?
                        (
                            <DialogComponent
                                language={data.connect}
                                name={"connect"}
                                button={
                                    !MetamaskController.connected ?
                                        data.connect.connect
                                        :
                                        data.connect.switch
                                }
                                icon={require("../../../assets/icons/icon-metamask.png")}
                            />
                        ) : ("")
                }
                {
                    this.state.openDialog === "deposit" ?
                        (
                            <DialogComponent
                                language={data.deposit}
                                name={"deposit"}
                                button={data.deposit.button}
                            />
                        ) : ("")
                }
                {
                    this.state.openDialog === "download" ?
                        (
                            <DialogComponent
                                language={data.download}
                                name={"download"}
                                button={data.download.button}
                            />
                        ) : ("")
                }
                {
                    this.state.openDialog === "send_wallet" ?
                        (
                            <DialogComponent
                                language={data.send_wallet}
                                name={"send_wallet"}
                                icon= {this.state.loading ? require("../../../assets/images/loading.gif") : undefined}
                                button={
                                    this.state.loading ?
                                        this.state.awaitMessage
                                        :
                                        this.state.retry ?
                                            data.send_game.retry 
                                            :
                                            data.send_wallet.button
                                }
                                wallet={true}
                            />
                        ) : ("")
                }
                {
                    this.state.openDialog === "send_game" ?
                        (
                            <DialogComponent
                                language={data.send_game}
                                name={"send_game"}
                                icon= {this.state.loading ? require("../../../assets/images/loading.gif") : undefined}
                                button={
                                    this.state.loading ?
                                        this.state.awaitMessage
                                        :
                                        this.state.retry ?
                                            data.send_game.retry
                                            :
                                            data.send_game.button
                                }
                                wallet={true}
                            />
                        ) : ("")
                }
                {
                    this.state.openDialog === "confirm_send" ?
                        (
                            <DialogConfirmComponent
                                language={data.confirm_send}
                                name={"confirm_send"}
                                button={
                                    this.state.loading ?
                                        this.state.awaitMessage
                                        :
                                        this.state.retry ?
                                            data.confirm_send.retry
                                            :
                                            data.confirm_send.button
                                }
                            />
                        ) : ("")
                }
            </div>
        );
    }
}