import { LanguageModel } from "../../../../models/language/language";
const language: LanguageModel = new LanguageModel({});

const stashPage = language.pages.stash;

stashPage.title = "SEUS ITENS <text-yellow> MAIS VALIOSOS </text-yellow> ESTÃO AQUI";
stashPage.empty = "Não há items para exibir!";
stashPage.cart.send_to_wallet = "Enviar para Wallet";
stashPage.cart.sent_to_game = "Enviar para o Jogo";
stashPage.cart.wallet = "Processando Saque...";
stashPage.cart.unit = "Unidade";
stashPage.cart.tooltip = "Há um saque em andamento, por favor aguarde o processamento dele para iniciar um novo.";
stashPage.cart.blocked = "Este item não pode ser sacado";
stashPage.cart.blockedAssociate = "Verifique sua Wallet";
stashPage.cart.blockedTooltip = "Por favor, conecte a Wallet associada: ";
stashPage.cart.selectedItem = "item selecionado";
stashPage.cart.selectedItems = "itens selecionados";
stashPage.menu.types = [
    {
        value: "backend",
        title: "No Jogo"
    },
    {
        value: "blockchain",
        title: "Blockchain"
    }
];
stashPage.menu.buttons = [
    {
        name: "all",
        title: "Todos Itens"
    },
    // {
    //     name: "nfts",
    //     title: "NFTs"
    // },
    // {
    //     name: "swords",
    //     title: "Espadas"
    // },
    // {
    //     name: "helmets",
    //     title: "Capacetes"
    // },
    // {
    //     name: "armors",
    //     title: "Armaduras"
    // },
    // {
    //     name: "neckles",
    //     title: "Colares"
    // },
    // {
    //     name: "axes",
    //     title: "Machados"
    // }
];
stashPage.menu.wallet = "Minha Wallet";
stashPage.menu.order = [
    {
        value: "ORDER_BY_CREATED_AT_ASC",
        title: "Recentes"
    },
    {
        value: "ORDER_BY_CREATED_AT_DESC",
        title: "Antigos"
    }
];

export { stashPage }