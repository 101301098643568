import { LanguageModel } from "../../../models/language/language";
import { homePage } from "./elements/home-page";
import { dashLogin } from "./elements/dash-login-page";
import { dashHome } from "./elements/dash-home-page";
import { stashPage } from "./elements/stash";
import { cratesPage } from "./elements/nft-crates";
import { shared } from "./elements/shared";

const languagePtBR: LanguageModel = new LanguageModel({});

languagePtBR.pages.home = homePage;
languagePtBR.pages.dash_login = dashLogin;
languagePtBR.pages.dashboard = dashHome;
languagePtBR.pages.stash = stashPage;
languagePtBR.pages.crates = cratesPage;
languagePtBR.shared = shared;

export default languagePtBR;