import "./header.scss";
import React, { Component } from "react";
import $ from "jquery";

import LangController from "../../../controllers/language/lang";
import MetamaskController from "../../../controllers/metamask/metamask";
import SocialLoginController from "../../../controllers/social-login/social-login";
import ButtonPlayComponent from "../../shared/button-play/button-play";
import Emitter from "../../../utils/emitter";

import enviroment from "../../../configuration/enviroment";
import { LanguageModel } from "../../../models/language/language";

export default class HeaderDashboardComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.languageOnLoad = this.languageOnLoad.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeMenuMobile = this.changeMenuMobile.bind(this);
        this.checkPage = this.checkPage.bind(this);
        this.checkWalletInList = this.checkWalletInList.bind(this);

        MetamaskController.ctx = this;
    }
    public readonly state: any = {
        showMenu: false,
        menuMobileFixed: false,
        language: LangController.getLanguage(null)
    }

    async componentDidMount() {
        this.languageOnLoad();
        this.checkPage();
        this.checkWalletInList();
        await Promise.all([
            MetamaskController.IsWalletConnected(),
            MetamaskController.IsConnectedToChainId()
        ]);
        await this.checkAssociateWallet();
        await MetamaskController.CheckWalletInList();
        Emitter.on("MetamaskRefresh", () => {
            this.setState({});
        })
    }

    languageOnLoad() {
        const lang = localStorage.getItem('lang');
        if (lang) {
            $(".language").val(lang);
        } else {
            $(".language").val(LangController.lang);
        }
    }
    checkPage() {
        var url = window.location.pathname;
        var spl = url.split("/");
        return String(spl[2]);
    }
    changeLanguage(e: any) {
        const lang = e.target.value;
        this.setState({ lang: lang });
        LangController.getLanguage(lang);
        localStorage.setItem('lang', lang);
        window.location.reload();
    }
    changeMenuMobile() {
        const show = !this.state.showMenu;
        this.setState({ showMenu: show });
        this.checkMenuMobile(show);
    }
    checkMenuMobile(show: boolean) {
        if (show) {
            $("body").css({ height: "100%", overflow: "hidden" });
        } else {
            $("body").css({ height: "auto", overflow: "auto" });
        }
    }
    checkMenuDropdownActive(item: any) {

    }
    changeMenuDropdown(item: any) {
        if (item.id === 'logout') {
            SocialLoginController.removeUser();
        }
    }
    async checkAssociateWallet() {
        if (
            MetamaskController.connected &&
            MetamaskController.isBinance
        ) {
            const profile = await SocialLoginController.getProfileState();
            const wallet = await MetamaskController.GetSigner();
            if (profile.walletAddress !== "") {
                Emitter.emit('AssociateWallet', true);
                if (String(profile.walletAddress).toLowerCase() === String(wallet).toLowerCase()) {
                    Emitter.emit('IsAssociateWallet', true);
                } else {
                    Emitter.emit('IsAssociateWallet', false);
                }
            }
        }
    }

    checkWalletInList() {
        if (enviroment.CHECK_WITELIST && !MetamaskController.walletInList) {
            var language: LanguageModel = this.state.language;
            let i = null;
            language.pages.dashboard.header.menu.filter((menu, index) =>{
                if(menu.id === 'stash')
                i = index;
            })
            language.pages.dashboard.header.menu[i].badge = "SOON";
            language.pages.dashboard.header.menu[i].url = "#";
            this.setState({ language: language })
        }
    }

    render() {
        const language = this.state.language;
        const data = language.pages.dashboard.header;
        const game = language.pages.dashboard.game;
        return (
            <header className='dash-header'>
                <nav className="navbar padding navbar-expand-lg navbar-light noselect">
                    <div
                        className={
                            'navbar-collapse ' +
                            (this.state.showMenu ? "active" : "inactive")
                        }
                    >
                        <div className="header-menu">
                            <div className="header-logo">
                                <a href="/">
                                    <img
                                        className="logotipo"
                                        src={require("../../../assets/images/landing-page/logotipo.png")}
                                        alt="Logo Prota Games"
                                    />
                                </a>
                            </div>

                            <div className="header-buttons">
                                {
                                    data.menu.map((item: any, i: any) => (
                                        <div
                                            key={i}
                                            className={`header-button-menu ${this.checkPage() === item.id ? "active" : ""}`
                                            }
                                        >
                                            <a href={item.url} target={item.target} rel="noreferrer">
                                                {item.title}
                                                {
                                                    item.badge !== "" ?
                                                        (
                                                            <span className="badge">{item.badge}</span>
                                                        ) : ""
                                                }
                                            </a>
                                        </div>
                                    ))
                                }
                                {
                                    !this.props.button_connect ? ("") :
                                        (
                                            <ButtonPlayComponent
                                                language={data.button}
                                                game={game}
                                                dialog={true}
                                                dashboard={true}
                                                deposit={true}
                                                download={true}
                                                start={true}
                                            />
                                        )
                                }
                                {
                                    MetamaskController.connected &&
                                        MetamaskController.isBinance ?
                                        (
                                            <div className="menu-metamask Dystopian">
                                                <div className="dropdown">
                                                    <img
                                                        className="btn-metamask"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        alt="metamask"
                                                        src={require("../../../assets/icons/icon-metamask-white.png")}
                                                    />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="none">
                                                            <div className="dropdown-item">
                                                                {this.state.buttonPlayTitle}
                                                            </div>
                                                        </li>
                                                        {
                                                            data.menu_dropdown.map((item: any, index: any) => (
                                                                <li key={index}>
                                                                    <div className="dropdown-item" onClick={() => this.changeMenuDropdown(item)}>
                                                                        <img
                                                                            className="icon"
                                                                            alt="dropdown item"
                                                                            src={require(`../../../assets/icons/${item.icon}`)}
                                                                        />
                                                                        {item.title}
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                        :
                                        ("")
                                }
                            </div>
                            <div className="button">
                                <div className="menu-mobile" onClick={() => this.changeMenuMobile()}>
                                    <span className="fa fa-bars"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {
                    !this.state.showMenu ? ("") :
                        (
                            <div className="mobile-box-menu">
                                <div className="row m-0">
                                    {
                                        data.menu.map((item: any, i: any) => (
                                            <div
                                                key={i}
                                                className={`header-button-menu middle my-3 ${this.checkPage() === item.id ? "active" : ""}`
                                                }
                                            >
                                                <a href={item.url} target={item.target} className="m-0" rel="noreferrer">
                                                    {item.title}
                                                    {
                                                        item.badge !== "" ?
                                                            (
                                                                <span className="badge">{item.badge}</span>
                                                            ) : ""
                                                    }
                                                </a>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                }
            </header>
        );
    }
}