import { LanguageModel } from "../../../../models/language/language";
const language: LanguageModel = new LanguageModel({});

const dashHome = language.pages.dashboard;

// GAME
dashHome.game.download = "https://protagames.wansilva.com/game_launcher.zip";
// HEADER
dashHome.header.logotipo = "logotipo.png";
dashHome.header.menu = [
    {
        id: "home",
        title: "Home",
        url: "/dashboard/home",
        target: "_self",
        badge: undefined
    },
    {
        id: "stash",
        title: "Stash",
        url: "/dashboard/stash",
        target: "_self",
        badge: undefined
    },
    {
        id: "crates",
        title: "Pre-sale crates",
        url: "/dashboard/crates",
        target: "_self",
        badge: undefined
    },
    {
        id: "marketplace",
        title: "Marketplace",
        url: "https://tofunft.com/collection/heroes-of-metaverse/items",
        target: "_blank",
        badge: undefined
    }
];
dashHome.header.menu_dropdown = [
    // {
    //     id: "stash",
    //     title: "Stash",
    //     icon: "icon-stash.png"
    // },
    // {
    //     id: "profile",
    //     title: "Public Profile",
    //     icon: "icon-profile.png"
    // },
    // {
    //     id: "account",
    //     title: "Account Setting",
    //     icon: "icon-account.png"
    // },
    {
        id: "logout",
        title: "Logout",
        icon: "icon-logout.png"
    }
];
dashHome.header.button.associate = "Associate Wallet";
dashHome.header.button.await = "Aguarde...";
dashHome.header.button.connect = "Connect with Metamask";
dashHome.header.button.deposit = "Deposit Crates";
dashHome.header.button.download = "Download Game";
dashHome.header.button.play_game.title = "Play the Game";
dashHome.header.button.play_game.url = "/dashboard/home";
dashHome.header.button.start = "Play the Game";
dashHome.header.button.switch = "Switch to Binance";
// BANNER
dashHome.banner.title = "PLAY. <br/> TRADE. <br/> EARN.";
dashHome.banner.video = "banner-video-wide.mp4";
// STEPS
dashHome.steps.title = "PLAY. TRADE. EARN";
dashHome.steps.items = [
    {
        id: {
            title: "1",
            color: "#FD7021"
        },
        title: "Associate your account with the Metamask that holds your crates",
        resume: "Get access to in-game NFTs and cryptocurrency and transfer them directly to your wallet.",
        info: "WARNING! The connected wallet is different than the previously associated one!",
        button: {
            mode: "button",
            type: "connect",
            title: "Connect with Metamask",
            switch_binance: "Switch to BNB Chain",
            associate: "Associate Wallet",
            new_associate: "Associate new Wallet",
            done: "ALREADY DONE!",
            await: "await...",
            android: null,
            apple: null,
            href: null
        }
    },
    {
        id: {
            title: "2",
            color: "#2151FD"
        },
        title: "Deposit the crates you have",
        resume: "Transferring your crates to the game will allow you to open them.",
        info: "",
        button: {
            mode: "button",
            type: "deposit",
            title: "Deposit Crates",
            done: "ALREADY DONE!",
            await: "await...",
            associate: null,
            new_associate: null,
            switch_binance: null,
            android: null,
            apple: null,
            href: null
        }
    },
    {
        id: {
            title: "3",
            color: "#2151FD"
        },
        title: "Open your crates",
        resume: "You are going to open them and win amazing new NFTs.",
        info: "",
        button: {
            mode: "redirect",
            type: "open",
            title: "Open Crates",
            done: "ALREADY DONE!",
            await: "await...",
            associate: null,
            new_associate: null,
            switch_binance: null,
            android: null,
            apple: null,
            href: "/dashboard/crates"
        }
    },
    {
        id: {
            title: "4",
            color: "#1BC098"
        },
        title: "Download the game",
        resume: "Play the game on your smartphone. You must log in to the same account as your registration. Chests can take up to 2 min to land in your account.",
        info: "",
        button: {
            mode: "app",
            type: "download",
            title: "Download Launcher",
            done: "ALREADY DONE!",
            await: "await...",
            android: {
                url: "https://play.google.com/store/apps/details?id=com.protagames.hom",
                title: "Download with Google Play"
            },
            apple: {
                url: "https://apps.apple.com/us/app/heroes-of-metaverse/id6443685500",
                title: "Download with Apple Store"
            },
            new_associate: null,
            associate: null,
            switch_binance: null,
            href: null
        }
    }
]

export { dashHome };