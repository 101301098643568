import "./button-play.css";
import React from "react";
import Emitter from "../../../utils/emitter";

import SocialLoginController from "../../../controllers/social-login/social-login";
import MetamaskController from "../../../controllers/metamask/metamask";
import CratesController from "../../../controllers/crates/crates";
import GameController from "../../../controllers/game/game";

export default class ButtonPlayComponent extends React.Component{
    private constructor(readonly props: any){
        super(props);

        this.getTitle = this.getTitle.bind(this);
        GameController.ctx = this;
    }
    public readonly state: any = {
        title: "",
        expand: false
    }
    async componentDidMount(){
        await MetamaskController.IsWalletConnected();
        await MetamaskController.IsConnectedToChainId();
        GameController.checkGameDownload();
        MetamaskController.emitters();
        if(
            this.props.deposit &&
            MetamaskController.connected &&
            MetamaskController.isBinance
        ){
            await this.checkAssociateWallet();
            await CratesController.CheckDepositCrates();
        }

        Emitter.on("MetamaskRefresh", ()=>{
            this.getTitle();
        });
        Emitter.on("CratesRefresh", ()=>{
            this.getTitle();
        });
        Emitter.on("GameRefresh", ()=>{
            this.getTitle();
        });
        Emitter.on("AssociateWallet", ()=>{
            this.getTitle();
        });

        this.getTitle();
    }

    async clickButton(){
        const dialog = this.props.dialog;
        if( !MetamaskController.connected && !MetamaskController.isBinance ) {
            await MetamaskController.ConnectWallet();
        }
        else if ( MetamaskController.connected && !MetamaskController.isBinance ) {
            await MetamaskController.SwitchToBinance();
        }
        else if ( 
            MetamaskController.isBinance && !MetamaskController.associate && MetamaskController.associatePermited ||
            !MetamaskController.isAssociateWallet && MetamaskController.associatePermited
        ) {
            await SocialLoginController.associateWallet().then((result: boolean) => {
                Emitter.emit("AssociateWallet", result);
                Emitter.emit("IsAssociateWallet", result);
            });
        }
        else if ( this.props.deposit && !CratesController.isDeposited ){
            if ((SocialLoginController.getUser() != null)) {
                await CratesController.DepositCrates();
            }
        }
        //else {
        //    if(this.props.dashboard){
        //        if(this.props.start){
        //            if(this.props.download && !GameController.download){
        //                if(dialog){
        //                    Emitter.emit("dialog", {
        //                        name: "download",
        //                        type: "open"
        //                    })
        //                }else{
        //                    GameController.GameDownload(this.props.game.download);
        //                }
        //            }
        //            else if(this.props.start && !GameController.start){
        //                GameController.StartGame();
        //            }
        //        } else {
        //            window.location.href = this.props.language.play_game.url;
        //        }
        //    }
        //}
        this.getTitle();
    }

    getTitle(){
        const data = this.props.language;
        var title  = "";
        var expand = false;

        if( !MetamaskController.connected && !MetamaskController.isBinance ) {
            title = data.connect;
        }
        else if ( MetamaskController.connected && !MetamaskController.isBinance ) {
            title = data.switch;
            expand = true;
        }
        else if ( MetamaskController.isBinance && !MetamaskController.associate ) {
            title = data.associate;
            expand = true;
        }
        else if (!MetamaskController.isAssociateWallet && MetamaskController.associatePermited) {
            title = data.associate;
            expand = true;
        }
        else if ( this.props.deposit && !CratesController.isDeposited ) {
            title = data.deposit;
            expand = true;
        }
        else {
            expand = false;
            //if(this.props.dashboard){
            //    if(this.props.start){
            //        if(this.props.download && !GameController.download){
            //            title = data.download;
            //        }
            //        else if(this.props.start && !GameController.start){
            //            title = data.start;
            //            expand = true;
            //        }
            //        else{
            //            title = data.start;
            //            expand = true;
            //        }
            //    }else{
            //        expand = true;
            //        title = data.play_game.title;
            //    }
            //} else {
            //}
            title = `${MetamaskController.wallet.substring(0, 5)}...${MetamaskController.wallet.substring(37, 42)}`;
        }

        this.setState({
            title: title,
            expand: expand
        });
    }

    async checkAssociateWallet() {
        if (
            MetamaskController.isBinance
        ) {
            const profile = await SocialLoginController.getProfileState();
            const wallet = await MetamaskController.GetSigner();
            console.log('wallet', wallet);
            console.log('associate', profile.walletAddress);
            console.log('check', profile.walletAddress.toLowerCase() === wallet.toLowerCase());
            
            if (profile.walletAddress === "") {
                Emitter.emit('AssociatePermited', true);
                Emitter.emit('IsAssociateWallet', false);
            }
            if (profile.walletAddress.toLowerCase() === wallet.toLowerCase()) {
                Emitter.emit('IsAssociateWallet', true);
            } else {
                Emitter.emit('IsAssociateWallet', false);
            }
        }
    }

    render() {
        return (
            <div
                className={`header-button-play ${this.state.expand ? "expand":""}`}
                onClick={()=>this.clickButton()}
            >
                {this.state.title}
            </div>
        );
    }
}