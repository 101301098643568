import { crateItems } from "./crates";
import { LanguageModel } from "../../../../models/language/language";
const language:LanguageModel = new LanguageModel({});

const shared = language.shared;

// LOGOTIPO
shared.language.select = "Choose your language";
shared.logotipo.image = "images/landing-page/logotipo.png";
shared.logotipo.url = "/";
// MEDIA
shared.media.discord = "https://discord.gg/VDdXGc5ywS";
shared.media.instagram = "https://www.instagram.com/heroesofmetaverseofficial/";
shared.media.twitter = "https://twitter.com/Heroesofmetav";
// COUNTDOWN
shared.countdown.days = "Days";
shared.countdown.hours = "Hours";
shared.countdown.minutes = "Minutes";
shared.countdown.seconds = "Seconds";
// CRATES
shared.crates.type.common = "COMMON";
shared.crates.type.uncommon = "UNCOMMON";
shared.crates.type.epic = "EPIC";
shared.crates.type.legendary = "LENGENDARY";
shared.crates.type.mythic = "MYTHIC";
shared.crates.type.rare = "RARE";
// ITEMS
shared.crates.items = crateItems;
// ATTRIBUTES
shared.crates.attributes.atkSpeed = "Attack Speed";
shared.crates.attributes.atkSpeedBonus = "Attack Speed Bonus";
shared.crates.attributes.damage = "Damage";
shared.crates.attributes.health = "Health";
shared.crates.attributes.shield = "Shield";
// ALL
shared.await = "Await...";

// DIALOG
// CONNECT
shared.dialog.connect.title = "Connect to Wallet";
shared.dialog.connect.subtitle = "Remember";
shared.dialog.connect.resume = "To access the game you need to have an account created on Metamask and the plugin installed in your browser.";
shared.dialog.connect.connect = "Connect Metamask";
shared.dialog.connect.switch = "Switch to Binance";
// DEPOSIT
shared.dialog.deposit.title = "Deposit Crates";
shared.dialog.deposit.subtitle = "Remember";
shared.dialog.deposit.resume = "You must transfer the crates you purchased to your Heroes of Metaverse account.";
shared.dialog.deposit.button = "Deposit Crates";
// DOWNLOAD
shared.dialog.download.title = "Download Launcher";
shared.dialog.download.subtitle = "Remember";
shared.dialog.download.resume = "It is necessary to download the launcher to be able to access the game.";
shared.dialog.download.button = "Download the launcher";
// SEND TO WALLET
shared.dialog.send_wallet.title = "SEND TO WALLET";
shared.dialog.send_wallet.subtitle = "Your Wallet";
shared.dialog.send_wallet.resume = "Send your game items to your crypto wallet";
shared.dialog.send_wallet.button = "CONFIRM";
shared.dialog.send_wallet.retry = "TRY AGAIN";
// SEND TO GAME
shared.dialog.send_game.title = "SEND TO GAME";
shared.dialog.send_game.subtitle = "Your Wallet";
shared.dialog.send_game.resume = "Send your items from your crypto wallet to game";
shared.dialog.send_game.button = "CONFIRM";
shared.dialog.send_game.retry = "TRY AGAIN";
// CONFIRM SEND
shared.dialog.confirm_send.title = "DONE!";
shared.dialog.confirm_send.subtitle = "Your request was successfully registered";
shared.dialog.confirm_send.resume = "";
shared.dialog.confirm_send.button = "OK";

// MESSAGES
shared.messages.withdraw = "Checking wallet...";
shared.messages.profile = "Checking Profile...";
shared.messages.gas = "Checking Gas...";
shared.messages.funds = "Checking Funds...";
shared.messages.transferFunds = "Transferring Gas...";
shared.messages.transferNft = "Scheduling Withdraw...";

export { shared }