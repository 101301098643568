import "./logotipo.scss";
import React, { Component } from "react";
import LanguageController from "../../../controllers/language/language";

export default class LogotipoComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {
    language: LanguageController.language(null),
  };

  async componentDidMount() {}

  render() {
    const data: any = this.state.language.shared;
    return (
      <div className="logotipo-component">
        <img
          className="logo-mobile"
          src={require(`../../../assets/${data.logotipo.image}`)}
          alt="Logotipo"
        />
      </div>
    );
  }
}
