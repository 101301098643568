import Emitter from "../../utils/emitter";
import GameService from "../../services/game/game";

export default class GameController {
    
    static ctx: any = null;
    static start: any = false;
    static download: any = false;

    private static refresh(){
        if(this.ctx != null){
            this.ctx.setState({});
            Emitter.emit("GameRefresh", "");
        }
    }

    // TO-DO: FAZER ESSA FUNÇÃO
    static async StartGame(){
        console.log('start-game')
        const start = await GameService.StartGame();
        if(start.success){
            this.start = true;
            return true;
        }
        this.refresh();
        return false;
    }

    static async GameDownload(url: any){
        const download = await GameService.GameDownload(url);
        if(download){
            this.download = true;
        }
        this.refresh();
        return download;
    }

    static checkGameDownload() {
        const downloaded = localStorage.getItem("downloaded");
        if(downloaded){
            this.download = true;
            this.refresh();
        }
        return downloaded;
    }
}