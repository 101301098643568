export default class GameService {

    // TO-DO: FAZER FUNÇÃO DO GAME
    static async StartGame(){
        try{
            return { success: true}
        // eslint-disable-next-line
        } catch (error){
            return { success: false, code: error.code }
        }
    }

    static async GameDownload(url: any) {
        try{
            window.location.assign(url);
            localStorage.setItem("downloaded", 'true');
            return true;
        // eslint-disable-next-line
        } catch (error){
            return false;
        }
    }
}