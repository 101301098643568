import "./language.scss";
import React, { Component } from "react";
import $ from "jquery";

import LanguageController from "../../../controllers/language/language";

export default class LanguageComponent extends Component {
  private constructor(readonly props: any) {
    super(props);

    this.languageOnLoad = this.languageOnLoad.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  public readonly state = {
    language: LanguageController.language(null),
  };

  async componentDidMount() {
    this.languageOnLoad();
  }

  languageOnLoad() {
    const lang = localStorage.getItem("lang");
    if (lang !== null) {
      $(".language").val(lang);
    } else {
      $(".language").val(this.props.language);
    }
  }
  changeLanguage(e: any) {
    const lang = e.target.value;
    this.setState({ lang: lang });
    LanguageController.language(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  render() {
    const data: any = this.state.language.shared
    return (
      <div className="language-component">
        <select className="language" onChange={this.changeLanguage} title={data.language.select}>
          <option value="pt-br">pt-br</option>
          <option value="en-us">en-us</option>
        </select>
      </div>
    );
  }
}
