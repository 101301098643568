import "./technology.scss";
import React, { Component } from "react";
import LangController from "../../../controllers/language/lang";
import TitleEffectComponent from "../../elements/title-effect/title-effect";

export default class TechnologyComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {
    language: LangController.getLanguage(null)
  };

  async componentDidMount() {}

  render() {
    const data: any = this.state.language.pages.home.technology;
    return (
      <section className="techonology-component" id="technology">
        <div className="content col-6">
          <TitleEffectComponent
            language={data.title}
            fontSize={"45px"}
            fontSizeEffect={"60px"}
          />
          <div className="resume">{data.resume}</div>
        </div>
        <div className="background-image">
          <img
            src={require(`../../../assets/images/landing-page/${data.image}`)}
            alt="background"
          />
        </div>
      </section>
    );
  }
}
