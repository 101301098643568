const WitelistWithdraw = {
    list: [
        "0xE4f7b49c9a7566aB94ad78F08b84aB3d063Cf44a", // wanderson
        "0x9bbc6dafb53e1E6C86Fd0419527d16278b93C6C2", // rodrigo pimentel
        "0x622f4afb8b9080111957bfdfacb6c5ec68b17f75", // lucas machado
        "0x8674dA4C2DA51d3B0d632E31f58a93131534658F", // santiago
        "0x041D40e582328E822525f215fE165851156304cA", // pablo
    ]
};

export default WitelistWithdraw;