import "./home.scss";
import React, { Component } from "react";

import LangController from "../../controllers/language/lang";
import MetamaskController from "../../controllers/metamask/metamask";
import CratesController from "../../controllers/crates/crates";
import InGameComponent from "../../components/landing-page/in-game/in-game";
import HeaderLandingPageComponent from "../../components/landing-page/header/header";
import BannerLandingPageComponent from "../../components/landing-page/banner/banner";
import TechnologyComponent from "../../components/landing-page/technology/technology";
import RoadmapComponent from "../../components/landing-page/roadmap/roadmap";
import InvestorsComponent from "../../components/landing-page/investors/investors";
import FooterComponent from "../../components/landing-page/footer/footer";

const language = LangController.getLanguage(null);
export default class HomePage extends Component {
  private constructor(props: any) {
    super(props);
    MetamaskController.ctx = this;
    CratesController.ctx = this;
  }
  public readonly state = {};

  componentDidMount() { }

  render() {
    return (
      <main className="landing-page container-fluid">
        <HeaderLandingPageComponent />
        <BannerLandingPageComponent
          title={language.pages.home.banner.title}
          video={language.pages.home.banner.video}
          donwload={true}
          go_to_id="#technology"
        />
        <section className="background">
          <TechnologyComponent />
          <InGameComponent />
        </section>
        <FooterComponent />
      </main>
    );
  }
}
