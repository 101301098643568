import Api from "../api";
import Web3Service from "../../services/web3/web3.service";
import SocialLoginController from "../../controllers/social-login/social-login";
import {ethers} from "ethers";
import {SuccessDto} from "../../services/common/SuccessDto";
import {stashItemModel} from "../../models/stash/stash";
import {GetItemContract} from "../../services/web3/intentions/item-deposit.intent";
import {GetCrateContract} from "../../services/web3/intentions/crate-deposit.intent";
import Toast from "../../utils/toast";
import Emitter from "../../utils/emitter";

import LangController from "../../controllers/language/lang";
const messages = LangController.getLanguage(null).shared.messages;

export default class StashRemote {

    // static async getItems(type: any, order: any) {
    //     const url = `https://protagames.wansilva.com?type=${type}&order=${order}`;
    //     const result = await Api.get_external_url(url);
    //     console.log(result);
    //     return result.data;
    // }

    // static async getItems(): Promise<PlayerResponseDto> {
    //     const url = `${process.env.REACT_APP_SERVER_URL}/v1/players?language=LANGUAGE_EN_US`; // todo: test this! Use the correct language
    //     console.log('url', url);
    //     const result = await Api.get(url);
    //     console.log("items", result.data);
    //     return result;
    // }

    // static async getMyWallet(wallet: string) {
    //     const url = `https://protagames.wansilva.com?wallet=${wallet}`;
    //     const result = await Api.get_external_url(url);
    //     console.log("url: ", url);
    //     console.log("result: ", result);
    //     return result.data;
    // }

    static async deposit(data: stashItemModel): Promise<SuccessDto> {
        // todo: Wan, stashItemModel needs to specify if data is a crate or item, and we need to know the tokenId in order to deposit it
        if(data.source === "backend")
            throw new Error("You cannot deposit an item that is already on the game");

        console.log('stash item model', data);
        if(!await Web3Service.EnsureIsConnected()) {
            console.error("wallet is not connected");
            return {error: "wallet is not connected", success: false};
        }


        // TODO: wan we need the token id here.
        if(!data.nftId) {
            console.error("Stashitemmodel needs to have the token id from wallet");
            throw new Error("Stashitemmodel needs to have the token id from wallet");
        }
        // crate deposit
        if(data.contract === "crate") {
            const contract = await GetCrateContract();
            const ownerAddr = await contract.signer.getAddress();
            try {
                const tx = await contract["safeTransferFrom(address,address,uint256)"](ownerAddr, process.env.REACT_APP_COREBANK_ADDRESS, data.nftId);
                await tx.wait(1);
                return {success: true, data: tx};
            }
            catch (e) {
                console.error(e);
                return {success: false, error: e.message};
            }
        }
        // crate deposit
        if(data.contract === "item") {
            const contract = await GetItemContract();
            const ownerAddr = await contract.signer.getAddress();
            try {
                // TODO: wan we need the token id here.
                const tx = await contract["safeTransferFrom(address,address,uint256)"](ownerAddr, process.env.REACT_APP_COREBANK_ADDRESS, data.nftId);
                await tx.wait(1);
                return {success: true, data: tx};
            }
            catch (e) {
                console.error(e);
                return {success: false, error: e.message};
            }
        }
    }

    static async checkWithdraw(): Promise<Boolean> {
        // check if the user can withdraw
        var withdrawLockedResponse = await Api.get("v1/blockchain-transactions?page=1&size=10&type=BLOCKCHAIN_TRANSACTION_TYPE_WITHDRAW&status=BLOCKCHAIN_TRANSACTION_STATUS_WAITING_BLOCKCHAIN&status=BLOCKCHAIN_TRANSACTION_STATUS_WAITING_FOR_PROCESSING")
        console.log("withdrawLockedResponse", withdrawLockedResponse);
        if(
            withdrawLockedResponse.data.transactions.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    static async withdraw(data: string []): Promise<SuccessDto> {
        console.log("WITHDRAW", data);
        
        // TO-DO: TOLSTA CONFIGURAR AS MENSAGENS EM PT-BR E EN-US
        // ESSE EMITTER AQUI MUDA O TITLE DO BOTÃO
        Emitter.emit("dialog-await-message", messages.withdraw);

        // check if the user can withdraw
        var withdrawLockedResponse = await Api.get("v1/blockchain-transactions?page=1&size=10&type=BLOCKCHAIN_TRANSACTION_TYPE_WITHDRAW&status=BLOCKCHAIN_TRANSACTION_STATUS_WAITING_BLOCKCHAIN&status=BLOCKCHAIN_TRANSACTION_STATUS_WAITING_FOR_PROCESSING")
        console.log("withdrawLockedResponse",withdrawLockedResponse);
        if(!withdrawLockedResponse.success)
            return withdrawLockedResponse;
        if(withdrawLockedResponse.data.transactions && withdrawLockedResponse.data.transactions.length >= 1)
            return {success: false, error: "You already have one withdraw request being processed, please wait for a few minutes."}

        if(!await Web3Service.EnsureIsConnected()) {
            console.error("wallet is not connected");
            return {error: "wallet is not connected", success: false};
        }

        // check if the connected wallet is the same saved in the server
        // Toast.info("Checking if we have your connected wallet associated with us");
        console.info("Checking if we have your connected wallet associated with us"); // todo: wan here you change the progress bar
        var signer = await Web3Service.GetSigner();
        var ownerAddress = await signer.getAddress();
        
        // ESSE EMITTER AQUI MUDA O TITLE DO BOTÃO
        Emitter.emit("dialog-await-message", messages.profile);
        var profile = await SocialLoginController.getProfileState();
        console.log("profile",profile);

        if(profile.walletAddress.toLowerCase() !== ownerAddress.toLowerCase()) {
            const err = `Server stored the wallet ${profile.walletAddress} but you are using ${ownerAddress}`;
            console.error(err);
            return {error: err, success:false}
        }

        // estimate gas to withdraw
        //Toast.info("Estimating gas");
        // ESSE EMITTER AQUI MUDA O TITLE DO BOTÃO
        Emitter.emit("dialog-await-message", messages.gas);
        console.info("Estimating Gas"); // todo: wan here you change the progress bar
        var estimateResponse = await Api.post(
            `v1/items/withdraw/estimate`, {
                itemIds: data
            }
        );
        if(!estimateResponse.success) {
            console.log(estimateResponse.error);
            return {error: estimateResponse.error, success:false};
        }
        console.log("estimatedFee",estimateResponse.data.estimatedFee);


        // ESSE EMITTER AQUI MUDA O TITLE DO BOTÃO
        Emitter.emit("dialog-await-message", messages.funds);
        // transfer gas if needed
        if(Number(profile.wallet.bnbAmount) <= Number(estimateResponse.data.estimatedFee)) {
            // check if the user has enough gas for withdraw
            var ethBalance = ethers.utils.formatEther(await signer.getBalance());            
            console.log("ethBalance", ethBalance);
            if(Number(ethBalance) <= Number(estimateResponse.data.estimatedFee)){
                const err = "insufficient funds to request withdraw";
                console.log(err);
                return {error: err, success:false};
            }

            // send funds
            try {
                //Toast.info("transferring funds to allow withdraw");
                console.info("transferring funds to allow withdraw"); // todo: wan here you change the progress bar
                
                // ESSE EMITTER AQUI MUDA O TITLE DO BOTÃO
                Emitter.emit("dialog-await-message", messages.transferFunds);

                var txTransfer = await signer.sendTransaction({to: process.env.REACT_APP_COREBANK_ADDRESS, value: ethers.utils.parseEther(estimateResponse.data.estimatedFee)})
                Toast.info("waiting 1 blockchain confirmation");
                await txTransfer.wait(1);
            }
            catch (e) {
                const err = "payment to withdraw failed. see console for details";
                console.error(e);
                return {error: err, success:false};
            }
        }
        else {
            // Toast.info("you don't need to deposit gas");
            console.info("user doesnt need to deposit gas");
        }

        // call withdraw
        //Toast.info("registering withdraw intent");
        console.info("registering withdraw intent"); // todo: wan here you change the progress bar
        var withdrawResponse = await Api.post(
            `v1/items/withdraw`, {
                itemIds: data
            }
        );
        console.log(withdrawResponse);
        if(!withdrawResponse.success) {
            console.log(withdrawResponse.error);
            return {error: withdrawResponse.error, success:false}
        }

        return withdrawResponse;
    }
}