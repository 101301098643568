import Router from "./router/index";
import "./style/style.css";

import React, {useEffect} from "react";
import GA4React, { useGA4React } from "ga-4-react";

export const ga4react = new GA4React("G-HGNWYE9CWQ");

function App() {
  const ga = useGA4React();
  console.log(ga); // this is needed to make ga work. I dont know why. Do not remove this.

  useEffect(() => {
    ga4react.pageview(window.location.pathname + window.location.search);
    console.log("ga sent");
  }, []);

  return (
    <Router />
  );
}
export default App;