import "./termsPage.scss";
import { Component } from "react";

import LanguageController from "../../controllers/language/language";
import MetamaskController from "../../controllers/metamask/metamask";

import HeaderLandingPageComponent from "../../components/landing-page/header/header";
import TermUsePtBrComponent from "./pt-br/term-use";
import TermUseEnUsComponent from "./en-us/term-use";
import TermPolicyPtBrComponent from "./pt-br/term-policy";
import TermPolicyEnUsComponent from "./en-us/term-policy";
import FooterComponent from "../../components/landing-page/footer/footer";
import React from 'react';

export default class TermsPage extends Component {
  private constructor(readonly props: any) {
    super(props);
    MetamaskController.ctx = this;
    this.getTermType = this.getTermType.bind(this);
  }
  public readonly state: any = {
    language: LanguageController.language(null),
    lang: LanguageController.lang,
    type: "",
  };

  componentDidMount() {
    this.getTermType();
  }

  getTermType(){
    const type = String(window.location.pathname).replaceAll("/terms/", "");
    this.setState({type: type})
  }

  render() {
    const lang: any = this.state.language;
    return (
      <main className="terms-page container-fluid">
        <HeaderLandingPageComponent language={lang.pages.landing_page.header} />
        <div className="terms-content">
          {
            this.state.type === "use" ? (
              this.state.lang === 'pt-br' ? (
                <TermUsePtBrComponent />
              ) : (
                <TermUseEnUsComponent />
              )
            ) : (
              this.state.lang === 'pt-br' ? (
                <TermPolicyPtBrComponent />
              ) : (
                <TermPolicyEnUsComponent />
              )
            )
          }
        </div>
        <FooterComponent language={lang.pages.landing_page.footer} />
      </main>
    );
  }
}
