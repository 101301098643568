import "./title-effect.scss";
import React, { Component } from "react";
import parse from "html-react-parser";

export default class TitleEffectComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {};

  async componentDidMount() {}

  render() {
    const data: any = this.props.language;
    return (
      <div
        className="title-effect-component"
        style={{ fontSize: `${this.props.fontSize || "40px"}` }}
      >
        <div className="box-text-effect">
          <div
            className="title Ethnocentric"
            style={{ color: `${this.props.color || "#f9a722"}` }}
          >
            {parse(data)}
          </div>
          <div
            className="title-effect Ethnocentric noselect"
            style={{ fontSize: `${this.props.fontSizeEffect || "60px"}` }}
          >
            {parse(data)}
          </div>
        </div>
      </div>
    );
  }
}
