/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  CircuitBreaker,
  CircuitBreakerInterface,
} from "../../../../contracts/infinibrains-smartcontracts/contracts/CircuitBreaker";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "data",
    outputs: [
      {
        internalType: "uint256",
        name: "idCount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052600480546001600160401b031916646000000384179055670429d069189e000060055567058d15e17628000060065567016345785d8a0000600755662386f26fc1000060085534801561005657600080fd5b5061006b600061007060201b6100481760201c565b6100c7565b60018101546100c45760408051606081018252600080825260208083018281529383018281526001808701805480830182559085529290932093516003909202909301908155925190830155516002909101555b50565b60ab806100d56000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806373d4a13a14602d575b600080fd5b60005460369081565b60405190815260200160405180910390f35b6001810154609b5760408051606081018252600080825260208083018281529383018281526001808701805480830182559085529290932093516003909202909301908155925190830155516002909101555b5056fea164736f6c6343000809000a";

type CircuitBreakerConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: CircuitBreakerConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class CircuitBreaker__factory extends ContractFactory {
  constructor(...args: CircuitBreakerConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<CircuitBreaker> {
    return super.deploy(overrides || {}) as Promise<CircuitBreaker>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): CircuitBreaker {
    return super.attach(address) as CircuitBreaker;
  }
  override connect(signer: Signer): CircuitBreaker__factory {
    return super.connect(signer) as CircuitBreaker__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): CircuitBreakerInterface {
    return new utils.Interface(_abi) as CircuitBreakerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): CircuitBreaker {
    return new Contract(address, _abi, signerOrProvider) as CircuitBreaker;
  }
}
