import { LanguageModel } from "../../../../models/language/language";
const language:LanguageModel = new LanguageModel({});

const homePage = language.pages.home;

// BANNER
homePage.banner.title = "JOGUE. <br/> TROQUE. <br/> GANHE.";
homePage.banner.video = "banner-video-wide.mp4";
// HEADER
homePage.header.menu = [
    {
        title: "whitepaper",
        url: "https://heroes-of-metaverse.gitbook.io/bem-vindos-ao-heroes-of-metaverse/",
        target: "_blank"
    }
];
// GAMEPLAY
homePage.gameplay.title = "GAMEPLAY";
homePage.gameplay.resume = "Heroes of Metaverse é um jogo gratuito, Roguelike, 3D, onde você luta contra monstros e chefões para coletar dinheiro e itens.";
homePage.gameplay.video = "gameplay-alpha.mp4";
homePage.gameplay.button.title = "COMING SOON";
homePage.gameplay.button.url = "#";
homePage.gameplay.button.target = "_self";
// TECHNOLOGY
homePage.technology.title = "TECNOLOGIA <br/> INOVADORA";
homePage.technology.resume = "Heroes of Metaverse é um jogo que usa a tecnologia blockchain para posses e moeda. Nosso objetivo é trazer padrões de alta qualidade para o mercado, misturando os melhores recursos das indústrias de jogos e blockchain.";
homePage.technology.image = "innovate-background.png";
// THE GAME
homePage.game.title = "O JOGO";
homePage.game.resume = "Saiba um pouco mais sobre o que você pode esperar nesta aventura.";
homePage.game.carousel = [
    {
        title: "",
        image: "images/landing-page/in-game/1.png"
    },
    {
        title: "",
        image: "images/landing-page/in-game/2.png"
    },
    {
        title: "",
        image: "images/landing-page/in-game/3.png"
    }
];
// ROADMAP
homePage.roadmap.title = "ROAD MAP";
homePage.roadmap.items = [
    {
        title: "Q4 2021",
        date: "2021-12",
        active: true,
        list: [
            {
                title: "Conceito do Jogo",
                active: true
            },
            {
                title: "Arte Conceitual",
                active: true
            },
            {
                title: "Whitepaper",
                active: true
            },
            {
                title: "Website",
                active: true
            }
        ]
    },
    {
        title: "Q1 2022",
        date: "2022-1",
        active: true,
        list: [
            {
                title: "Mecânica do Jogo",
                active: true
            },
            {
                title: "Venda do NFT",
                active: true
            },
            {
                title: "Gameplay Video",
                active: true
            }
        ]
    },
    {
        title: "Q2 2022",
        date: "2022-2",
        active: true,
        list: [
            {
                title: "Abertura das Caixas",
                active: true
            },
            {
                title: "Lançamento Pre-Alpha",
                active: true
            }
        ]
    },
    {
        title: "Q3 2022",
        date: "2022-3",
        active: false,
        list: [
            {
                title: "Lançamento Alpha",
                active: false
            },
            {
                title: "Marketplace",
                active: false
            }
        ]
    },
    {
        title: "FUTURO",
        date: "2022-4",
        active: false,
        list: [
            {
                title: "Co-Op",
                active: false
            }
        ]
    }
];
// INVESTORS
homePage.investors.title = "INVESTIDORES";
homePage.investors.images = [
    {
        image: "/images/landing-page/investors/iporanga.png",
        title: "Iporanga"
    },
    {
        image: "/images/landing-page/investors/big_bets.png",
        title: "Big Bets"
    },
    {
        image: "/images/landing-page/investors/prota-games.png",
        title: "Prota Games"
    },
    {
        image: "/images/landing-page/investors/class5.png",
        title: "Class 5"
    }
];
// FOOTER
homePage.footer.menu = [
    {
        title: "Terms of Use",
        url: "/terms/use",
        target: "_self"
    },
    {
        title: "Privacy Policy",
        url: "/terms/policy",
        target: "_self"
    }
];

export { homePage };