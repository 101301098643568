import StashRemote from "../../remotes/rest/stash";
import Web3Service from "../web3/web3.service";
import Toast from "../../utils/toast";
import {MetadataDto} from "../web3/dtos/metadata.dto";
import {stashItemModel} from "../../models/stash/stash";
import {SuccessDto} from "../common/SuccessDto";

export default class StashService {
    static async checkWithdraw(): Promise<Boolean> {
        return await StashRemote.checkWithdraw();
    }

    static async withdraw(data: string[] = []): Promise<SuccessDto> {
        return await StashRemote.withdraw(data);
    }

    static async deposit(data: stashItemModel): Promise<SuccessDto> {
        return await StashRemote.deposit(data);
    }
}