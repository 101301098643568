import {BigNumber, ContractTransaction} from "ethers";
import {ArtifactCrate, ArtifactCrate__factory, BatchNftSender__factory,} from "../../../typechain";
import Web3Service from "../web3.service";
import Toast from "../../../utils/toast";

export async function GetCrateContract(): Promise<ArtifactCrate>{
    let signer = await Web3Service.GetSigner();
    return ArtifactCrate__factory.connect(
        process.env.REACT_APP_CRATE_ADDRESS,
        signer);
}

export async function GetCratesBalance(): Promise<number>{
    let signer = await Web3Service.GetSigner();
    let address = await signer.getAddress();
    let contract = ArtifactCrate__factory.connect(
        process.env.REACT_APP_CRATE_ADDRESS,
        signer);
    return (await contract.balanceOf(address)).toNumber();
}

export async function MultisenderIsApprovedForAllCrates(): Promise<boolean> {
    let crateContract = await GetCrateContract();
    return await crateContract.isApprovedForAll(await crateContract.signer.getAddress(), process.env.REACT_APP_MULTISENDER_ADDRESS)
}

export async function MultisenderApproveForAllCrates(): Promise<ContractTransaction>{
    let crateContract = await GetCrateContract();
    var tx = await crateContract.setApprovalForAll(process.env.REACT_APP_MULTISENDER_ADDRESS, true);
    //Toast.info("tx submitted. Waiting for confirmation");
    return tx;
}

// enable multisender to manipulate NFTs in the user behalf
export async function EnsureMultisenderIsApprovedForAllCrates(): Promise<ContractTransaction> {
    let crateContract = await GetCrateContract();

    // approve for all. the multisender needs to be allowed to manipulate the nft for the users
    if(!await crateContract.isApprovedForAll(await crateContract.signer.getAddress(), process.env.REACT_APP_MULTISENDER_ADDRESS)) {
        // todo: emit a message to show the approval is accepted and show the tx to the user
        let tx = await crateContract.setApprovalForAll(process.env.REACT_APP_MULTISENDER_ADDRESS, true);
        //Toast.info("tx submitted. Waiting for confirmation");
        return tx;
    }
}

// // transfer all crates to the bank
// export async function MultiSenderDepositCrates(): Promise<Transaction> {
//     await EnsureMultisenderIsApprovedForAll();
//
//     let signer = await Web3Service.GetSigner();
//     let multisender = BatchNftSender__factory.connect(process.env.REACT_APP_MULTISENDER_ADDRESS, signer);
//     return multisender.SendERC721ToBank(process.env.REACT_APP_CRATE_ADDRESS, await ListMyCrateIds());
// }


// // list all crates owned by a given user
// export async function ListMyCrateIds(): Promise<BigNumber[]>{
//     let crateContract = await GetCrateContract();
//     let signerAddress = await crateContract.signer.getAddress();
//
//     // this is too costly. probably we should use moralis for that.
//     // in order do achieve this, we should create an api for it to protect moralis api key
//     // https://github.com/nft-api/nft-api#getnfts
//     const balance = await crateContract.balanceOf(signerAddress);
//
//     let ids: BigNumber[] = [];
//
//     for (let i = ethers.constants.Zero; i.lt(balance); i=i.add(1))
//         ids.push(await crateContract.tokenOfOwnerByIndex(signerAddress, i)); // this is not working. use moralis for this
//
//     return ids;
// }

// transfer all crates to the bank
export async function MultiSenderDepositCrates(): Promise<ContractTransaction> {
    await EnsureMultisenderIsApprovedForAllCrates();
    let signer = await Web3Service.GetSigner();

    let nfts = await Web3Service.GetMyCrates();

    console.log('nfts', nfts);
    let ids: BigNumber[] = [];
    for(let nft of nfts)
        ids.push(BigNumber.from(nft.id));

    Toast.info("Transferring your crates");
    let multisender = BatchNftSender__factory.connect(process.env.REACT_APP_MULTISENDER_ADDRESS, signer);
    let tx = await multisender.SendERC721ToBank(process.env.REACT_APP_CRATE_ADDRESS, ids);
    return tx;
}