import { cratesItemsModel } from "../../../../models/language/elements/shared";
const crateItems: Array<cratesItemsModel> = [
    {
        id: "loc_iron_sword_g0",
        type: "weapons",
        name: "Iron Sword",
        description: "Mind and body forged to battle",
    },
    {
        id: "loc_energy_iron_sword_g0",
        type: "weapons",
        name: "Energy Iron Sword",
        description: "Powerful weapon ready to deplete any foes",
    },
    {
        id: "loc_improved_sword_g0",
        type: "weapons",
        name: "Improved Sword",
        description: "Cutting edge technology ready to strike"
    },
    {
        id: "loc_carbon_sword_g0",
        type: "weapons",
        name: "Carbon Sword",
        description: "Rock solid craftsmanship"
    },
    {
        id: "loc_rainbow_sword_g0",
        type: "weapons",
        name: "Rainbow Sword",
        description: "Turn your enemies into rainbow dust"
    },
    {
        id: "loc_shirogami_g0",
        type: "weapons",
        name: "Shirogami",
        description: "Timeless piece of war,\nhastening foes to their deaths,\nsince the dawn of men"
    },
    {
        id: "loc_aogami_g0",
        type: "weapons",
        name: "Aogami",
        description: "Like wind in the desert,\ncutting and reshaping paths,\nchasing its own will"
    },
    {
        id: "loc_akai_shi_g0",
        type: "weapons",
        name: "Akai Shi",
        description: "Jester, flickering dancer,\ndreaded spectators set ablaze,\nwaiting for the end"
    },
    {
        id: "loc_midori_no_ha_g0",
        type: "weapons",
        name: "Midori no Ha",
        description: "Forceful warmonger,\nramming through adversity,\ndestiny incarnate"
    },
    {
        id: "loc_mato_kuroi_g0",
        type: "weapons",
        name: "Mato Kuroi",
        description: "A desire unmet,\nendless and insatiable void,\never consuming"
    },
    {
        id: "loc_hgs_pistol_g0",
        type: "weapons",
        name: "Photon Pistol",
        description: "A reliable weapon even for the harshest critic."
    },
    {
        id: "loc_hgs_crossbow_g0",
        type: "weapons",
        name: "Photon Crossbow",
        description: "A portable cupid, crossing the heart of anyone on its way."
    },
    {
        id: "loc_hgs_rifle_g0",
        type: "weapons",
        name: "Photon Rifle",
        description: "So precise that it will blow your mind."
    },
    {
        id: "loc_hgs_shotgun_g0",
        type: "weapons",
        name: "Photon Shotgun",
        description: "Recoiling enemies to stardust in a matter of seconds."
    },
    {
        id: "loc_iron_light_shield_g0",
        type: "weapons",
        name: "Iron Light Shield",
        description: "The frontier between a bump and certain death"
    },
    {
        id: "loc_carbon_light_shield_g0",
        type: "weapons",
        name: "Carbon Light Shield",
        description: "Compressed stardust, ready to defend you"
    },
    {
        id: "loc_battle_light_shield_g0",
        type: "weapons",
        name: "Battle Light Shield",
        description: "Blood and bond tied to your arms"
    },
    {
        id: "loc_golden_light_shield_g0",
        type: "weapons",
        name: "Golden Light Shield",
        description: "Glorious helper for troubled times"
    },
    {
        id: "loc_blessed_light_shield_g0",
        type: "weapons",
        name: "Blessed Light Shield",
        description: "Your portable guardian angel"
    },
    // ARMORS
    {
        id: "loc_bluish_polymer_mask_g0",
        type: "armors",
        name: "Blue Polymer Mask",
        description: "Soft and resistant mask that protects you on cold days"
    },
    {
        id: "loc_reddish_polymer_mask_g0",
        type: "armors",
        name: "Red Polymer Mask",
        description: "Soft and resistant mask that protects you on hot days"
    },
    {
        id: "loc_greenish_polymer_mask_g0",
        type: "armors",
        name: "Green Polymer Mask",
        description: "Soft and resistant mask that protects you on toxic days"
    },
    {
        id: "loc_hardened_polymer_mask_g0",
        type: "armors",
        name: "Hard Polymer Mask",
        description: "Hardened mask to protect your face"
    },
    {
        id: "loc_darkened_polymer_mask_g0",
        type: "armors",
        name: "Dark Polymer Mask",
        description: "The most resistant polymer against evil"
    },
    {
        id: "loc_bluish_polymer_suit_g0",
        type: "armors",
        name: "Blue Polymer Suit",
        description: "Soft and resistant suit that protects you on cold days"
    },
    {
        id: "loc_reddish_polymer_suit_g0",
        type: "armors",
        name: "Red Polymer Suit",
        description: "Soft and resistant suit that protects you on hot days"
    },
    {
        id: "loc_greenish_polymer_suit_g0",
        type: "armors",
        name: "Green Polymer Suit",
        description: "Soft and resistant suit that protects you on toxic days"
    },
    {
        id: "loc_hardened_polymer_suit_g0",
        type: "armors",
        name: "Hard Polymer Suit",
        description: "Hardened suit to protect your body"
    },
    {
        id: "loc_darkened_polymer_suit_g0",
        type: "armors",
        name: "Dark Polymer Suit",
        description: "The most resistant polymer against evil"
    },
    {
        id: "loc_bluish_polymer_pants_g0",
        type: "armors",
        name: "Blue Polymer Pants",
        description: "Soft and resistant pants that protects you on cold days"
    },
    {
        id: "loc_reddish_polymer_pants_g0",
        type: "armors",
        name: "Red Polymer Pants",
        description: "Soft and resistant pants that protects you on hot days"
    },
    {
        id: "loc_greenish_polymer_pants_g0",
        type: "armors",
        name: "Green Polymer Pants",
        description: "Soft and resistant pants that protects you on toxic days"
    },
    {
        id: "loc_hardened_polymer_pants_g0",
        type: "armors",
        name: "Hard Polymer Pants",
        description: "Hardened pants to protect your legs"
    },
    {
        id: "loc_darkened_polymer_pants_g0",
        type: "armors",
        name: "Dark Polymer Pants",
        description: "The most resistant polymer against evil"
    },
    {
        id: "loc_bluish_carbon_mask_g0",
        type: "armors",
        name: "Blue Carbon Mask",
        description: "An anti-cold carbon mask"
    },
    {
        id: "loc_reddish_carbon_mask_g0",
        type: "armors",
        name: "Red Carbon Mask",
        description: "An anti-heat carbon mask"
    },
    {
        id: "loc_greenish_carbon_mask_g0",
        type: "armors",
        name: "Green Carbon Mask",
        description: "An anti-venom carbon mask"
    },
    {
        id: "loc_hardened_carbon_mask_g0",
        type: "armors",
        name: "Hard Carbon Mask",
        description: "A hardened face to match your personality"
    },
    {
        id: "loc_darkened_carbon_mask_g0",
        type: "armors",
        name: "Dark Carbon Mask",
        description: "A mask of darkened carbon empowered to face evil"
    },
    {
        id: "loc_bluish_carbon_suit_g0",
        type: "armors",
        name: "Blue Carbon Suit",
        description: "An anti-cold carbon suit"
    },
    {
        id: "loc_reddish_carbon_suit_g0",
        type: "armors",
        name: "Red Carbon Suit",
        description: "An anti-heat carbon suit"
    },
    {
        id: "loc_greenish_carbon_suit_g0",
        type: "armors",
        name: "Green Carbon Suit",
        description: "An anti-venom carbon suit"
    },
    {
        id: "loc_hardened_carbon_suit_g0",
        type: "armors",
        name: "Hard Carbon Suit",
        description: "Your personal strongbox, hardened like rocks"
    },
    {
        id: "loc_darkened_carbon_suit_g0",
        type: "armors",
        name: "Dark Carbon Suit",
        description: "A suit of darkened carbon empowered to face evil"
    },
    {
        id: "loc_bluish_carbon_pants_g0",
        type: "armors",
        name: "Blue Carbon Pants",
        description: "An anti-cold carbon pants"
    },
    {
        id: "loc_reddish_carbon_pants_g0",
        type: "armors",
        name: "Red Carbon Pants",
        description: "An anti-heat carbon pants"
    },
    {
        id: "loc_greenish_carbon_pants_g0",
        type: "armors",
        name: "Green Carbon Pants",
        description: "An anti-venom carbon pants"
    },
    {
        id: "loc_hardened_carbon_pants_g0",
        type: "armors",
        name: "Hard Carbon Pants",
        description: "A sturdy piece of protection, but flexible to run"
    },
    {
        id: "loc_darkened_carbon_pants_g0",
        type: "armors",
        name: "Dark Carbon Pants",
        description: "A pants of darkened carbon empowered to face evil"
    },
    // ACCESSORIES
    {
        id: "loc_impact_ring_g0",
        type: "accessories",
        name: "Impact Ring",
        description: "A fine ornament that will push anyone forward"
    },
    {
        id: "loc_vampiric_ring_g0",
        type: "accessories",
        name: "Vampiric Ring",
        description: "Keep a strain on our foes without lifting a finger"
    },
    {
        id: "loc_destruction_ring_g0",
        type: "accessories",
        name: "Destruction Ring",
        description: "Smashed energy ready to burst"
    },
    {
        id: "loc_shield_ring_g0",
        type: "accessories",
        name: "Shield Ring",
        description: "Chunky piece of craftsmanship"
    },
    {
        id: "loc_astonish_ring_g0",
        type: "accessories",
        name: "Astonish Ring",
        description: "Flashy ornament ready for any adventure"
    },
    // PETS
    {
        id: "loc_zero_g0",
        type: "pets",
        name: "Zero",
        description: "A round drone with a powerful shot"
    },
    {
        id: "loc_guardian_g0",
        type: "pets",
        name: "Guardian",
        description: "Your favorite guardian"
    },
    {
        id: "loc_cannon_g0",
        type: "pets",
        name: "Cannon",
        description: "The power of three cannons"
    },
    // CONSUMABLES
    {
        id: "loc_character_chip",
        type: "consumables",
        name: "Character Chip",
        description: "An upgrade chip used to level up characters"
    },
    {
        id: "loc_backweapon_chip",
        type: "consumables",
        name: "Back Weapon Chip",
        description: "An upgrade chip used to level up back weapons"
    },
    {
        id: "loc_mainweapon_chip",
        type: "consumables",
        name: "Main Weapon Chip",
        description: "An upgrade chip used to level up main weapons"
    },
    {
        id: "loc_armor_chip",
        type: "consumables",
        name: "Armor Chip",
        description: "An upgrade chip used to level up armors"
    },
    {
        id: "loc_ring_chip",
        type: "consumables",
        name: "Ring Chip",
        description: "An upgrade chip used to level up rings"
    },
    {
        id: "loc_pet_chip",
        type: "consumables",
        name: "Pet Chip",
        description: "An upgrade chip used to level up pets"
    },
    // CURRENCY
    {
        id: "loc_hgs_coin",
        type: "currency",
        name: "GS",
        description: ""
    },
    {
        id: "loc_hom_coin",
        type: "currency",
        name: "HOM",
        description: ""
    }
];
export { crateItems };