import React from "react";
import Emitter from "../../../utils/emitter";
import "./dialog.css";
import MetamaskController from "../../../controllers/metamask/metamask";
export default class DialogComponent extends React.Component{
    private constructor(readonly props: any){
        super(props);

        this.dialog = this.dialog.bind(this);
    }
    public readonly state = {}

    async componentDidMount(){
        if(MetamaskController.connected) {
            await MetamaskController.GetSigner();
        }
    }

    dialog(type: any, open: boolean = false){
        Emitter.emit('dialog', {
            name: this.props.name,
            type: type,
            open: open
        });
    }

    render() {
        const data = this.props.language;
        const wallet = MetamaskController.wallet;
        return (
            <div className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title Dystopian Bold">
                            {data.title}
                        </h5>
                        <button
                            type="button" className="close" aria-label="Close"
                            onClick={()=>this.dialog('close', false)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4 className="Dystopian Bold">
                            {data.subtitle}
                        </h4>
                        {
                            !this.props.wallet ? (""):
                            (
                                <p className="modal-wallet Exo Bold">
                                    <img src={require("../../../assets/icons/icon-metamask.png")} alt="metamask" />
                                    {
                                        `${wallet.substring(0, 7)}...${wallet.substring(
                                            wallet.length - 7,
                                            wallet.length - 1
                                          )}`
                                    }
                                </p>
                            )
                        }
                        <p className="Circular">
                            {data.resume}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <div
                            className="button noselect Ethnocentric"
                            onClick={()=>this.dialog('button', false)}
                        >
                            {
                                this.props.icon === undefined ? (""):
                                (
                                    <img className="icon" src={this.props.icon} alt="Icone" />
                                )
                            }
                            {this.props.button}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}