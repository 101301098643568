import { stashItemModel } from "../stash/stash"
class userStashModel {
    stash: Array<stashItemModel>;
    crates: Array<stashItemModel>;
    wallet: userStashWalletModel;
    walletAddress: string;

    constructor(data: any) {
        this.stash = data.stash || [];
        this.crates = data.crates || [];
        this.wallet = data.wallet;
        this.walletAddress = data.walletAddress || '';
    }
}

class userStashWalletModel {
    bnbAmount: string;
    hgsAmount: string;
    homAmount: string;

    constructor(data: any) {
        this.bnbAmount = data.bnbAmount;
        this.hgsAmount = data.hgsAmount;
        this.homAmount = data.homAmount;
    }
}

export { userStashModel, userStashWalletModel }