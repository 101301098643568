import "./steps.scss";
import React, { Component } from "react";
import Emitter from "../../../utils/emitter";

import SocialLoginController from "../../../controllers/social-login/social-login";
import MetamaskController from "../../../controllers/metamask/metamask";
import CratesController from "../../../controllers/crates/crates";
import GameController from "../../../controllers/game/game";
//import LanguageController from "../../../controllers/language/language";
import LangController from "../../../controllers/language/lang";
import Web3Service from "../../../services/web3/web3.service";
import Toast from "../../../utils/toast";

const language = LangController.getLanguage(null).pages.dashboard.steps;

export default class StepsDashboardComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.changeStep = this.changeStep.bind(this);
        this.changeTitle = this.changeTitle.bind(this);
        this.changeInfo = this.changeInfo.bind(this);
        this.changeLoading = this.changeLoading.bind(this);
        this.beforeMountSteps = this.beforeMountSteps.bind(this);

        MetamaskController.ctx = this;
        CratesController.ctx = this;
        GameController.ctx = this;
    }
    public readonly state: any = {
        language: LangController.getLanguage(null),
        steps: {},
        button: 0,
        button_title: "",
        info: "",
        openDialog: "",
        loading: false
    }

    async componentDidMount() {
        this.beforeMountSteps();
        try {
            await Promise.all([
                MetamaskController.IsWalletConnected(),
                MetamaskController.IsConnectedToChainId(),
            ]);
            if (MetamaskController.connected && MetamaskController.isBinance) {
                await CratesController.CheckDepositCrates();
                GameController.checkGameDownload();
            }
            this.checkSteps();
            await this.checkAssociateWallet();
            this.changeTitle();
        } catch (e) {
            Toast.error("Please connect your wallet");
        }
        Emitter.on("SwitchToBinance", () => {
            this.checkSteps();
        });
        Emitter.on("MetamaskRefresh", () => {
            this.checkSteps();
        });
        Emitter.on("IsAssociateWallet", () => {
            this.checkSteps();
        });
        Emitter.on("ConnectWallet", async () => {
            await this.checkAssociateWallet();
        });
        Emitter.on("CratesRefresh", () => {
            this.checkSteps();
        });
        Emitter.on("dialog", () => {
            this.checkSteps();
        });
    }

    beforeMountSteps() {
        var steps = {};
        var button = this.state.button;
        language.items.forEach((item) => {
            steps[item.button.type] = false;
        });
        this.setState({ steps: steps, button: button });
    }
    async checkAssociateWallet() {
        if (
            MetamaskController.isBinance &&
            MetamaskController.associate
        ) {
            const profile = await SocialLoginController.getProfileState();
            const wallet = await MetamaskController.GetSigner();
            if (profile.walletAddress === wallet.toLowerCase()) {
                Emitter.emit('IsAssociateWallet', true);
                this.changeStep('connect', 1);
                this.changeInfo("");
            } else {
                this.changeInfo(language.items[0].info);
            }
        }
    }
    checkSteps() {
        if (
            MetamaskController.connected &&
            MetamaskController.isBinance &&
            MetamaskController.associate &&
            MetamaskController.isAssociateWallet
        ) {
            this.changeStep('connect', 1);
        }
        if (MetamaskController.associate && CratesController.isDeposited) {
            this.changeStep('deposit', 2);
        }
    
        if (CratesController.isDeposited && GameController.download) {
            this.changeStep('open', 3);
        }
        this.changeTitle();
    }
    changeInfo(info: string) {
        this.setState({
            info: info
        })
    }
    changeTitle() {
        let title = "";
        if (!MetamaskController.connected && !MetamaskController.isBinance) {
            title = language.items[this.state.button].button.title;
        }
        else if (MetamaskController.connected && !MetamaskController.isBinance) {
            title = language.items[this.state.button].button.switch_binance;
        }
        else if (MetamaskController.isBinance && !MetamaskController.associate) {
            title = language.items[this.state.button].button.associate;
        }
        else if (!MetamaskController.isAssociateWallet) {
            title = language.items[this.state.button].button.new_associate;
        }
        this.setState({ button_title: title });
    }
    changeStep(id: any, btn: any) {
        var steps = this.state.steps;
        var button = this.state.button;
        if (!steps[id]) {
            steps[id] = true;
            button += 1;
            if (btn !== null) {
                button = btn;
            }
            this.setState({ steps: steps, button: button });
        }
    }
    closeDialog() {
        this.setState({ openDialog: "" });
        this.checkSteps();
    }
    changeLoading(loading: boolean) {
        this.setState({ loading: loading });
        if (loading) {
            const title = language.items[this.state.button].button;
            this.setState({ title: title });
        } else {
            this.changeTitle();
        }
    }
    async changeButtonStep(type: any, index: number) {
        if (!this.state.steps[type] && this.state.button === index) {
            if (type === 'connect') {
                if (!MetamaskController.connected && !MetamaskController.isBinance) {
                    await MetamaskController.ConnectWallet();
                }
                else if (MetamaskController.connected && !MetamaskController.isBinance) {
                    await MetamaskController.SwitchToBinance();
                }
                else if (
                    MetamaskController.isBinance && !MetamaskController.associate ||
                    !MetamaskController.isAssociateWallet
                ) {
                    await SocialLoginController.associateWallet().then((result: boolean) => {
                        Emitter.emit("AssociateWallet", result);
                        Emitter.emit("IsAssociateWallet", result);
                    });
                }
                this.checkSteps();
            } else if (type === 'deposit') {
                this.changeLoading(true);
                if ((SocialLoginController.getUser() != null)) {
                    await CratesController.DepositCrates();
                    //this.changeLoading(false);
                }
                this.checkSteps();
            }
        }
    }

    changeButtonApp(url: string) {
        if (url !== '#') {
            this.changeStep('download', 3);
            window.open(url, '_blank').focus();
        }
    }

    getStatusButton(mode: string, index: number) {
        if (this.state.steps[mode]) {
            return "active";
        }
        if (this.state.button !== index) {
            return "inactive"
        }
        return "";
    }


    render() {
        const data = language;
        const wallet = MetamaskController.wallet;
        return (
            <section id="steps" className="dash-steps padding">
                <div className="dash-title Monument UltraBold">
                    {data.title}
                </div>
                <div className="dash-items">
                    {
                        data.items.map((item: any, index: any) => (
                            <div className={`item ${this.state.steps[item.button.type] ? " active" : ""}`} key={index}>
                                <div className="item-id" style={{ "background": item.id.color }}>
                                    {item.id.title}
                                </div>
                                <div className="item-title Circular Bold">
                                    {item.title}
                                </div>
                                <div className="item-resume Circular">
                                    {
                                        (this.state.info !== '' && this.state.button === index) ?
                                            this.state.info : item.resume
                                    }
                                    <div className="resume-wallet mt-3">
                                        {
                                            (this.state.info !== '' && this.state.button === index) ?
                                                `${wallet.substring(0, 6)}...${wallet.substring(
                                                    wallet.length - 5,
                                                    wallet.length - 1
                                                )}` : ""
                                        }
                                    </div>
                                </div>
                                {
                                    item.button.mode === 'button' && 
                                        <div
                                            className={`item-button Circular Bold 
                                            ${this.getStatusButton(
                                                item.button.type,
                                                index
                                            )}`}
                                            onClick={() => {
                                                this.changeButtonStep(item.button.type, index)
                                            }
                                            }
                                        >
                                            {
                                                !this.state.steps[item.button.type] ?
                                                    (this.state.button_title !== '' && this.state.button === index) ?
                                                        this.state.button_title : item.button.title
                                                    :
                                                    item.button.done
                                            }
                                        </div>
                                }
                                {
                                    item.button.mode === 'app' && 
                                    <div className="item-apps">
                                        <div className="app-image"
                                            onClick={() => { this.changeButtonApp(item.button.android.url) }}
                                            title={item.button.android.title}
                                        >
                                            <img
                                                src={require("../../../assets/icons/icon-button-google.png")}
                                                alt="Google Play"
                                            />
                                        </div>
                                        <div className="app-image"
                                            onClick={() => { this.changeButtonApp(item.button.apple.url) }}
                                            title={item.button.apple.title}
                                        >
                                            <img
                                                src={require("../../../assets/icons/icon-button-apple.png")}
                                                alt="App Store"
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    item.button.mode === 'redirect'  && 
                                    <div
                                        className={`item-button Circular Bold 
                                        ${this.getStatusButton(
                                            item.button.type,
                                            index
                                        )}`}
                                        onClick={() => {
                                            window.location.href = item.button.href;
                                        }
                                        }
                                    >
                                        {
                                            !this.state.steps[item.button.type] ?
                                                (this.state.button_title !== '' && this.state.button === index) ?
                                                    this.state.button_title : item.button.title
                                                :
                                                item.button.done
                                        }
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </section>
        );
    }
}