import Emitter from "../../utils/emitter";
import Toast from "../../utils/toast";
import Web3Service from "../../services/web3/web3.service";
import WitelistWithdraw from "../../configuration/witelistWithdraw";

import LanguageController from "../language/language";
const language = LanguageController.language(null);

export default class MetamaskController {

    static ctx: any = null;
    static connected: boolean = false;
    static isBinance: boolean = false;
    static associate: boolean = false;
    static isAssociateWallet: boolean = false;
    static associatePermited: boolean = false;
    static wallet: string = "";
    static whitdraw: boolean = false;
    static walletInList: boolean = false;

    private static refresh(){
        if(this.ctx != null){
            this.ctx.setState({});
            Emitter.emit("MetamaskRefresh", "");
        }
    }

    static emitters(){
        Emitter.on("ConnectWallet", (data: any)=>{
            this.connected = data;
            this.refresh();
        });
        Emitter.on("SwitchToBinance", (data: any)=>{
            this.isBinance = data;
            this.refresh();
        });
        Emitter.on("AssociateWallet", (data: any)=>{
            this.associate = data;
            this.refresh();
        });
        Emitter.on("IsAssociateWallet", (data: any)=>{
            this.isAssociateWallet = data;
            this.refresh();
        });
        Emitter.on("AssociatePermited", (data: any)=>{
            this.associatePermited = data;
            this.refresh();
        });
        Emitter.on("Wallet", (data: any)=>{
            this.wallet = data;
            this.refresh();
        });
        Emitter.on("isWhitedraw", (data: any)=>{
            this.whitdraw = data;
            this.refresh();
        });
        Emitter.on("walletInList", (data: any)=>{
            this.walletInList = data;
            this.refresh();
        });
    }

    static async ConnectWallet(): Promise<boolean>{
        const result: any = await Web3Service.ConnectWallet();
        if(result.success){
            await this.isConnectedToBinance();
            this.connected = true;
            Emitter.emit('ConnectWallet', true);
            Toast.success(language.success['2500']);
            this.refresh();
            return true;
        } else {
            Toast.error(language.error[result.error]);
            this.refresh();
            return false;
        }
    }

    static async IsWalletConnected(): Promise<boolean>{
        const result = await Web3Service.IsWalletConnected();
        this.connected = result;
        this.refresh();
        return result;
    }

    static async isConnectedToBinance(): Promise<boolean>{
        const result = await Web3Service.IsConnectedToChainId();
        this.isBinance = result;
        this.refresh();
        return result;
    }

    static async SwitchToBinance(): Promise<boolean>{
        const result = await Web3Service.SwitchToBinance();
        if(result.success){
            Toast.success(language.success[result.code] + ' ' + process.env.REACT_APP_CHAIN_NAME);
            this.isBinance = true;
            Emitter.emit('SwitchToBinance', true);
            this.refresh();
            return true;
        }else{
            this.isBinance = false;
            Toast.error(language.error[result.error] + ' ' + process.env.REACT_APP_CHAIN_NAME);
            this.refresh();
            return false;
        }
    }

    static async IsConnectedToChainId(){
        const result = await Web3Service.IsConnectedToChainId();
        if(result){
            this.connected = true;
            this.isBinance = result;
            await this.GetSigner();
        }
        this.refresh();
        return result;
    }

    static async CheckWalletInList() {
        const wallet = await this.GetSigner();
        const exist = WitelistWithdraw.list.includes(wallet);
        this.walletInList = exist
        Emitter.emit("walletInList", exist);
    }

    static async GetSigner(){
        const result = await Web3Service.GetSigner();
        if(result != null){
            const wallet = await result.getAddress();
            this.wallet = wallet.toLowerCase();
            this.refresh();
            Emitter.emit("Wallet", wallet);
            return wallet;
        } else {
            this.wallet = "";
            return "";
        }
    }

    static async DisconnectWallet() {
        const result: any = await Web3Service.DisconnectWallet();
        this.connected = false;
        this.refresh();
        return result;
    }
}