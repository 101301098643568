import "./marketplace.css";
import React, { Component } from "react";

import LanguageController from "../../controllers/language/language";

import HeaderDashboardComponent from "../../components/dashboard/header/header";
import DialogsDashboardComponent from "../../components/shared/dialogs/dialogs";

export default class MarketplacePage extends Component {
    private constructor(readonly props: any) {
        super(props);
    }
    public readonly state: any = {
        lang: LanguageController.language(null)
    }

    componentDidMount() { }

    render() {
        const lang = this.state.lang.pages;
        return (
            <main className="marketplace-page">

                <HeaderDashboardComponent
                    language={lang.dashboard.header}
                    game={lang.dashboard.game}
                />

               <DialogsDashboardComponent
                    language={ lang.dashboard.dialog }
                />

                <div className="w-100 middle my-5">
                    Em desenvolvimento
                </div>
            </main>
        );
    }
}
