import { ethers } from 'ethers';
import enviroment from '../../configuration/enviroment';
import ArtifactCrate from '../../artifacts/contracts/ArtifactCrate.sol/ArtifactCrate.json';

import Web3Service from '../web3/web3.service';
import {MetadataDto} from "../web3/dtos/metadata.dto";
import {GetCratesBalance} from "../web3/intentions/crate-deposit.intent";
import Toast from "../../utils/toast";
import { stashItemModel } from '../../models/stash/stash';
import CrateRemote from "../../remotes/rest/crate";
export default class CratesService {
    static async BuyCrate(type: any) {
        const types = enviroment.CRATES.ITEMS;
        if (!await Web3Service.IsWalletConnected()) {
            return { success: false, error: '4001' }
        }
        if (typeof window['ethereum'] !== 'undefined') {
            try{
                const provider = new ethers.providers.Web3Provider(window['ethereum']);
                const signer = provider.getSigner()
                const contract = new ethers.Contract(
                    process.env.REACT_APP_CRATE_ADDRESS,
                    ArtifactCrate.abi,
                    signer
                );
                const transaction = await contract.buy(
                    types[type].id,
                    {
                        value: ethers.utils.parseEther(types[type].value)
                    }
                );
                await transaction.wait();

                return { success: true, code: '2520' };
            } catch(error){
                if(error.code === -32603){
                    return { success: false, error: '4020' }
                }
            }
        }
    }

    static async CheckDepositCrates(): Promise<number>{
        if(await Web3Service.EnsureIsConnected() === false)
            return -1;

        return await GetCratesBalance();
    }

    static async CratesAvailable(){
        if (typeof window['ethereum'] !== 'undefined') {
            if (!await Web3Service.IsWalletConnected()) {
                return { success: false, error: '4001' };
            }
            const provider = new ethers.providers.Web3Provider(window['ethereum']);
            const contract = new ethers.Contract(
                process.env.REACT_APP_CRATE_ADDRESS,
                ArtifactCrate.abi,
                provider
            );

            const rareSupply = await contract.rareSupply();
            const epicSupply = await contract.epicSupply();
            const legendarySupply = await contract.legendarySupply();
            const mythicSupply = await contract.mythicSupply();

            const rareAvaliable = rareSupply.quantity - await contract.rareArtifactsPurchased();
            const epicAvailable = epicSupply.quantity - await contract.epicArtifactsPurchased();
            const legendaryAvailable = legendarySupply.quantity - await contract.legendaryArtifactsPurchased();
            const mythicAvailable = mythicSupply.quantity - await contract.mythicArtifactsPurchased();

            return {
                success: true,
                data: {
                    rare: rareAvaliable,
                    epic: epicAvailable,
                    legendary: legendaryAvailable,
                    mythic: mythicAvailable
                }
            };
        }
    }

    static async HasPurchasedCrate() {
        if (typeof window['ethereum'] !== 'undefined') {
            if (!await Web3Service.IsWalletConnected()) {
                return { success: false, error: '4001' };
            }
            const [account] = await window['ethereum'].request({method: 'eth_requestAccounts'})
            const provider = new ethers.providers.Web3Provider(window['ethereum']);
            const contract = new ethers.Contract(process.env.REACT_APP_CRATE_ADDRESS, ArtifactCrate.abi, provider)
            const balance = await contract.balanceOf(account);
            return { success: true, data: balance > 0 };
        }
        return { success: false, error: '0000' };
    }

    static async CratesPurchased(){
        let crates = {
            rare: 0,
            epic: 0,
            legendary: 0,
            mythic: 0
        };

        if (typeof window['ethereum'] !== 'undefined') {
            if (!await Web3Service.IsWalletConnected()) {
                return { success: false, data: crates, error: '4001' };
            }
            const [account] = await window['ethereum'].request({
                method: 'eth_requestAccounts'
            });
            const provider = new ethers.providers.Web3Provider(window['ethereum']);
            const contract = new ethers.Contract(
                process.env.REACT_APP_CRATE_ADDRESS,
                ArtifactCrate.abi,
                provider
            );
            const balance = await contract.balanceOf(account);

            for(let i = 0; i < balance; i++) {
                const id = await contract.tokenOfOwnerByIndex(account, i);
                const uri = await contract.tokenURI(id);

                if (uri === "https://hom-nfts.s3.sa-east-1.amazonaws.com/artifact-crate/rare.json") {
                    crates.rare++;
                } else if (uri === "https://hom-nfts.s3.sa-east-1.amazonaws.com/artifact-crate/epic.json") {
                    crates.epic++;
                } else if (uri === "https://hom-nfts.s3.sa-east-1.amazonaws.com/artifact-crate/legendary.json") {
                    crates.legendary++;
                } else {
                    crates.mythic++;
                }
            }

            return { success: true, data: crates };
        }
        return { success: false, data: crates, error: '0000' };
    }

    static async OpenCrate(id: string) {
        const result = await CrateRemote.openCrate(id);
        return {
            success: result.success,
            data: result.data.items,
            error: result.error,
        }
    }
}