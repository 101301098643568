import "./menu.css";
import React, { Component } from "react";

import Emitter from "../../../utils/emitter";

export default class MenuStashComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.changeMenu = this.changeMenu.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.changeType = this.changeType.bind(this);
    }
    public readonly state: any = {
        selected: "all",
        type: "backend",
        order: "ORDER_BY_CREATED_AT_ASC"
    }

    componentDidMount() {

    }

    changeMenu(data: any) {
        this.setState({
            selected: data
        });
        Emitter.emit("menu-button", {
            type: this.state.type,
            filter: data,
            order: this.state.order
        });
    }

    changeType(e: any) {
        const data = e.target.value;
        Emitter.emit("menu-button", {
            type: data,
            filter: this.state.selected,
            order: this.state.order
        });
        this.setState({
            type: data
        });
    }

    changeOrder(e: any) {
        const data = e.target.value;
        this.setState({
            order: data
        });
        Emitter.emit("menu-button", {
            type: this.state.type,
            filter: this.state.selected,
            order: data
        });
    }

    checkActive(id: any) {
        if (this.state.selected === id) {
            return "active"
        }
        return "";
    }

    render() {
        const data = this.props.language;
        return (
            <section className="stash-menu noselect padding">
                <div className="type">
                    <select className="type-stash" onChange={this.changeType}>
                        {
                            data.types.map((item: any, index: any) => (
                                <option key={index} value={item.value}>
                                    {item.title}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="buttons">
                    {
                        data.buttons.map((item: any, index: any) => (
                            <div
                                key={index}
                                className={`button ${this.checkActive(item.name)}`}
                                onClick={() => this.changeMenu(item.name)}
                            >
                                {item.title}
                            </div>
                        ))
                    }
                </div>
                <div className="order">
                    <select className="order-select" onChange={this.changeOrder}>
                        {
                            data.order.map((item: any, index: any) => (
                                <option key={index} value={item.value}>
                                    {item.title}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </section>
        );
    }
}