class sharedComponentModel {
    button_play: buttonPlay;
    countdown: countdownModel;
    dialog: dialogComponent;
    language: languageSelect;
    logotipo: logotipoModel;
    media: mediaModel;
    crates: cratesModel;
    await?: string;
    messages: dialogMessagesModel;

    constructor(data: any) {
        this.button_play = data.button_play || new buttonPlay({});
        this.countdown = data.countdown || new countdownModel({});
        this.dialog = data.dialog || new dialogComponent({});
        this.language = data.language || new languageSelect({});
        this.logotipo = data.logotipo || new logotipoModel({});
        this.media = data.media || new mediaModel({});
        this.crates = data.crates || new cratesModel({});
        this.messages = data.messages || new dialogMessagesModel();
    }
}

class dialogMessagesModel {
    withdraw: string | "";
    profile: string | "";
    gas: string | "";
    funds: string | "";
    transferFunds: string | "";
    transferNft: string | "";
}

class dialogComponent {
    connect: dialogBody;
    deposit: dialogBody;
    download: dialogBody;
    send_wallet: dialogBody;
    send_game: dialogBody;
    confirm_send: dialogBody;
    constructor(data: any) {
        this.connect = data.connect || new dialogBody({});
        this.deposit = data.deposit || new dialogBody({});
        this.download = data.download || new dialogBody({});
        this.send_wallet = data.send_wallet || new dialogBody({});
        this.send_game = data.send_game || new dialogBody({});
        this.confirm_send = data.confirm_send || new dialogBody({});
    }
}
class dialogBody {
    title: string;
    subtitle: string;
    resume: string;
    connect: string;
    switch: string;
    button: string;
    retry: string | "";

    constructor(data: any) {
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.resume = data.resume;
        this.connect = data.connect;
        this.switch = data.switch;
        this.button = data.button;
    }
}

class languageSelect {
    select: string;
    constructor(data: any) {
        this.select = data.select || "";
    }
}

class logotipoModel {
    image: string;
    url: string;
    constructor(data: any) {
        this.image = data.image;
        this.url = data.url;
    }
}

class mediaModel {
    twitter: string;
    instagram: string;
    discord: string;
    constructor(data: any) {
        this.twitter = data.twitter;
        this.instagram = data.instagram;
        this.discord = data.discord;
    }
}

class countdownModel {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
    constructor(data: any) {
        this.days = data.days;
        this.hours = data.hours;
        this.minutes = data.minutes;
        this.seconds = data.seconds;
    }
}

class buttonPlay {
    associate: string;
    await: string;
    connect: string;
    deposit: string;
    download: string;
    play_game: playGame;
    start: string;
    switch: string;

    constructor(data: any) {
        this.associate = data.associate;
        this.await = data.await;
        this.connect = data.connect;
        this.deposit = data.deposit;
        this.download = data.download;
        this.play_game = data.play_game || new playGame({});
        this.start = data.start;
        this.switch = data.switch;
    }
}
class playGame {
    title: string;
    url: string;
    constructor(data: any) {
        this.title = data.title;
        this.url = data.url;
    }
}

class cratesModel {
    type: cratesType;
    attributes: attributesModel;
    items: Array<cratesItemsModel>;
    constructor(data: any) {
        this.type = data.type || new cratesType({});
        this.items = data.items || new cratesItemsModel({});
        this.attributes = data.attributes || new attributesModel({});
    }
}
class cratesType {
    common: string;
    uncommon: string;
    rare: string;
    epic: string;
    legendary: string;
    mythic: string;
    constructor(data: any) {
        this.common = data.common;
        this.uncommon = data.uncommon;
        this.rare = data.rare;
        this.epic = data.epic;
        this.legendary = data.legendary;
        this.mythic = data.mythic;
    }
}
class cratesItemsModel {
    id: string;
    name: string;
    description: string;
    type: string;
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.type = data.type;
    }
}
class attributesModel {
    atkSpeed: string;
    atkSpeedBonus: string;
    damage: string;
    health: string;
    shield: string;

    constructor(data: any) {
        this.atkSpeed = data.atkSpeed;
        this.atkSpeedBonus = data.atkSpeedBonus;
        this.damage = data.damage;
        this.health = data.health;
        this.shield = data.shield;
    }
}

export { sharedComponentModel, cratesItemsModel };