class cratesPageModel {
    title: string;
    empty: string;

    constructor(data: any) {
        this.title = data.title;
        this.empty = data.empty;
    }
}

export { cratesPageModel };