enum cratesTypeByID {
    "COMMON",
    "UNCOMMON",
    "RARE",
    "EPIC",
    "LEGENDARY",
    "MYTHIC",
}
enum cratesTypeByName {
    ITEM_RARITY_COMMON = "common",
    ITEM_RARITY_UNCOMMON = "uncommon",
    ITEM_RARITY_RARE = "rare",
    ITEM_RARITY_EPIC = "epic",
    ITEM_RARITY_LEGENDARY = "legendary",
    ITEM_RARITY_MYTHIC = "mythic",
}
enum cratesColorByID {
    "common",
    "uncommon",
    "rare",
    "epic",
    "legendary",
    "mythic",
}
enum cratesColorByName {
    'ITEM_RARITY_COMMON' = "common",
    'ITEM_RARITY_UNCOMMON' = "uncommon",
    'ITEM_RARITY_RARE' = "rare",
    'ITEM_RARITY_EPIC' = "epic",
    'ITEM_RARITY_LEGENDARY' = "legendary",
    'ITEM_RARITY_MYTHIC' = "mythic",
}

enum cratesSubtype {
    ITEM_SUBTYPE_UNDEFINED = 0,
    ITEM_SUBTYPE_SHORTSWORD = 1,
    ITEM_SUBTYPE_KATANA = 1,
    ITEM_SUBTYPE_SHIELD = 3,
    ITEM_SUBTYPE_PISTOL = 0,
    ITEM_SUBTYPE_RIFLE = 0,
    ITEM_SUBTYPE_CROSSBOW = 0,
    ITEM_SUBTYPE_SHOTGUN = 0,
    ITEM_SUBTYPE_MASK = 2,
    ITEM_SUBTYPE_SUIT = 0,
    ITEM_SUBTYPE_PANTS = 4,
    ITEM_SUBTYPE_RING = 0,
    ITEM_SUBTYPE_DRONE = 0,
    ITEM_SUBTYPE_CRATE = 0,
}

enum cratesSubtypeFilter {
    NFTS,
    SWORDS,
    HELMETS,
    SHIELDS,
    ARMORS,
    AXES
}

export { cratesTypeByID, cratesTypeByName, cratesColorByID, cratesColorByName, cratesSubtype, cratesSubtypeFilter };