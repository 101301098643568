const enviroment = {
    LANGUAGE: "pt-br",
    DASHBOARD: true,
    SHOW_BUTTON_CONNECT: true,
    CHECK_WITELIST: false,
    STEP: 'dashboard',
    STEPS: [
        'mktplace',
        'dashboard'
    ],
    CRATES: {
        COUNTDOWN: "2022-01-14T23:00:00.300Z",
        OPEN_SALE: 3,
        TOFU: true,
        MYCRATES: true,
        ITEMS: {
            RARE: {
                ID: 0,
                VALUE: "0.1"
            },
            EPIC: {
                ID: 1,
                VALUE: "0.3"
            },
            LEGENDARY: {
                ID: 2,
                VALUE: "0.9"
            },
            MYTHIC: {
                ID: 3,
                VALUE: "2.4"
            }
        }
    }
}

export default enviroment;