import Api from "../api";
import {SuccessDto} from "../../services/common/SuccessDto";
import LangController from "../../controllers/language/lang";
import {languageTypes} from "../../models/language/types";

export default class ItemRemote {
    static async listItemByIds(itemIds: string[]): Promise<SuccessDto> {
        LangController.getLanguage(null)
        const language = languageTypes[LangController.lang];
        const itemIdsQueryParams = itemIds.map(i => `itemIds=${i}`).join('&')
        return await Api.get(
            `v1/items?${itemIdsQueryParams}&language=${language}`
        );
    }
}