import enviroment from "../../configuration/enviroment";
import { LanguageModel } from "../../models/language/language";
import languagePtBR from "../../configuration/language/pt-br/pt-br";
import languageEnUS from "../../configuration/language/en-us/en-us";

export default class LangController {
    static lang: any = null;
    static language: LanguageModel = new LanguageModel({});

    static getLanguage(type: string | null): LanguageModel {
        const lang = localStorage.getItem('lang');
        if(lang === null){
            if(type === undefined || type === null){
                type = enviroment.LANGUAGE;
            }
            this.lang = type;
            return this.change_language(type);
        }else{
            this.lang = lang;
            return this.change_language(lang);
        }
    }

    static change_language(type: any): LanguageModel{
        this.lang = type;
        switch(type){
            case 'pt-br':
                this.language = new LanguageModel(languagePtBR);
                return new LanguageModel(languagePtBR);
            case 'en-us':
                this.language = new LanguageModel(languageEnUS);
                return new LanguageModel(languageEnUS);
            default: break;
        }
    }
}