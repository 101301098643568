import axios, {AxiosResponse} from 'axios';

import SocialLoginController from '../controllers/social-login/social-login';
import {SuccessDto} from "../services/common/SuccessDto";


export default class Api {

  private static header() {
    const user = SocialLoginController.getUser();
    return {
      "Content-Type": "application/json",
      "Authorization": user.id_token
    }
  }

  static async get_external_url(params) {
    try{
      const response = await axios.get(`${params}`);
      return { success : true, data: response.data };
    }
    catch (error){
      return {"success" : false, error: 100 }
    }
  }

  static async postExternalUrlHeader(params: string, body: any, header: any) {
    try {
      const response = await axios.post(params, body, { headers: header });
      return { success: true, data: response.data };
    }
    catch (error) {
      return { success: false, data: error }
    }
  }

  static async post_external_url(params: string, body: any) {
    try{
      const response = await axios.post(params, body);
      return { success: true, data: response.data };
    }
    catch (error){
      return {success: false, data: error }
    }
  }
  
  static async get(params: string): Promise<SuccessDto>{
    try{
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/${params}`,
          {
            headers: this.header()
          }
        );
      return { success: true, data: response.data };
    }
    catch (error){
      console.error(error);
      return {"success" : false, error: "100"}
    }
  }

  static async post(params: any, body: any): Promise<SuccessDto> {
    try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/${params}`,
          body,
          {
            headers: this.header()
          }
        );
      return {"success" : true, data: response.data };
    }
    catch(error) {
      return {"success" : false, error: error }
    }
  }

  static async put(params: any, body: any): Promise<SuccessDto> {
    var url = `${process.env.REACT_APP_SERVER_URL}/${params}`;
    var headers = this.header();
    console.log("url", url);
    console.log("header", headers);
    console.log("body", body);
    try {
      const response = await axios.put( url, body,{ headers }, );
      return {"success" : true, data: response.data };
    }
    catch(e){
      if(e.response.status===409)
        return {"success": false, code: e.response.success, error: e.response.data.error}
      else
        return {"success": false, code: e.response.success, error: e.message}
    }
  }

  static async del(params: any) {
    try{
        const response = await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/${params}`,
          {
            headers: this.header()
          }
        );
      return response.data;
    }
    catch(error){
      return {"success" : false, error: 100 }
    }
  }
}
