import { cratesItemsModel } from "../../../../models/language/elements/shared";
const crateItems: Array<cratesItemsModel> = [
    {
        id: "loc_iron_sword_g0",
        type: "weapons",
        name: "Espada de Ferro",
        description: "Mente e corpo forjados para a batalha",
    },
    {
        id: "loc_energy_iron_sword_g0",
        type: "weapons",
        name: "Espada de Ferro de Energia",
        description: "Arma poderosa pronta para esgotar qualquer inimigo",
    },
    {
        id: "loc_improved_sword_g0",
        type: "weapons",
        name: "Espada Aprimorada",
        description: "Tecnologia de ponta pronta para atacar"
    },
    {
        id: "loc_carbon_sword_g0",
        type: "weapons",
        name: "Espada De Carbono",
        description: "Artesanato sólido como rocha"
    },
    {
        id: "loc_rainbow_sword_g0",
        type: "weapons",
        name: "Espada Arco-Íris",
        description: "Transforme seus inimigos em poeira de arco íris"
    },
    {
        id: "loc_shirogami_g0",
        type: "weapons",
        name: "Shirogami",
        description: "Peça de guerra atemporal,\nacelerando os inimigos para a morte,\ndesde o início dos homens"
    },
    {
        id: "loc_aogami_g0",
        type: "weapons",
        name: "Aogami",
        description: "Como o vento no deserto,\ncortando e remodelando caminhos,\nperseguindo sua própria vontade"
    },
    {
        id: "loc_akai_shi_g0",
        type: "weapons",
        name: "Akai Shi",
        description: "O zombador, dançarino trêmulo,\ntemidos espectadores incendiados,\nesperando o fim"
    },
    {
        id: "loc_midori_no_ha_g0",
        type: "weapons",
        name: "Midori no Ha",
        description: "Forte provocador de guerra\nforçando a adversidade,\ndestino encarnado"
    },
    {
        id: "loc_mato_kuroi_g0",
        type: "weapons",
        name: "Mato Kuroi",
        description: "Um desejo não atendido,\nvazio sem fim e insaciável,\nsempre consumindo"
    },
    {
        id: "loc_hgs_pistol_g0",
        type: "weapons",
        name: "Pistola de Fóton",
        description: "Uma arma confiável, mesmo para o critico mais severo"
    },
    {
        id: "loc_hgs_crossbow_g0",
        type: "weapons",
        name: "Besta de Fóton",
        description: "Um cupido portátil, cruzando o coração de qualquer um em seu caminho"
    },
    {
        id: "loc_hgs_rifle_g0",
        type: "weapons",
        name: "Rifle de Fóton",
        description: "Tão preciso que vai explodir sua mente"
    },
    {
        id: "loc_hgs_shotgun_g0",
        type: "weapons",
        name: "Escopeta de Fóton",
        description: "Devolvendo inimigos para poeira estelar em momentos"
    },
    {
        id: "loc_iron_light_shield_g0",
        type: "weapons",
        name: "Escudo Leve de Ferro",
        description: "A fronteira entre um golpe e a morte certa"
    },
    {
        id: "loc_carbon_light_shield_g0",
        type: "weapons",
        name: "Escudo Leve de Carbono",
        description: "Poeira Estelar compactada, a melhor defesa possível"
    },
    {
        id: "loc_battle_light_shield_g0",
        type: "weapons",
        name: "Escudo Leve de Batalha",
        description: "Sangue e vínculo amarrado aos seus braços"
    },
    {
        id: "loc_golden_light_shield_g0",
        type: "weapons",
        name: "Escudo Leve Dourado",
        description: "Ajudante glorioso para tempos dificeis"
    },
    {
        id: "loc_blessed_light_shield_g0",
        type: "weapons",
        name: "Escudo Leve Abençoado",
        description: "Seu anjo da guarda portátil"
    },
    // ARMORS
    {
        id: "loc_bluish_polymer_mask_g0",
        type: "armors",
        name: "Máscara de Polímero Azul",
        description: "Máscara macia e resistente que te proteje em dias frios"
    },
    {
        id: "loc_reddish_polymer_mask_g0",
        type: "armors",
        name: "Máscara de Polímero Vermelho",
        description: "Máscara macia e resistente que te proteje em dias quentes"
    },
    {
        id: "loc_greenish_polymer_mask_g0",
        type: "armors",
        name: "Máscara de Polímero Verde",
        description: "Máscara macia e resistente que te proteje em dias tóxicos"
    },
    {
        id: "loc_hardened_polymer_mask_g0",
        type: "armors",
        name: "Máscara de Polímero Duro",
        description: "Máscara endurecida para proteger seu rosto"
    },
    {
        id: "loc_darkened_polymer_mask_g0",
        type: "armors",
        name: "Máscara de Polímero Escuro",
        description: "O polímero mais resistente contra a maldade"
    },
    {
        id: "loc_bluish_polymer_suit_g0",
        type: "armors",
        name: "Traje de Polímero Azul",
        description: "Traje macio e resistente que te proteje em dias frios"
    },
    {
        id: "loc_reddish_polymer_suit_g0",
        type: "armors",
        name: "Traje de Polímero Vermelho",
        description: "Traje macio e resistente que te proteje em dias quentes"
    },
    {
        id: "loc_greenish_polymer_suit_g0",
        type: "armors",
        name: "Traje de Polímero Verde",
        description: "Traje macio e resistente que te proteje em dias tóxicos"
    },
    {
        id: "loc_hardened_polymer_suit_g0",
        type: "armors",
        name: "Traje de Polímero Duro",
        description: "Traje endurecido para proteger seu corpo"
    },
    {
        id: "loc_darkened_polymer_suit_g0",
        type: "armors",
        name: "Traje de Polímero Escuro",
        description: "O polímero mais resistente contra a maldade"
    },
    {
        id: "loc_bluish_polymer_pants_g0",
        type: "armors",
        name: "Calça de Polímero Azul",
        description: "Calça macia e resistente que te proteje em dias frios"
    },
    {
        id: "loc_reddish_polymer_pants_g0",
        type: "armors",
        name: "Calça de Polímero Vermelho",
        description: "Calça macia e resistente que te proteje em dias quentes"
    },
    {
        id: "loc_greenish_polymer_pants_g0",
        type: "armors",
        name: "Calça de Polímero Verde",
        description: "Calça macia e resistente que te proteje em dias tóxicos"
    },
    {
        id: "loc_hardened_polymer_pants_g0",
        type: "armors",
        name: "Calça de Polímero Duro",
        description: "Máscara endurecida para proteger suas pernas"
    },
    {
        id: "loc_darkened_polymer_pants_g0",
        type: "armors",
        name: "Calça de Polímero Escuro",
        description: "O polímero mais resistente contra a maldade"
    },
    {
        id: "loc_bluish_carbon_mask_g0",
        type: "armors",
        name: "Máscara de Carbono Azul",
        description: "Uma mascara de carbono anti frio"
    },
    {
        id: "loc_reddish_carbon_mask_g0",
        type: "armors",
        name: "Máscara de Carbono Vermelho",
        description: "Uma máscara de carbono anti calor"
    },
    {
        id: "loc_greenish_carbon_mask_g0",
        type: "armors",
        name: "Máscara de Carbono Verde",
        description: "Uma mascara de carbono anti veneno"
    },
    {
        id: "loc_hardened_carbon_mask_g0",
        type: "armors",
        name: "Máscara de Carbono Duro",
        description: "Um rosto endurecido para combinar com sua personalidade"
    },
    {
        id: "loc_darkened_carbon_mask_g0",
        type: "armors",
        name: "Máscara de Carbono Escuro",
        description: "Uma máscara de carbono escurecido capaz de enfrentar o mal"
    },
    {
        id: "loc_bluish_carbon_suit_g0",
        type: "armors",
        name: "Traje de Carbono Azul",
        description: "Um Traje de carbono anti frio"
    },
    {
        id: "loc_reddish_carbon_suit_g0",
        type: "armors",
        name: "Traje de Carbono Vermelho",
        description: "Um Traje de carbono anti calor"
    },
    {
        id: "loc_greenish_carbon_suit_g0",
        type: "armors",
        name: "Traje de Carbono Verde",
        description: "Um Traje de carbono anti veneno"
    },
    {
        id: "loc_hardened_carbon_suit_g0",
        type: "armors",
        name: "Traje de Carbono Duro",
        description: "Seu cofre pessoal, endurecido como rochas"
    },
    {
        id: "loc_darkened_carbon_suit_g0",
        type: "armors",
        name: "Traje de Carbono Escuro",
        description: "Um traje de carbono escurecido capaz de enfrentar o mal"
    },
    {
        id: "loc_bluish_carbon_pants_g0",
        type: "armors",
        name: "Calça de Carbono Azul",
        description: "Uma calça de carbono anti frio"
    },
    {
        id: "loc_reddish_carbon_pants_g0",
        type: "armors",
        name: "Calça de Carbono Vermelho",
        description: "Uma calça de carbono anti calor"
    },
    {
        id: "loc_greenish_carbon_pants_g0",
        type: "armors",
        name: "Calça de Carbono Verde",
        description: "Uma calça de carbono anti veneno"
    },
    {
        id: "loc_hardened_carbon_pants_g0",
        type: "armors",
        name: "Calça de Carbono Duro",
        description: "Uma peça de proteção robusta, mas flexível para correr"
    },
    {
        id: "loc_darkened_carbon_pants_g0",
        type: "armors",
        name: "Calça de Carbono Escuro",
        description: "Uma calça de carbono escurecido capaz de enfrentar o mal"
    },
    // ACCESSORIES
    {
        id: "loc_impact_ring_g0",
        type: "accessories",
        name: "Anel de Impacto",
        description: "Um belo ornamento que vai empurrar qualquer um para a frente"
    },
    {
        id: "loc_vampiric_ring_g0",
        type: "accessories",
        name: "Anel Vampírico",
        description: "Mantenha uma pressão sobre nossos inimigos sem levantar um dedo"
    },
    {
        id: "loc_destruction_ring_g0",
        type: "accessories",
        name: "Anel de Destruição",
        description: "Energia concentrada pronta para estourar"
    },
    {
        id: "loc_shield_ring_g0",
        type: "accessories",
        name: "Anel de Escudo",
        description: "Peça de artesanato grosseira"
    },
    {
        id: "loc_astonish_ring_g0",
        type: "accessories",
        name: "Anel de Atordoamento",
        description: "Ornamento chamativo pronto para qualquer aventura"
    },
    // PETS
    {
        id: "loc_zero_g0",
        type: "pets",
        name: "Zero",
        description: "Um drone redondo com um tiro poderoso"
    },
    {
        id: "loc_guardian_g0",
        type: "pets",
        name: "Guardião",
        description: "Seu guardião favorito"
    },
    {
        id: "loc_cannon_g0",
        type: "pets",
        name: "Canhão",
        description: "O poder de três canhões"
    },
    // CONSUMABLES
    {
        id: "loc_character_chip",
        type: "consumables",
        name: "Chip de Personagem",
        description: "Um chip de upgrade usado para subir o nível dos personagens"
    },
    {
        id: "loc_backweapon_chip",
        type: "consumables",
        name: "Chip de Arma Secundaria",
        description: "Um chip de upgrade usado para subir o nível das armas secundárias"
    },
    {
        id: "loc_mainweapon_chip",
        type: "consumables",
        name: "Chip de Arma Principal",
        description: "Um chip de upgrade usado para subir o nível das armas principais"
    },
    {
        id: "loc_armor_chip",
        type: "consumables",
        name: "Chip de Armadura",
        description: "Um chip de upgrade usado para subir o nível das armaduras"
    },
    {
        id: "loc_ring_chip",
        type: "consumables",
        name: "Chip de Anel",
        description: "Um chip de upgrade usado para subir o nível dos aneis"
    },
    {
        id: "loc_pet_chip",
        type: "consumables",
        name: "Chip de Pet",
        description: "Um chip de upgrade usado para subir o nível dos pets"
    },
    // CURRENCY
    {
        id: "loc_hgs_coin",
        type: "currency",
        name: "GS",
        description: ""
    },
    {
        id: "loc_hom_coin",
        type: "currency",
        name: "HOM",
        description: ""
    }
];
export { crateItems };