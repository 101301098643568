import {BigNumber, ethers, Signer} from 'ethers';
import witelist from "../../configuration/witelist.json";
import {SuccessDto} from "../common/SuccessDto";
import {MetadataDto} from "./dtos/metadata.dto";
import {HeroesOfMetaverseItemToken__factory} from "../../typechain";
import axios from "axios";
import Toast from "../../utils/toast";
import {ContractTypeEnum, NftListRequestDto, NftListResponseDto} from "./dtos/nft-list-response.dto";
import { stashItemWallet } from '../../models/stash/stash';
import { retry } from 'ts-retry-promise';

export default class Web3Service {
    static async ConnectWallet(): Promise<SuccessDto> {
        let provider = await Web3Service.getProvider();
        if (provider) {
            try {
                var res = await provider.send("eth_requestAccounts", []);

                console.log(res);

                if(res.length > 0)
                    return { success: true }
                else
                    return { success: false }
            } catch (error) {
                let code: string;
                if (error.code === 4001) {
                    code = "4001";
                } else {
                    code = "4002";
                }
                return { success: false, error: code }
            }
        }
        else
            return { success: false, error: 'Web3NotFound' }
    }

    static async IsWalletConnected(): Promise<boolean> {
        let provider = await this.getProvider();
        if (provider) {
            //await provider.send("eth_requestAccounts", []);
            const accounts = await provider.listAccounts();
            return accounts.length > 0;
        }
        return false;
    }

    static async EnsureIsConnected(): Promise<boolean> {
        // connect
        try {
            if (!await this.IsWalletConnected())
                await this.ConnectWallet();
            if (!await this.IsWalletConnected())
                return false;
        }
        catch (e) {
            Toast.error(e.message);
            return false;
        }
        // change chain
        try {
            if (!await this.IsConnectedToChainId())
                await this.SwitchToBinance();
            if (!await this.IsConnectedToChainId())
                return false;
        } catch (e) {
            Toast.error(e.message);
            return false;
        }
        return true;
    }

    static async SwitchToBinance(): Promise<SuccessDto>{
        console.log("SwitchToBinance");
        let provider = await Web3Service.getProvider();
        if(provider) {
            console.log("SwitchToBinance has provider");

            try {
                const res = await provider.send('wallet_addEthereumChain',[{
                    chainId: ethers.utils.hexlify(Number(process.env.REACT_APP_CHAIN_ID)).replace('0x0', '0x'),
                    chainName: process.env.REACT_APP_CHAIN_NAME,
                    nativeCurrency: {
                        name: process.env.REACT_APP_CHAIN_CURRENCY,
                        symbol: process.env.REACT_APP_CHAIN_SYMBOL,
                        decimals: Number(process.env.REACT_APP_CHAIN_DECIMALS)
                    },
                    rpcUrls: [process.env.REACT_APP_CHAIN_RPC],
                    blockExplorerUrls: [process.env.REACT_APP_CHAIN_BLOCKEXPLORER]
                }]);
                console.log(res);
                return { success: true, code: '2510' };
            } catch (error) {
                console.log(error);
                return { success: false, error: '4010' };
            }
        }
    }

    static async IsConnectedToChainId(): Promise<boolean> {
        let provider = await Web3Service.getProvider();
        await provider.getNetwork();
        if (provider && await this.IsWalletConnected()) {
            return provider.network.chainId === Number(process.env.REACT_APP_CHAIN_ID);
        }
        return false;
    }

    static async getProvider(): Promise<ethers.providers.Web3Provider>{
        // @ts-ignore
        if ((window as any).ethereum)
            return new ethers.providers.Web3Provider((window as any).ethereum);
        else return null;
    }

    static async GetSigner(): Promise<Signer>{
        let provider = await this.getProvider();
        if (provider) {
            if(await this.IsWalletConnected())
                return provider.getSigner();
            else
                return null;
        }
        else
            return null;
    }

    static async GetWalletAddress(): Promise<string> {
        const signer = await this.GetSigner();
        if (!signer) {
            return null;
        }
        return await signer.getAddress();
    }

    // todo: refactor this return type
    static async IsOnWhiteList(): Promise<SuccessDto> {
        var signer = this.GetSigner();
        if (signer) {
            if (!await this.IsWalletConnected())
                return { success: false, error: '4001'};

            const signer = await this.GetSigner();

            let list = witelist.data;

            list = list.map(e => e.toLowerCase())

            return { success: true, data: list.includes(await signer.getAddress()) };
        }
        return { success: false, error: '0000'};
    }

    // TO-DO: FAZER FUNÇÃO PARA DISCONECTAR METAMASK
    static async DisconnectWallet(){
        return true;
    }

    public static async SignMessage(message: string): Promise<string> {
        const signer = await this.GetSigner();
        const messageHash = ethers.utils.solidityKeccak256(["string"], [message]);
        return await signer.signMessage(ethers.utils.arrayify(messageHash));
    }

    public static async GetMyCrates(): Promise<stashItemWallet[]>{
        console.log("Fetching HoM Crates from your wallet");

        try {
            if(false === await Web3Service.EnsureIsConnected())
                return [];
        } catch (e) {
            Toast.error(e.message);
            return [];
        }

        console.log("metamask is connected");

        var walletAddress = await (await this.GetSigner()).getAddress()
        var reqbody: NftListRequestDto = {type: ContractTypeEnum.crate, wallet: walletAddress};
        var res: stashItemWallet[] = [];

        var list = await axios.post<NftListResponseDto>(`${process.env.REACT_APP_CAL_SERVICE}/eth/getNfts`, reqbody);
        
        for (const elem of list.data.result)
            res.push(new stashItemWallet({
                id: elem.token_id, 
                metadataUri: elem.token_uri, 
                metadata: JSON.parse(elem.metadata), 
                contract: "crate"
            }))

        var shouldRefetch= list.data.page+1 < list.data.total/list.data.page_size;
        while (shouldRefetch) {
            reqbody = {type: ContractTypeEnum.crate, wallet: walletAddress, cursor: list.data.cursor};
            list = await axios.post<NftListResponseDto>(`${process.env.REACT_APP_CAL_SERVICE}/eth/getNfts`, reqbody);
            for (const elem of list.data.result)
                res.push(new stashItemWallet({
                    id: elem.token_id, 
                    metadataUri: elem.token_uri, 
                    metadata: JSON.parse(elem.metadata), 
                    contract: "crate"
                }))
            shouldRefetch= list.data.page+1 <= list.data.total/list.data.page_size;
        }

        return res;
    }

    public static async GetMyHomItems():Promise<stashItemWallet[]>{
        const signer = await this.GetSigner();
        console.log('signer', signer);
        const address = await signer.getAddress();
        console.log('address', address);
        const contract = HeroesOfMetaverseItemToken__factory.connect(process.env.REACT_APP_NFT_ADDRESS, signer);
        const balance: BigNumber = await retry(()=> contract.balanceOf(address));

        const ids: BigNumber[] = [];
        for(let i=ethers.constants.Zero; i.lt(balance); i=i.add(ethers.constants.One)) {
            ids.push(await retry(()=> contract.tokenOfOwnerByIndex(address, i)));
        }

        const ret: stashItemWallet[] = [];
        for(let i of ids) {
            const metadataUri: string = await retry(()=>contract.tokenURI(i));
            const internalId: string = metadataUri.split(process.env.REACT_APP_METADATA_URI)[1]
            ret.push(new stashItemWallet({
                id: i.toString(),
                contract: "item",
                metadataUri,
                internalId
            }));
        }
        return ret;
    }
}