import "./dash-logged.scss";
import React, { Component } from "react";

export default class DashboardLoggedPage extends Component {
    private constructor(readonly props: any) {
        super(props);
    }
    public readonly state = {};

    componentDidMount() {
        setTimeout(()=>{
            window.top.location.href = "/dashboard/home"
        }, 1500) // todo: why this?
    }

    render() {
        return (
            <section className="dash-logged-page">
                <div id="" className="box-text center Exo">
                    Login efetuado com sucesso! <br/>
                    Aguarde...
                </div>
            </section>
        );
    }
}
