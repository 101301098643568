import "./start-game.scss";
import React, { Component } from "react";
import parse from "html-react-parser";

import enviroment from "../../../../configuration/enviroment";
import LanguageController from "../../../../controllers/language/language";
import MetamaskController from "../../../../controllers/metamask/metamask";
import Navigation from "../../../../controllers/navigation/navigation";

export default class StartGameButtonComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
    MetamaskController.ctx = this;
  }
  public readonly state = {
    language: LanguageController.language(null),
    step: "connect",
    title: "",
  };

  async componentDidMount() {
    (await MetamaskController.IsWalletConnected()) &&
      (await MetamaskController.isConnectedToBinance());
    await this.getTitle();
  }

  async clickButton() {
    if (enviroment.DASHBOARD) {
      Navigation.push("/dashboard/login");
    }
  }

  async getTitle() {
    const data: any = this.state.language.shared.button_play;
    var title = "";
    if (enviroment.DASHBOARD) {
      title = data.play_game.title;
    } else {
      title = data.soon;
    }
    this.setState({ title: title });
  }

  render() {
    return (
      <div
        className="connect-wallet-button Ethnocentric noselect"
        onClick={() => this.clickButton()}
      >
        {parse(this.state.title)}
      </div>
    );
  }
}
