import React from "react";
import "./show-items.css";

export default class ShowItemsComponent extends React.Component{
    private constructor(readonly props: any){
        super(props);
    }

    render() {
        const { items, closeShowItemsDialog } = this.props;
        return (
            <div className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title Dystopian Bold">
                            Congratulations!
                        </h5>
                        <button
                            type="button" className="close" aria-label="Close"
                            onClick={()=>closeShowItemsDialog()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4 className="Dystopian Bold">
                            You gained the following items:
                        </h4>
                        <div className="gained-items-container">
                            {
                                items.map(item => (
                                    <img key={item.id} src={item.iconUrl} className="gained-item-icon"/>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div
                            className="button noselect Ethnocentric"
                            onClick={()=>closeShowItemsDialog()}
                        >
                            OK
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}