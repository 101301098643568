import React from "react";
import Emitter from "../../../utils/emitter";
import "./dialog-confirm.css";
import MetamaskController from "../../../controllers/metamask/metamask";
export default class DialogConfirmComponent extends React.Component {
    private constructor(readonly props: any) {
        super(props);

        this.dialog = this.dialog.bind(this);
    }
    public readonly state = {}

    async componentDidMount() {
        if (MetamaskController.connected) {
            await MetamaskController.GetSigner();
        }
    }

    dialog(type: any, open: boolean = false) {
        Emitter.emit('dialog', {
            name: this.props.name,
            type: type,
            open: open
        });
    }

    render() {
        const data = this.props.language;
        return (
            <div className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-image">
                                <img src={require("../../../assets/images/akali.png")} alt="modal-image" />
                            </div>
                            <div className="modal-title"></div>
                            <button
                                type="button" className="close" aria-label="Close"
                                onClick={() => this.dialog('close', false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="body-title Ethnocentric Bold">
                                {data.title}
                            </div>
                            <div className="body-subtitle Ethnocentric Bold">
                                {data.subtitle}
                            </div>
                        </div>
                        <div className="confirm-modal-footer middle">
                            <div
                                className="button noselect Ethnocentric"
                                onClick={() => this.dialog('close', false)}
                            >
                                {this.props.button}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}