import "./crates-list.scss";
import React, { Component } from "react";
import { userStashModel } from "../../models/user/user-stash";

import {
    cratesTypeByName,
    cratesColorByName,
} from "../../models/crates/crates";
import {
    stashItemModel,
} from "../../models/stash/stash";

import SocialLoginController from "../../controllers/social-login/social-login";
import LangController from "../../controllers/language/lang";
import MetamaskController from "../../controllers/metamask/metamask";
import CratesController from "../../controllers/crates/crates";
import ShowItemsComponent from "../../pages/crates/show-items/show-items";

const language = LangController.getLanguage(null);

export default class CratesListComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.getCrates = this.getCrates.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.closeShowItemsDialog = this.closeShowItemsDialog.bind(this);
        this.openCrate = this.openCrate.bind(this);
        MetamaskController.ctx = this;
    }
    public readonly state: any = {
        loading: true,
        crates: [],
        gainedItems: [],
        showItems: false,
    }

    async componentDidMount() {
        this.setLoading(true);
        const crates = await this.getCrates("ORDER_BY_CREATED_AT_ASC");
        this.setState({
            crates,
            loading: false,
        });
    }

    async openCrate(id: string) {
        const result = await CratesController.OpenCrate(id);
        const gainedItems = result.data;
        this.setState({
            gainedItems,
            showItems: true
        })
    }

    async getCrates(order: string) {
        const user = await SocialLoginController.getProfileState(order);
        const crates = user.crates.filter(c => c.baseItemId === 1).map(c => {
            const baseUrl = `https://hom-nfts.s3.sa-east-1.amazonaws.com/artifact-crate`;
            const rarityUrls = {
                "ITEM_RARITY_RARE": `${baseUrl}/rare.png`,
                "ITEM_RARITY_EPIC": `${baseUrl}/epic.png`,
                "ITEM_RARITY_LEGENDARY": `${baseUrl}/legendary.png`,
                "ITEM_RARITY_MYTHIC": `${baseUrl}/mythic.png`,
            }
            
            c.iconUrl = rarityUrls[c.rarity];
            return c;
        })
        return crates;
    }

    setLoading(data: any) {
        this.setState({
            loading: data
        });
    }

    closeShowItemsDialog() {
        window.location.href = "/dashboard/crates";
    }

    render() {
        const data = language.pages.stash;
        return (
            <section className="stash-items padding py-3">
                {
                    this.state.showItems && <ShowItemsComponent items={this.state.gainedItems} closeShowItemsDialog={this.closeShowItemsDialog}/>
                }
                {
                    !this.state.loading ?
                        this.state.crates.length > 0 ?
                            this.state.crates.map((item: stashItemModel, index: number) => (
                                <div key={index} className="item">
                                    <div className="image"
                                        style={{
                                            backgroundImage: `url(${item.iconUrl})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover"
                                        }}
                                    >
                                        <div className="crate-type">
                                            <div className="type">
                                                <div className={
                                                    `box ${cratesColorByName[item.rarity]}`
                                                }>
                                                    {language.shared.crates.type[cratesTypeByName[item.rarity]]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="h-100"></div>
                                        <div
                                                        className={`button-send-wallet Ethnocentric noselect`}
                                                        onClick={() => this.openCrate(item.id)}
                                                    >
                                                        Open
                                                    </div>
                                    </div>
                                </div>
                            ))
                            :
                            (
                                <div className="text-empty">
                                    {data.empty}
                                </div>
                            )
                        :
                        (
                            <div className="loading">
                                <img src={require("../../assets/images/loading.gif")} alt="Loading" />
                            </div>
                        )
                }
            </section>
        );
    }
}
