import "./carousel.scss";
import React, { Component } from "react";
import $ from "jquery";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

export default class CarouselComponent extends Component {
  private constructor(readonly props: any) {
    super(props);

    this.changeImageFull = this.changeImageFull.bind(this);
    this.removeImageFull = this.removeImageFull.bind(this);
  }
  public readonly state = {
    full: false,
    image: ""
  };

  async componentDidMount() {
    this.changeImageFull();
  }

  changeImageFull() {
    $('.alice-carousel__stage-item').on('click', (e: any) => {
      const image = e.target.currentSrc;
      this.setState({ full: true, image: image })
    });
  }

  removeImageFull() {
    this.setState({ full: false, image: '' })
  }

  render() {
    const data: any = this.props.language;
    const handleDragStart = (e: any) => e.preventDefault();

    const items = data.map((item: any, index: any) => (
      <img
        key={index}
        src={require(`../../../assets/${item.image}`)}
        onDragStart={handleDragStart}
        role="presentation"
        alt={item.title}
      />
    ));

    return (
      <section className="carousel-component">
        {
          this.state.full ?
            (
              <div className="image-full" onClick={() => this.removeImageFull()}>
                <span className="image-close" onClick={() => this.removeImageFull()}>x</span>
                <img src={this.state.image} title='image' alt="Image" />
              </div>
            ) : ("")
        }
        <AliceCarousel
          mouseTracking={false}
          animationDuration={500}
          infinite={false}
          disableButtonsControls={true}
          autoWidth={true}
          autoHeight={true}
          items={items}
        />
      </section>
    );
  }
}
