// todo: import this from the backend swagger

export class NftListResponseEntryDto {
    token_address: string;
    token_id: string;
    contract_type: string;
    owner_of: string;
    block_number: string;
    block_number_minted: string;
    token_uri: string;
    metadata: string;
    synced_at: string;
    amount: string;
    name: string;
    symbol: string;
    token_hash: string;
    last_token_uri_sync: string;
    last_metadata_sync: string;
}

export class NftListResponseDto {
    status: string;
    total: number;
    page: number;
    page_size: number;
    cursor: string;
    result: NftListResponseEntryDto[];
}

export enum ContractTypeEnum {
    // eslint-disable-next-line no-unused-vars
    native = 'native',
    // eslint-disable-next-line no-unused-vars
    item = 'item',
    // eslint-disable-next-line no-unused-vars
    crate = 'crate',
    // eslint-disable-next-line no-unused-vars
    hom = 'hom',
}

export class NftListRequestDto {
    wallet: string;
    type: ContractTypeEnum;
    cursor?: string;
}
