import { crateItems } from "./crates";
import { LanguageModel } from "../../../../models/language/language";
const language:LanguageModel = new LanguageModel({});

const shared = language.shared;

// LOGOTIPO
shared.language.select = "Escolha a sua linguagem";
shared.logotipo.image = "images/landing-page/logotipo.png";
shared.logotipo.url = "/";
// MEDIA
shared.media.discord = "https://discord.gg/VDdXGc5ywS";
shared.media.instagram = "https://www.instagram.com/heroesofmetaverseofficial/";
shared.media.twitter = "https://twitter.com/Heroesofmetav";
// COUNTDOWN
shared.countdown.days = "Dias";
shared.countdown.hours = "Horas";
shared.countdown.minutes = "Minutos";
shared.countdown.seconds = "Segundos";
// CRATES
shared.crates.type.common = "COMUM";
shared.crates.type.uncommon = "INCOMUM";
shared.crates.type.epic = "ÉPICO";
shared.crates.type.legendary = "LENDÁRIO";
shared.crates.type.mythic = "MÍTICO";
shared.crates.type.rare = "RARO";
// ITEMS
shared.crates.items = crateItems;
// ATTRIBUTES
shared.crates.attributes.atkSpeed = "Velo. ataque";
shared.crates.attributes.atkSpeedBonus = "Vel. atk. bônus";
shared.crates.attributes.damage = "Dano";
shared.crates.attributes.health = "Saúde";
shared.crates.attributes.shield = "Escudo";
// ALL
shared.await = "Aguarde...";

// DIALOG
// CONNECT
shared.dialog.connect.title = "Conectar com Wallet";
shared.dialog.connect.subtitle = "Lembrete";
shared.dialog.connect.resume = "Para acessar o jogo você precisa ter uma conta criada no Metamask e o plugin instalado em seu navegador.";
shared.dialog.connect.connect = "Conectar com Metamask";
shared.dialog.connect.switch = "Mudar para Binance";
// DEPOSIT
shared.dialog.deposit.title = "Depositar Crates";
shared.dialog.deposit.subtitle = "Lembrete";
shared.dialog.deposit.resume = "Você deve transferir as caixas que comprou para sua conta Heroes of Metaverse.";
shared.dialog.deposit.button = "Depositar Crates";
// DOWNLOAD
shared.dialog.download.title = "Download Launcher";
shared.dialog.download.subtitle = "Lembrete";
shared.dialog.download.resume = "É necessário baixar o launcher para poder acessar o jogo.";
shared.dialog.download.button = "Download do launcher";
// SEND TO WALLET
shared.dialog.send_wallet.title = "ENVIAR PARA CARTEIRA";
shared.dialog.send_wallet.subtitle = "Sua Carteira";
shared.dialog.send_wallet.resume = "Envie os seus itens do jogo para a sua carteira";
shared.dialog.send_wallet.button = "CONFIRMAR";
shared.dialog.send_wallet.retry = "TENTAR NOVAMENTE";
// SEND TO GAME
shared.dialog.send_game.title = "ENVIAR PARA O JOGO";
shared.dialog.send_game.subtitle = "Sua Wallet";
shared.dialog.send_game.resume = "Envie os itens da sua carteira para o jogo";
shared.dialog.send_game.button = "CONFIRMAR";
shared.dialog.send_game.retry = "TENTAR NOVAMENTE";
// CONFIRM SEND
shared.dialog.confirm_send.title = "FEITO!";
shared.dialog.confirm_send.subtitle = "Seu pedido foi registrado com sucesso.";
shared.dialog.confirm_send.resume = "";
shared.dialog.confirm_send.button = "OK";

// MESSAGES
shared.messages.withdraw = "Checando Wallet...";
shared.messages.profile = "Checando Perfil...";
shared.messages.gas = "Checando Taxas...";
shared.messages.funds = "Checando Saldo...";
shared.messages.transferFunds = "Transferindo Gas...";
shared.messages.transferNft = "Agendando Saque...";

export { shared }