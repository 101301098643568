import Api from "../api";
import {SuccessDto} from "../../services/common/SuccessDto";
import LangController from "../../controllers/language/lang";
import {languageTypes} from "../../models/language/types";

export default class SocialLoginRemote {
    static async validateCodeAfterLogin(hash: string) {
        var url = `${process.env.REACT_APP_HOSTED_UI}/oauth2/token?`;
        url += `client_id=${process.env.REACT_APP_CLIENT_ID}&`;
        url += "grant_type=authorization_code&";
        url += `code=${hash}&`;
        url += `redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

        return await Api.postExternalUrlHeader(url, {}, { "Content-Type": "application/x-www-form-urlencoded" });
    }

    static async walletChallenge() {
        return await Api.post("v1/players/wallet-challenge", {});
    }
    static async putWalletAddress(body: { walletAddress: string, challenge_signature: string }): Promise<SuccessDto> {
        return await Api.put("v1/players/wallet-address", JSON.stringify(body));
    }
    static async getProfileState(order= 'ORDER_BY_CREATED_AT_ASC'): Promise<SuccessDto> {
        LangController.getLanguage(null)
        const language = languageTypes[LangController.lang];

        return await Api.get(
            `v1/players?orderBy=${order}&language=${language}`
        );
    }
}