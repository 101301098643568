import { LanguageModel } from "../../../../models/language/language";

const language:LanguageModel = new LanguageModel({});
const dashLogin = language.pages.dash_login;

// BANNER
dashLogin.banner.title = "JOGUE. <br/> TROQUE. <br/> GANHE.";
dashLogin.banner.video = "banner-video-wide.mp4";
// LOGIN
dashLogin.login.title = "Comece sua jornada agora!";
dashLogin.login.resume = "Crie e acesse sua conta em Heroes Of Metaverse usando uma das contas sociais abaixo.";
dashLogin.login.back = "Voltar";
dashLogin.login.buttons.google.title = "Continue com Google";
dashLogin.login.buttons.google.url = `${process.env.REACT_APP_HOSTED_UI}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=email openid phone profile`;
dashLogin.login.buttons.google.active = true;
dashLogin.login.buttons.facebook.title = "Continue com Facebook";
dashLogin.login.buttons.facebook.url = `${process.env.REACT_APP_HOSTED_UI}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=email openid phone profile`;
dashLogin.login.buttons.facebook.active = false;
dashLogin.login.buttons.apple.title = "Continue com Apple";
dashLogin.login.buttons.apple.url = `${process.env.REACT_APP_HOSTED_UI}/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=email openid phone profile`;
dashLogin.login.buttons.apple.active = true;

export { dashLogin }