import { LanguageModel } from "../../../../models/language/language";
const language: LanguageModel = new LanguageModel({});

const stashPage = language.pages.stash;

stashPage.title = "YOUR <text-yellow> MOST VALUABLE </text-yellow> ITENS ARE HERE";
stashPage.empty = "There are no items to display!";
stashPage.cart.send_to_wallet = "Send to Wallet";
stashPage.cart.sent_to_game = "Send to Game";
stashPage.cart.wallet = "Processing Withdrawal...";
stashPage.cart.unit = "Unit";
stashPage.cart.tooltip = "There is a withdraw being processed, please wait until it is completed to begin another one.";
stashPage.cart.blocked = "This item can't be withdrawn";
stashPage.cart.blockedAssociate = "Verify you Wallet";
stashPage.cart.blockedTooltip = "Please, connect Wallet associte: ";
stashPage.cart.selectedItem = "item selected";
stashPage.cart.selectedItems = "items selecteds";
stashPage.menu.types = [
    {
        value: "backend",
        title: "In Game"
    },
    {
        value: "blockchain",
        title: "Blockchain"
    }
];
stashPage.menu.buttons = [
    {
        name: "all",
        title: "All Items"
    },
    // {
    //     name: "nfts",
    //     title: "NFTs"
    // },
    // {
    //     name: "swords",
    //     title: "Swords"
    // },
    // {
    //     name: "helmets",
    //     title: "Helmets"
    // },
    // {
    //     name: "armors",
    //     title: "Armors"
    // },
    // {
    //     name: "neckles",
    //     title: "Neckles"
    // },
    // {
    //     name: "axes",
    //     title: "Axes"
    // }
];
stashPage.menu.wallet = "My Wallet";
stashPage.menu.order = [
    {
        value: "ORDER_BY_CREATED_AT_ASC",
        title: "Latest"
    },
    {
        value: "ORDER_BY_CREATED_AT_DESC",
        title: "Older"
    }
];

export { stashPage };