class stashPageModel {
    title: string;
    empty: string;
    cart: cartModel;
    menu: menuModel;

    constructor(data: any) {
        this.title = data.title;
        this.empty = data.empty;
        this.cart = data.cart || new cartModel({});
        this.menu = data.menu || new menuModel({});
    }
}

class cartModel {
    send_to_wallet: string;
    sent_to_game: string;
    wallet: string;
    unit: string;
    tooltip: string;
    blocked: string;
    selectedItem: string;
    selectedItems: string;
    blockedAssociate: string;
    blockedTooltip: string;
    constructor(data: any) {
        this.send_to_wallet = data.send_to_wallet;
        this.sent_to_game = data.sent_to_game;
        this.wallet = data.wallet;
        this.unit = data.unit;
        this.tooltip = data.tooltip;
        this.blocked = data.blocked;
        this.selectedItem = data.selectedItem;
        this.selectedItems = data.selectedItems;
        this.blockedAssociate = data.blockedAssociate;
        this.blockedTooltip = data.blockedTooltip;
    }
}
class menuModel {
    wallet: string;
    buttons: Array<menuButtonModel>;
    types: Array<selectModel>;
    order: Array<selectModel>;

    constructor(data: any) {
        this.wallet = data.wallet;
        this.buttons = data.buttons || [];
        this.types = data.types || [];
        this.order = data.order || [];
    }
}
class menuButtonModel {
    name: string;
    title: string;
    constructor(data: any) {
        this.name = data.name;
        this.title = data.title;
    }
}
class selectModel {
    value: string;
    title: string;
    constructor(data: any) {
        this.value = data.value;
        this.title = data.title;
    }
}

export { stashPageModel };