export default class Navigation {
    static push(route: string) {
        window.location.href = route;
    }
    static back() {
        window.history.back()
    }
    static getQuery(id: string): string {
        const querys = (new URL(String(document.location))).searchParams;
        return querys.get(id);
    }
}