/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../common";
import type {
  RoyaltiesV2Upgradeable,
  RoyaltiesV2UpgradeableInterface,
} from "../../../../../contracts/@rarible/royalties-upgradeable/contracts/RoyaltiesV2Upgradeable";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address payable",
            name: "account",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "value",
            type: "uint96",
          },
        ],
        indexed: false,
        internalType: "struct LibPart.Part[]",
        name: "royalties",
        type: "tuple[]",
      },
    ],
    name: "RoyaltiesSet",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getRaribleV2Royalties",
    outputs: [
      {
        components: [
          {
            internalType: "address payable",
            name: "account",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "value",
            type: "uint96",
          },
        ],
        internalType: "struct LibPart.Part[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "royalties",
    outputs: [
      {
        internalType: "address payable",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint96",
        name: "value",
        type: "uint96",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506102b1806100206000396000f3fe608060405234801561001057600080fd5b50600436106100415760003560e01c806301ffc9a7146100465780638924af741461006e578063cad96cca146100a8575b600080fd5b6100596100543660046101d7565b6100c8565b60405190151581526020015b60405180910390f35b61008161007c366004610208565b6100ff565b604080516001600160a01b0390931683526001600160601b03909116602083015201610065565b6100bb6100b636600461022a565b610148565b6040516100659190610243565b60006001600160e01b0319821663656cb66560e11b14806100f957506301ffc9a760e01b6001600160e01b03198316145b92915050565b6033602052816000526040600020818154811061011b57600080fd5b6000918252602090912001546001600160a01b0381169250600160a01b90046001600160601b0316905082565b606060336000838152602001908152602001600020805480602002602001604051908101604052809291908181526020016000905b828210156101cc57600084815260209081902060408051808201909152908401546001600160a01b0381168252600160a01b90046001600160601b03168183015282526001909201910161017d565b505050509050919050565b6000602082840312156101e957600080fd5b81356001600160e01b03198116811461020157600080fd5b9392505050565b6000806040838503121561021b57600080fd5b50508035926020909101359150565b60006020828403121561023c57600080fd5b5035919050565b602080825282518282018190526000919060409081850190868401855b8281101561029757815180516001600160a01b031685528601516001600160601b0316868501529284019290850190600101610260565b509197965050505050505056fea164736f6c6343000809000a";

type RoyaltiesV2UpgradeableConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: RoyaltiesV2UpgradeableConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class RoyaltiesV2Upgradeable__factory extends ContractFactory {
  constructor(...args: RoyaltiesV2UpgradeableConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<RoyaltiesV2Upgradeable> {
    return super.deploy(overrides || {}) as Promise<RoyaltiesV2Upgradeable>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): RoyaltiesV2Upgradeable {
    return super.attach(address) as RoyaltiesV2Upgradeable;
  }
  override connect(signer: Signer): RoyaltiesV2Upgradeable__factory {
    return super.connect(signer) as RoyaltiesV2Upgradeable__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): RoyaltiesV2UpgradeableInterface {
    return new utils.Interface(_abi) as RoyaltiesV2UpgradeableInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RoyaltiesV2Upgradeable {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as RoyaltiesV2Upgradeable;
  }
}
