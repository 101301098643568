class dashLoginPageModel {
    banner: bannerComponent;
    login: loginComponent;

    constructor(data: any) {
        this.banner = data.banner || new bannerComponent({});
        this.login = data.login || new loginComponent({});
    }
}

class bannerComponent {
    title: string;
    video: string;
    constructor(data: any) {
        this.title = data.title;
        this.video = data.video;
    }
}

class loginComponent {
    title: string;
    resume: string;
    back: string;
    buttons: loginButtons
    constructor(data: any) {
        this.title = data.title;
        this.resume = data.resume;
        this.back = data.back
        this.buttons = data.buttons || new loginButtons({});
    }
}
class loginButtons {
    google: loginButton;
    facebook: loginButton;
    apple: loginButton;
    constructor(data: any) {
        this.google = data.google || new loginButton({});
        this.facebook = data.facebook || new loginButton({});
        this.apple = data.apple || new loginButton({});
    }
}
class loginButton {
    title: string;
    url: string;
    active: boolean;
    constructor(data: any) {
        this.title = data.title;
        this.url = data.url;
        this.active = data.active || false;
    }
}

export { dashLoginPageModel };