class dashHomePageModel {
    game: gameComponent;
    header: headerComponent;
    banner: bannerComponent;
    steps: stepsComponent;

    constructor(data: any) {
        this.game = data.game || new gameComponent({});
        this.header = data.header || new headerComponent({});
        this.banner = data.banner || new bannerComponent({});
        this.steps = data.steps || new stepsComponent({});
    }
}

class gameComponent {
    download: string;
    url_apple: string;
    url_google: string;

    constructor(data: any) {
        this.download = data.download;
        this.url_apple = data.url_apple;
        this.url_google = data.url_google;
    }
}

class headerComponent {
    logotipo: string;
    menu: Array<headerMenu>;
    menu_dropdown: headerMenuDropdown[];
    button: headerButtonPlay;

    constructor(data: any) {
        this.logotipo = data.logotipo;
        this.menu = data.menu || [];
        this.menu_dropdown = data.menu_dropdown || [];
        this.button = data.button || new headerButtonPlay({});
    }
}
class headerMenu {
    id: string;
    title: string;
    url: string;
    target: string;
    badge: string;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.url = data.url;
        this.target = data.target;
        this.badge = data.badge;
    }
}
class headerMenuDropdown {
    id: string;
    title: string;
    icon: string;
    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.icon = data.icon;
    }
}
class headerButtonPlay {
    associate: string;
    await: string;
    connect: string;
    deposit: string;
    download: string;
    play_game: headerPlayGame;
    start: string;
    switch: string;

    constructor(data: any) {
        this.associate = data.associate;
        this.await = data.await;
        this.connect = data.connect;
        this.deposit = data.deposit;
        this.download = data.download;
        this.play_game = data.play_game || new headerPlayGame({});
        this.start = data.start;
        this.switch = data.switch;
    }
}
class headerPlayGame {
    title: string;
    url: string;
    constructor(data: any) {
        this.title = data.title;
        this.url = data.url;
    }
}

class bannerComponent {
    title: string;
    video: string;
    constructor(data: any) {
        this.title = data.title;
        this.video = data.video;
    }
}

class stepsComponent {
    title: string;
    items: stepsItem[];
    constructor(data: any) {
        this.title = data.title;
        this.items = data.items || [];
    }
}
class stepsItem {
    id: stepsItemId;
    title: string;
    resume: string;
    info: string;
    button: stepsButton;

    constructor(data: any) {
        this.id = data.id || new stepsItemId({});
        this.title = data.title;
        this.resume = data.resume;
        this.button = data.button || new stepsButton({});
    }
}
class stepsItemId {
    title: string;
    color: string;
    constructor(data: any) {
        this.title = data.title;
        this.color = data.color;
    }
}
class stepsButton {
    mode: string;
    type: string;
    title: string;
    switch_binance: string;
    associate: string;
    new_associate: string;
    done: string;
    await: string;
    android: stepsButtonLink;
    apple: stepsButtonLink;
    href: string;

    constructor(data: any) {
        this.mode = data.mode;
        this.type = data.type;
        this.title = data.title;
        this.switch_binance = data.switch_binance;
        this.associate = data.associate;
        this.done = data.done;
        this.await = data.await;
        this.android = data.android;
        this.apple = data.apple;
        this.href = data.href
    }
}
class stepsButtonLink {
    title: string;
    url: string;
}

export { dashHomePageModel };