import moment from "moment";
import SocialLoginRemote from "../../remotes/rest/social-login";
import { userLoginModel } from "../../models/user/user-login";
import { userStashModel } from "../../models/user/user-stash";
import Crypt from "../../utils/crypt";
import Navigation from "../navigation/navigation";
import Toast from "../../utils/toast";
import Web3Service from "../../services/web3/web3.service";
import Api from "../../remotes/api";

export default class SocialLoginController {

    static async validateCodeAfterLogin(hash: string) {
        const send: any = await SocialLoginRemote.validateCodeAfterLogin(hash);
        if (send.success) {
            this.setUser(send.data);
        } else {
            Navigation.push('/dashboard/login');
        }
    }

    static setUser(data: userLoginModel) {
        const dateNow = new Date();
        data.timestamp = dateNow.toISOString();
        const encrypt = Crypt.encrypt(JSON.stringify(data));
        localStorage.setItem(process.env.REACT_APP_SESSION, encrypt);
        Navigation.push('/dashboard/home');
    }


    static async removeUser() {
        // WARNING!!!
        // before you try to fix google logout, make sure you read those issues
        // https://stackoverflow.com/questions/58154256/aws-cognito-how-to-force-select-account-when-signing-in-with-google
        // https://github.com/amazon-archives/amazon-cognito-auth-js/issues/68
        // until this date it is impossible to pass `prompt=select_account` to the login and make the user select which account to use
        // the suboptimal solution is to open https://accounts.google.com/logout and make the current user disconnect from everything, and I am not will.ng to do it

        localStorage.removeItem(process.env.REACT_APP_SESSION);
        var url = `${process.env.REACT_APP_HOSTED_UI}/logout?redirect_uri=${process.env.REACT_APP_WEBSITE}&logout_uri=${process.env.REACT_APP_WEBSITE}&client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=CODE`;
        window.open(url, "_self");
    }

    static async getProfileState(order = "ORDER_BY_CREATED_AT_ASC"): Promise<userStashModel> {
        let userResponse = await SocialLoginRemote.getProfileState(order);
        if(userResponse.success === false) {
            Toast.error(userResponse.error.toString()); // todo: Wan, pls adjust this
            return null;
        }
        const user = userResponse.data;
        return new userStashModel(user);
    }

    static getUser(): userLoginModel {
        const session = localStorage.getItem(process.env.REACT_APP_SESSION);
        if (session === null) {
            return null;
        }
        try {
            const decrypt = JSON.parse(Crypt.decrypt(session));
            if (decrypt.access_token !== undefined) {
                return decrypt;
            }
        } catch (e) {
            localStorage.removeItem(process.env.REACT_APP_SESSION);
        }
        return null;
    }

    static checkLogged(): boolean {
        const dateNow = new Date().toISOString();
        const user = this.getUser();
        if (user !== null) {
            const diff = moment(dateNow).diff(moment(user.timestamp));
            const duration = moment.duration(diff);
            if (duration.asHours() >= 24) {
                localStorage.removeItem(process.env.REACT_APP_SESSION);
                return false;
            }
            return true;
        } else {
            localStorage.removeItem(process.env.REACT_APP_SESSION);
            return false;
        }
    }

    static async associateWallet(): Promise<boolean> {
        const authResponse = await SocialLoginRemote.walletChallenge();
        if (authResponse.data.challenge) {
            const signer = await Web3Service.GetSigner();
            const address = (await signer.getAddress()).toLowerCase();
            const signedMessage = await signer.signMessage(authResponse.data.challenge);
            const putWallet = await SocialLoginRemote.putWalletAddress({
                walletAddress: address.toLowerCase(),
                challenge_signature: signedMessage
            });
            if (putWallet.success === true) {
                window.location.reload();
                return true;
            }
            else
                Toast.error(putWallet.error);
        }
        return false;
    }
}