import Toast from "../../utils/toast";
import CratesService from "../../services/crate/crates";
import LanguageController from '../language/language';
import Emitter from "../../utils/emitter";
import {
    GetCratesBalance,
    MultisenderApproveForAllCrates, MultiSenderDepositCrates,
    MultisenderIsApprovedForAllCrates
} from "../../services/web3/intentions/crate-deposit.intent";
import {SuccessDto} from "../../services/common/SuccessDto";
import {retry} from "ts-retry-promise";
import { stashItemModel } from "../../models/stash/stash";


const language = LanguageController.language(null);
export default class CratesController {

    static ctx: any = null;
    static myCrates: any = null;
    static isPurchasedCrate: boolean = false;
    static isDeposited: boolean = false;

    private static refresh() {
        if(this.ctx != null){
            this.ctx.setState({});
            Emitter.emit("CratesRefresh", "");
        }
    }

    static async BuyCrate(type: any) {
        const result: any = await CratesService.BuyCrate(type);
        if(result.success){
            Toast.success(language.success[result.code]);
            return true;
        }else{
            Toast.error(language.error[result.error]);
            return false;
        }
    }

    static async DepositCrates() {
        let result = await this.depositCrates();
        if(result){
            this.isDeposited = true;
            this.refresh();
            return result;
        }else{
            // Toast.error(language.error[`0000`]);
            console.error(result);
        }
        return false;
    }

    static async depositCrates(): Promise<boolean> {
        console.log("deposit CRATES");

        // check if the user have crates
        var balance = await GetCratesBalance();
        console.log("crates balance", balance);

        // transfer if the user have balance
        if(balance>0) {
            // approve transfer
            if (!await MultisenderIsApprovedForAllCrates()) {
                try {
                    Toast.info("Asking for approval to manage your crates"); // todo: move this to localization services
                    console.log("Asking for approval to manage your crates")
                    let tx = await MultisenderApproveForAllCrates();
                    console.log("waititng 1 confirmation")
                    await tx.wait(1);
                    console.log("confirmed",tx.hash);
                } catch (e) {
                    // todo: improve this error
                    Toast.error(e);
                    console.log(e);
                    return false;
                }
            }

            // send crates
            try {
                Toast.info("Requesting approval to send crates");
                let tx = await MultiSenderDepositCrates();
                console.log("crates deposited", tx.hash);
                console.log("waiting for 1 confirmation");
                await tx.wait(1);
                console.log("tx confirmed");
                Toast.success("Your crates will appear in your game in up to 10 minutes")
            } catch (e) {
                Toast.error(e.message);
                console.error(e);
                return false;
            }

            this.isDeposited = true;
        }


        // // todo: improve this
        // console.warn("REMOVE THIS BEFORE SENDING TO PROTUCTION!!!!")
        //
        // console.log("deposit ITEMS");
        //
        // // check if the user have crates
        // balance = (await GetItemsBalance()).toNumber();
        //
        // console.log("items balance", balance);
        //
        // // transfer if the user have balance
        // if(balance>0) {
        //     // approve transfer
        //     if (!await MultisenderIsApprovedForAllItems()) {
        //         try {
        //             Toast.info("Asking for approval to manage your Items"); // todo: move this to localization services
        //             console.log("Asking for approval to manage your Items");
        //             let tx = await MultisenderApproveForAllItems();
        //             console.log("waiting one confirmation");
        //             await tx.wait(1);
        //             console.log("confirmed", tx.hash);
        //         } catch (e) {
        //             // todo: improve this error
        //             Toast.error(e);
        //             console.log(e);
        //             return false;
        //         }
        //     }
        //
        //     // send crates
        //     try {
        //         Toast.info("Requesting approval to send items");
        //         console.log("Requesting approval to send items");
        //         let tx = await MultiSenderDepositItems();
        //         console.log("waiting 1 confirmation");
        //         await tx.wait(1);
        //         console.log("confirmed",tx.hash);
        //         Toast.success("Your crates will appear in your game in up to 10 minutes")
        //     } catch (e) {
        //         Toast.error(e);
        //         console.log(e);
        //         return false;
        //     }
        //
        //     this.isDeposited = true;
        // }


        if(this.isDeposited===false) {
            this.isDeposited = true;
            this.refresh();
        }
        return true;
    }

    static async CheckDepositCrates(): Promise<SuccessDto> {
        try {
            const result = await retry(()=>CratesService.CheckDepositCrates(), {retries: 5});
            if(result===0){
                this.isDeposited = true;
                this.refresh();
                return {success: true};
            }
            return { success: false };
        } catch (e) {
            console.log(e);
            Toast.error(e.message);
            this.isDeposited = false;
        }
    }

    static async CratesAvailable() {
        const result: any = await CratesService.CratesAvailable();
        if(result.success){
            return result;
        }else{
            return { success: false };
        }
    }

    static async HasPurchasedCrate() {
        this.isPurchasedCrate = true;
        return true;
        //const result: any = await CratesService.HasPurchasedCrate();
        //if(result.success){
        //    this.isPurchasedCrate = true;
        //    this.refresh();
        //    return result.data;
        //}else{
        //    Toast.error(language.error[result.error]);
        //}
        //return false;
    }

    static async CratesPurchased() {
        //const result: any = await CratesService.CratesPurchased();
        //if(!result.success){
        //    Toast.error(language.error[result.error]);
        //}
        //return result.data;

        // TO-DO: APENAS PARA TESTE
        const crate = {
            rare: 10,
            epic: 20,
            legendary: 30,
            mythic: 50
        };
        this.myCrates = crate;
        this.refresh();
        return crate;
    }
    
    static async OpenCrate(id: string) {
        let result: any = await CratesService.OpenCrate(id);
 
        if (result.success) {
            let items: stashItemModel[] = [];
            result.data.map(i => {
                i.imageUrl = `https://hom-item-assets.s3.amazonaws.com/icons/${i.name}.png`;
                i.iconUrl = i.imageUrl;
                items.push(new stashItemModel(i));
            })    
            
            return { success: true, data: items };
        }

        return {
            success: false
        }
    }
}