import "./items.scss";
import React, { Component } from "react";
import Emitter from "../../../utils/emitter";
import Toast from "../../../utils/toast";

import {
    cratesTypeByName,
    cratesColorByName,
    cratesSubtype,
    cratesSubtypeFilter
} from "../../../models/crates/crates";
import {
    stashItemModel,
} from "../../../models/stash/stash";

import SocialLoginController from "../../../controllers/social-login/social-login";
import LangController from "../../../controllers/language/lang";
import DialogsDashboardComponent from "../../shared/dialogs/dialogs";
import StashController from "../../../controllers/stash/stash";
import Web3Service from "../../../services/web3/web3.service";
import { SuccessDto } from "../../../services/common/SuccessDto";
import MetamaskController from "../../../controllers/metamask/metamask";

import Server from "../../../configuration/server.json";
import moment from "moment";

const language = LangController.getLanguage(null);

export default class ItemsStashComponent extends Component {
    private constructor(readonly props: any) {
        super(props);

        this.getItemsBackend = this.getItemsBackend.bind(this);
        this.getItemsBlockchain = this.getItemsBlockchain.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeWithdraw = this.changeWithdraw.bind(this);
        this.resetMultipleWithdraw = this.resetMultipleWithdraw.bind(this);
        this.checkAssociateWallet = this.checkAssociateWallet.bind(this);
        MetamaskController.ctx = this;
    }
    public readonly state: any = {
        selected: "all",
        loading: true,
        type: "",
        items: [],
        multiple: false,
        listWithdraw: [],
        walletAssociate: "",
        isAssociate: false,
        time: null,
    }

    async componentDidMount() {
        MetamaskController.emitters();
        await Promise.all([
            MetamaskController.IsWalletConnected(),
            MetamaskController.isConnectedToBinance(),
        ]);
        await this.checkAssociateWallet();

        Emitter.on("MetamaskRefresh", () => {
            this.setState({});
        })
        Emitter.on("menu-button", async (data: any) => {
            await this.getItems(data);
        });
        Emitter.on("confirm_send_wallet", async (data) => {
            await this.confirmSendToWallet(data);
        });
        Emitter.on("confirm_send_game", async (data) => {
            await this.confirmSendToGame(data);
        });
        await this.getItems({ type: "backend", filter: "all", order: "ORDER_BY_CREATED_AT_ASC" })
    }

    async checkWithDraw() {
        await StashController.checkWithdraw()
            .then((data: Boolean) => {
                Emitter.emit("isWhitedraw", data);
            })
    }

    changeWithdraw(id: string) {
        const listWithdraw: string[] = this.state.listWithdraw;
        const index = listWithdraw.indexOf(id);
        if (listWithdraw[index]) {
            listWithdraw.splice(index, 1);
        } else {
            listWithdraw.push(id);
        }
        this.setState({
            listWithdraw: listWithdraw,
            multiple: listWithdraw.length >= 1 ? true : false
        });
        console.log('listWithdraw', this.state.listWithdraw);
    }

    changeType(data: string) {
        this.setState({ type: data });
    }

    async getItems(data: { type: "backend" | "blockchain", filter: string, order: string }) {
        this.setLoading(true);
        await this.checkWithDraw();
        if (data.type === "blockchain") {
            await this.getItemsBlockchain();
        } else {
            await this.getItemsBackend(data.filter, data.order);
        }
    }

    async getItemsBlockchain() {
        const homItems = await Web3Service.GetMyHomItems();
        const formattedHomItems = await StashController.listItemByIds(homItems.map(h => h.internalId));
        const items = formattedHomItems.map(item => ({
            ...item,
            blocked: false,
            source: "blockchain",
            nftId: homItems.filter(i => i.internalId === item.id)[0].id,
            contract: "item",
        }));

        this.setState({
            items: items,
            selected: this.state.selected,
            loading: false,
            type: "blockchain"
        });
    }

    async getItemsBackend(filter: string | "all", order: string | "ORDER_BY_CREATED_AT_ASC") {
        const user = await SocialLoginController.getProfileState(order);
        var stash = [];
        if (filter === 'all') {
            stash = user.stash;
            stash.map((item: stashItemModel) => {
                item.blocked = this.checkPurchased(item);
                item.source = "backend";
                return item;
            });
        } else {
            stash.forEach((item: stashItemModel) => {
                if (cratesSubtypeFilter[cratesSubtype[item.subType]] === filter.toUpperCase()) {
                    item.blocked = this.checkPurchased(item);
                    item.source = "backend";
                    stash.push(item);
                }
            });
        }
        this.setState({
            items: stash,
            selected: filter,
            loading: false,
            type: "backend"
        });
    }

    checkRarity(data: string): boolean {
        if (
            data === 'ITEM_RARITY_COMMON' ||
            data === 'ITEM_RARITY_UNCOMMON'
        ) {
            return true;
        }
        return false;
    }

    checkPurchased(data: stashItemModel): boolean {
        return this.checkRarity(data.rarity);
        // TODO: remove after we validate that we don't need to do quarantine anymore
        // const dateNow = moment();
        // const created = moment(data.createdAt)
        // const diff = dateNow.diff(created, 'days');
        // if (
        //     (data.purchased && diff <= Server.min_days_whitdraw) ||
        //     this.checkRarity(data.rarity)
        // ) {
        //     return true;
        // }
        // return false;
    }

    checkBlocked(data: any): boolean {
        if (data.blocked) {
            return true;
        }
        if (
            MetamaskController.whitdraw && this.state.type === 'backend' ||
            !this.state.isAssociate
        ) {
            return true;
        }
        return false;
    }

    openModalSend(data: any) {
        const blocked = this.checkBlocked(data);
        if (!blocked) {
            Emitter.emit("dialog", {
                type: "open",
                name: data.source === "backend" ? "send_wallet" : "send_game",
                item: data
            });
        }
    }

    async confirmSendToGame(data: stashItemModel) {
        Emitter.emit("dialog-button-load", true);
        await this.checkAssociateWallet();
        if (!this.state.isAssociate) {
            Emitter.emit("dialog-button-load", false);
            Toast.error("Wallet not associate");
            return "";
        }
        const result: SuccessDto = await StashController.deposit(data);
        Emitter.emit("dialog-button-load", false);
        console.log('confirmSendToGame', result);
        if (!result.success) {
            if (result.error)
                Toast.error(result.error || "")
            Emitter.emit("dialog-button-retry", true);
        } else {
            Emitter.emit("dialog", {
                type: "open",
                name: "confirm_send"
            });
            Emitter.emit("dialog-button-retry", false);
            await this.getItems({
                type: this.state.type,
                filter: "all",
                order: undefined
            });
        }
    }

    async confirmSendToWallet(data: any) {
        // todo: wan after the confirmation the page needs to be reloaded to update the items already deposited
        Emitter.emit("dialog-button-load", true);

        await this.checkAssociateWallet();
        if (!this.state.isAssociate) {
            Emitter.emit("dialog-button-load", false);
            Toast.error("Wallet not associate");
            return "";
        }

        let list: string[] = [];

        this.state.multiple ? list = this.state.listWithdraw : list.push(data.id);

        const result = await StashController.withdraw(list);
        Emitter.emit("dialog-button-load", false);
        if (!result.success) {
            Toast.error(result.error)
            Emitter.emit("dialog-button-retry", true);
        } else {
            Emitter.emit("dialog", {
                type: "open",
                name: "confirm_send"
            });
            this.resetMultipleWithdraw();
            Emitter.emit("dialog-button-retry", false);
            await this.getItems({
                type: this.state.type,
                filter: "all",
                order: undefined
            });
        }
    }

    setLoading(data: any) {
        this.setState({
            loading: data
        });
    }

    getButtonTitle(lang: any, item: stashItemModel): string {
        let title = "";

        if (this.state.type === "backend") {
            if (item.blocked)
                title = lang.blocked;
            else
                if (MetamaskController.whitdraw)
                    title = lang.wallet;
                else if (!this.state.isAssociate)
                    title = lang.blockedAssociate;
                else
                    title = lang.send_to_wallet;
        } else {
            if (!this.state.isAssociate)
                title = lang.blockedAssociate;
            else
                title = lang.sent_to_game;
        }
        return title;
    }
    getButtonTitleMultipleWithdraw(lang: any): string {
        let title = "";

        if (this.state.type === "backend") {
            if (MetamaskController.whitdraw)
                title = lang.wallet;
            else
                title = lang.send_to_wallet;
        } else {
            title = lang.sent_to_game;
        }
        return title;
    }
    resetMultipleWithdraw() {
        this.setState({
            listWithdraw: [],
            multiple: false
        });
    }

    timeIntervalCheckAssociateWallet() {
        return setInterval(() => {
            this.checkAssociateWallet();
        }, 5000);
    }

    async checkAssociateWallet() {
        let walletAssociate = "";
        let isAssociate = false;
        let time = this.state.time;
        if (
            MetamaskController.isBinance
        ) {
            await Promise.all([
                SocialLoginController.getProfileState(),
                MetamaskController.GetSigner()
            ]).then((result) => {
                const profile = result[0];
                const wallet = result[1];
                walletAssociate = profile.walletAddress;
                if (profile.walletAddress === "") {
                    Emitter.emit('AssociatePermited', true);
                    Emitter.emit('IsAssociateWallet', false);
                }
                if (String(profile.walletAddress).toLowerCase() === String(wallet).toLowerCase()) {
                    Emitter.emit('IsAssociateWallet', true);
                    Emitter.emit('AssociatePermited', false);
                    isAssociate = true;
                    clearInterval(time);
                    time = null;
                } else {
                    this.resetMultipleWithdraw();
                    isAssociate = false;
                    if (time === null) {
                        time = this.timeIntervalCheckAssociateWallet();
                    }
                }
            });
        }
        this.setState({ walletAssociate: walletAssociate, isAssociate: isAssociate, time: time });
    }

    render() {
        const data = language.pages.stash;
        return (
            <section className="stash-items padding py-3">
                {
                    !this.state.loading ?
                        this.state.items.length > 0 ?
                            this.state.items.map((item: stashItemModel, index: number) => (
                                <div key={index} className="item">
                                    <div className="image"
                                        style={{
                                            backgroundImage: `url(${item.iconUrl})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover"
                                        }}
                                    >
                                        <div className="crate-checkbox">
                                            {
                                                MetamaskController.whitdraw ||
                                                this.checkBlocked(item) ||
                                                this.state.type === "blockchain" ? ("") :
                                                    (
                                                        <input
                                                            className="checkbox"
                                                            type="checkbox"
                                                            checked={this.state.listWithdraw[item.id]}
                                                            onClick={() => this.changeWithdraw(item.id)}
                                                        ></input>
                                                    )
                                            }
                                        </div>
                                        <div className="crate-type">
                                            <div className="type">
                                                <div className={
                                                    `box ${cratesColorByName[item.rarity]}`
                                                }>
                                                    {language.shared.crates.type[cratesTypeByName[item.rarity]]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="item-name">
                                            {item.name}
                                        </div>
                                        <div className="item-abilities">
                                            {
                                                item.abilities.map((abilitie: string, key: number) => (
                                                    <div className="abilitie Exo Bold" key={key}>
                                                        <img
                                                            src={require("../../../assets/icons/icon-stash-abilities.png")}
                                                            alt="icon-abilitie"
                                                        />
                                                        {abilitie}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="item-attributes">
                                            {
                                                item.attributes.damage === 0 ? ("") :
                                                    (
                                                        <div className="attr-item">
                                                            <div className="icon">
                                                                <img
                                                                    src={require("../../../assets/icons/icon-attributes-damage.png")}
                                                                    alt="damage"
                                                                />
                                                            </div>
                                                            <div className="value Exo Bold">+{item.attributes.damage}</div>
                                                            <div className="label Exo">
                                                                {language.shared.crates.attributes.damage}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                item.attributes.atkSpeed === 0 ? ("") :
                                                    (
                                                        <div className="attr-item">
                                                            <div className="icon">
                                                                <img
                                                                    src={require("../../../assets/icons/icon-attributes-atk-speed.png")}
                                                                    alt="attack speed"
                                                                />
                                                            </div>
                                                            <div className="value Exo Bold">{item.attributes.atkSpeed}</div>
                                                            <div className="label Exo">
                                                                {language.shared.crates.attributes.atkSpeed}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                item.attributes.atkSpeedBonus === 0 ? ("") :
                                                    (
                                                        <div className="attr-item">
                                                            <div className="icon">
                                                                <img
                                                                    src={require("../../../assets/icons/icon-attributes-atk-speed.png")}
                                                                    alt="attack speed"
                                                                />
                                                            </div>
                                                            <div className="value Exo Bold">+{item.attributes.atkSpeedBonus}</div>
                                                            <div className="label Exo">
                                                                {language.shared.crates.attributes.atkSpeedBonus}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                item.attributes.health === 0 ? ("") :
                                                    (
                                                        <div className="attr-item">
                                                            <div className="icon">
                                                                <img
                                                                    src={require("../../../assets/icons/icon-attributes-health.png")}
                                                                    alt="attack speed"
                                                                />
                                                            </div>
                                                            <div className="value Exo Bold">+{item.attributes.health}</div>
                                                            <div className="label Exo">
                                                                {language.shared.crates.attributes.health}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                item.attributes.shield === 0 ? ("") :
                                                    (
                                                        <div className="attr-item">
                                                            <div className="icon">
                                                                <img
                                                                    src={require("../../../assets/icons/icon-attributes-shield.png")}
                                                                    alt="attack speed"
                                                                />
                                                            </div>
                                                            <div className="value Exo Bold">{item.attributes.shield * 100}%</div>
                                                            <div className="label Exo">
                                                                {language.shared.crates.attributes.shield}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                        <div className="h-100"></div>
                                        {
                                            this.state.selected === 'wallet' ?
                                                (
                                                    <div className="amounts Circular bold">
                                                        {data.cart.unit}
                                                    </div>
                                                )
                                                :
                                                ("")
                                        }
                                        {
                                            this.state.multiple ?
                                                ("")
                                                :
                                                (
                                                    <div
                                                        className={`button-send-wallet Ethnocentric noselect ${this.checkBlocked(item) ? 'blocked' : ''}`}
                                                        onClick={() => this.openModalSend(item)}
                                                    >
                                                        <div className="tooltips Exo">
                                                            {
                                                                item.blocked ?
                                                                    data.cart.blocked
                                                                    :
                                                                    MetamaskController.whitdraw ?
                                                                        data.cart.tooltip
                                                                        :
                                                                        !this.state.isAssociate && !MetamaskController.associatePermited ?
                                                                        `${data.cart.blockedTooltip} ${this.state.walletAssociate.substring(0, 5)}...${this.state.walletAssociate.substring(37, 42)}`
                                                                         :
                                                                        ""
                                                            }
                                                        </div>
                                                        {
                                                            this.getButtonTitle(data.cart, item)
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            ))
                            :
                            (
                                <div className="text-empty">
                                    {data.empty}
                                </div>
                            )
                        :
                        (
                            <div className="loading">
                                <img src={require("../../../assets/images/loading.gif")} alt="Loading" />
                            </div>
                        )
                }
                {
                    !this.state.multiple ? ("") :
                        (
                            <div className="multiple-withdraw">
                                <div className="items-selected Exo">
                                    {this.state.listWithdraw.length} &nbsp;
                                    {
                                        this.state.listWithdraw.length <= 1 ?
                                            data.cart.selectedItem : data.cart.selectedItems
                                    }
                                </div>
                                <div
                                    className={`button-send-wallet Ethnocentric noselect`}
                                    onClick={() => this.openModalSend({ source: this.state.type, blocked: false })}
                                >
                                    <div className="tooltips Exo">
                                        {
                                            MetamaskController.whitdraw ?
                                                data.cart.tooltip
                                                :
                                                ''
                                        }
                                    </div>
                                    {
                                        this.getButtonTitleMultipleWithdraw(data.cart)
                                    }
                                </div>
                            </div>

                        )
                }
                <DialogsDashboardComponent />
            </section>
        );
    }
}
