import { LanguageModel } from "../../../../models/language/language";
const language: LanguageModel = new LanguageModel({});

const homePage = language.pages.home;

// BANNER
homePage.banner.title = "PLAY. <br/> TRADE. <br/> EARN.";
homePage.banner.video = "banner-video-wide.mp4";
// HEADER
homePage.header.menu = [
    {
        title: "whitepaper",
        url: "https://heroes-of-metaverse.gitbook.io/bem-vindos-ao-heroes-of-metaverse/",
        target: "_blank"
    }
];
// GAMEPLAY
homePage.gameplay.title = "GAMEPLAY";
homePage.gameplay.resume = "Heroes of Metaverse is a free-to-play, Roguelike, 3D game, where you fight against monsters and bosses to collect coins and itens.";
homePage.gameplay.video = "gameplay-alpha.mp4";
homePage.gameplay.button.title = "COMING SOON";
homePage.gameplay.button.url = "#";
homePage.gameplay.button.target = "_self";
// TECHNOLOGY
homePage.technology.title = "INNOVATIVE <br/> TECHNOLOGY";
homePage.technology.resume = "Heroes of Metaverse is a game tha uses blockchain technology for assets and currency. Our goal is to bring high-quality standards to the market, mixing the best features from the games and blockchain industries.";
homePage.technology.image = "innovate-background.png";
// THE GAME
homePage.game.title = "IN GAME";
homePage.game.resume = "Learn a little bit more about what you can expect in this adventure.";
homePage.game.carousel = [
    {
        title: "",
        image: "images/landing-page/in-game/1.png"
    },
    {
        title: "",
        image: "images/landing-page/in-game/2.png"
    },
    {
        title: "",
        image: "images/landing-page/in-game/3.png"
    }
];
// ROADMAP
homePage.roadmap.title = "ROAD MAP";
homePage.roadmap.items = [
    {
        title: "Q4 2021",
        date: "2021-12",
        active: true,
        list: [
            {
                title: "Game Concept",
                active: true
            },
            {
                title: "Conceptual Art",
                active: true
            },
            {
                title: "Whitepaper",
                active: true
            },
            {
                title: "Website",
                active: true
            }
        ]
    },
    {
        title: "Q1 2022",
        date: "2022-1",
        active: true,
        list: [
            {
                title: "Game Mechanic",
                active: true
            },
            {
                title: "NFT Sale",
                active: true
            },
            {
                title: "Gameplay Video",
                active: true
            }
        ]
    },
    {
        title: "Q2 2022",
        date: "2022-2",
        active: true,
        list: [
            {
                title: "Crate Opening",
                active: true
            },
            {
                title: "Pre-Alpha Launch",
                active: true
            }
        ]
    },
    {
        title: "Q3 2022",
        date: "2022-3",
        active: false,
        list: [
            {
                title: "Alpha Launch",
                active: false
            },
            {
                title: "Marketplace",
                active: false
            }
        ]
    },
    {
        title: "Future",
        date: "2022-4",
        active: false,
        list: [
            {
                title: "Co-Op",
                active: false
            }
        ]
    }
];
// INVESTORS
homePage.investors.title = "INVESTORS";
homePage.investors.images = [
    {
        image: "/images/landing-page/investors/iporanga.png",
        title: "Iporanga"
    },
    {
        image: "/images/landing-page/investors/big_bets.png",
        title: "Big Bets"
    },
    {
        image: "/images/landing-page/investors/prota-games.png",
        title: "Prota Games"
    },
    {
        image: "/images/landing-page/investors/class5.png",
        title: "Class 5"
    }
];
// FOOTER
homePage.footer.menu = [
    {
        title: "Terms of Use",
        url: "/terms/use",
        target: "_self"
    },
    {
        title: "Privacy Policy",
        url: "/terms/policy",
        target: "_self"
    }
];

export { homePage };