/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../common";
import type {
  LibPart,
  LibPartInterface,
} from "../../../../../contracts/@rarible/royalties/contracts/LibPart";

const _abi = [
  {
    inputs: [],
    name: "TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x607d610038600b82828239805160001a607314602b57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060335760003560e01c806364d4c819146038575b600080fd5b605e7f397e04204c1e1a60ee8724b71f8244e10ab5f2e9009854d80f602bda21b59ebb81565b60405190815260200160405180910390f3fea164736f6c6343000809000a";

type LibPartConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: LibPartConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class LibPart__factory extends ContractFactory {
  constructor(...args: LibPartConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<LibPart> {
    return super.deploy(overrides || {}) as Promise<LibPart>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): LibPart {
    return super.attach(address) as LibPart;
  }
  override connect(signer: Signer): LibPart__factory {
    return super.connect(signer) as LibPart__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): LibPartInterface {
    return new utils.Interface(_abi) as LibPartInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibPart {
    return new Contract(address, _abi, signerOrProvider) as LibPart;
  }
}
