import StashService from "../../services/stash/stash";
import {stashItemModel} from "../../models/stash/stash";
import {SuccessDto} from "../../services/common/SuccessDto";
import ItemService from "../../services/item/item";
import Toast from "../../utils/toast";

export default class StashController {
    static async deposit(data: stashItemModel): Promise<SuccessDto> {
        return await StashService.deposit(data);
    }

    static async withdraw(data: string[] = []): Promise<SuccessDto> {
        return await StashService.withdraw(data);
    }

    static async checkWithdraw(): Promise<Boolean> {
        return await StashService.checkWithdraw();
    }
    
    static async listItemByIds(itemIds: string[]): Promise<stashItemModel[]> {
        const output = await ItemService.listItemByIds(itemIds);
        const items = output.data.items as stashItemModel[];
        return items;
    }
}