import React, { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";

import HomePage from "../pages/home/home";
import DashboardPage from "../pages/dashboard/dashboard";
import DashboardLoginPage from "../pages/dash-login/dash-login";
import DashboardLoggedPage from "../pages/dash-logged/dash-logged";
import StashPage from "../pages/stash/stash";
import CratesPage from "../pages/crates/crates";
import MarketplacePage from "../pages/marketplace/marketplace";
import TermsPage from "../pages/terms/termsPage";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms/:id" element={<TermsPage />} />
        <Route path="/dashboard"  element={ <Navigate  to="/dashboard/login" /> } />
        <Route path="/dashboard/logged" element={<DashboardLoggedPage />} />
        <Route path="/dashboard/login" element={<DashboardLoginPage />} />
        <Route path="/dashboard/home" element={<DashboardPage />} />
        <Route path="/dashboard/stash" element={<StashPage />} />
        <Route path="/dashboard/crates" element={<CratesPage />} />
        <Route path="/dashboard/marketplace" element={<MarketplacePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
