import "./crates.scss";
import React, { Component } from "react";
import parse from 'html-react-parser';
import enviroment from "../../configuration/enviroment";

import Navigation from "../../controllers/navigation/navigation";
import SocialLoginController from "../../controllers/social-login/social-login";
import LanguageController from "../../controllers/language/language";
import LangController from "../../controllers/language/lang";
import MetamaskController from "../../controllers/metamask/metamask";

import HeaderDashboardComponent from "../../components/dashboard/header/header";
import CratesListComponent from "../../components/crates/crates-list";

const language = LangController.getLanguage(null);

export default class CratesPage extends Component {
    private constructor(readonly props: any){
        super(props);
    }
    public readonly state: any = {
        lang: LanguageController.language(null)
    }

    async componentDidMount(){
        await MetamaskController.CheckWalletInList();
        if (!SocialLoginController.checkLogged()) {
            Navigation.push("/");
        }
    }
    
    render() {
        const data = language.pages;
        return (
            <main className="stash">

                <HeaderDashboardComponent
                    language={ data.dashboard.header }
                    game={ data.dashboard.game }
                    button_connect={true}
                />

                <div className="stash-title Monument padding">
                   {
                        parse(data.crates.title
                            .replaceAll("<text-yellow>", "<span class='text-yellow'>")
                            .replaceAll("</text-yellow>", "</span>")
                        )
                   }
                </div>
                
                <CratesListComponent/>
                
            </main>
        );
    }
}