import { homePageModel } from "./elements/home-page";
import { dashLoginPageModel } from "./elements/dash-login-page";
import { dashHomePageModel } from "./elements/dash-home-page";
import { sharedComponentModel } from "./elements/shared";
import { stashPageModel } from "./elements/stash-page";
import { cratesPageModel } from "./elements/crates-page";

class LanguageModel {
    pages: pagesModel;
    shared: sharedComponentModel;

    constructor(data: any) {
        this.pages = data.pages || new pagesModel({});
        this.shared = data.shared || new sharedComponentModel({});
    }
}

class pagesModel {
    home: homePageModel;
    dash_login: dashLoginPageModel;
    dashboard: dashHomePageModel;
    stash: stashPageModel;
    crates: cratesPageModel

    constructor(data: any) {
        this.home = data.home || new homePageModel({});
        this.dash_login = data.dash_login || new dashLoginPageModel({});
        this.dashboard = data.dashboard || new dashHomePageModel({});
        this.stash = data.stash || new stashPageModel({});
        this.crates = data.crates || new cratesPageModel({})
    }
}

export { LanguageModel }