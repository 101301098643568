import ReactDOM from 'react-dom';
import App, {ga4react} from './App';
import './index.css';
import React from "react";

(async () => {
    await ga4react.initialize();

    ReactDOM.render(
        <App />,
        document.getElementById("root")
    );
})();

