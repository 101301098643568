import { LanguageModel } from "../../../../models/language/language";
const language: LanguageModel = new LanguageModel({});

const dashHome = language.pages.dashboard;

// GAME
dashHome.game.download = "https://protagames.wansilva.com/game_launcher.zip";
// HEADER
dashHome.header.logotipo = "logotipo.png";
dashHome.header.menu = [
    {
        id: "home",
        title: "Home",
        url: "/dashboard/home",
        target: "_self",
        badge: undefined
    },
    {
        id: "stash",
        title: "Stash",
        url: "/dashboard/stash",
        target: "_self",
        badge: undefined
    },
    {
        id: "crates",
        title: "Pre-sale crates",
        url: "/dashboard/crates",
        target: "_self",
        badge: undefined
    },
    {
        id: "marketplace",
        title: "Marketplace",
        url: "https://tofunft.com/collection/heroes-of-metaverse/items",
        target: "_blank",
        badge: undefined
    }
];
dashHome.header.menu_dropdown = [
    // {
    //     id: "stash",
    //     title: "Stash",
    //     icon: "icon-stash.png"
    // },
    // {
    //     id: "profile",
    //     title: "Public Profile",
    //     icon: "icon-profile.png"
    // },
    // {
    //     id: "account",
    //     title: "Account Setting",
    //     icon: "icon-account.png"
    // },
    {
        id: "logout",
        title: "Logout",
        icon: "icon-logout.png"
    }
];
dashHome.header.button.associate = "Associar Wallet";
dashHome.header.button.await = "Aguarde...";
dashHome.header.button.connect = "Conectar com Metamask";
dashHome.header.button.deposit = "Depositar Crates";
dashHome.header.button.download = "Download Game";
dashHome.header.button.play_game.title = "Play the Game";
dashHome.header.button.play_game.url = "/dashboard/home";
dashHome.header.button.start = "Play the Game";
dashHome.header.button.switch = "Mudar para Binance";
// BANNER
dashHome.banner.title = "JOGUE. <br/> TROQUE. <br/> GANHE.";
dashHome.banner.video = "banner-video-wide.mp4";
// STEPS
dashHome.steps.title = "JOGUE. TROQUE. GANHE";
dashHome.steps.items = [
    {
        id: {
            title: "1",
            color: "#FD7021"
        },
        title: "Associe sua conta com a Metamask que possui crates",
        resume: "Obtenha acesso a NFTs e criptomoedas no jogo e mande elas diretamente para sua carteira.",
        info: "ATENÇÃO! A carteira conectada é diferente da carteira associada anteriormente!",
        button: {
            mode: "button",
            type: "connect",
            title: "Conectar com Metamask",
            switch_binance: "Mudar para BNB Chain",
            associate: "Associar Wallet",
            new_associate: "Associar nova Wallet",
            done: "FEITO!",
            await: "Aguarde...",
            android: null,
            apple: null,
            href: null
        }
    },
    {
        id: {
            title: "2",
            color: "#2151FD"
        },
        title: "Deposite as caixas que você possui",
        resume: "Você vai abrir elas no jogo e ganhar novos NFTs.",
        info: "",
        button: {
            mode: "button",
            type: "open",
            title: "Depositar Crates",
            done: "FEITO!",
            await: "Aguarde...",
            associate: null,
            new_associate: null,
            switch_binance: null,
            android: null,
            apple: null,
            href: null
        }
    },
    {
        id: {
            title: "3",
            color: "#2151FD"
        },
        title: "Abra suas caixas",
        resume: "Você irá abri-las e ganhar NFTs",
        info: "",
        button: {
            mode: "redirect",
            type: "deposit",
            title: "Abrir caixas",
            done: "ALREADY DONE!",
            await: "await...",
            associate: null,
            new_associate: null,
            switch_binance: null,
            android: null,
            apple: null,
            href: "/dashboard/crates"
        }
    },
    {
        id: {
            title: "4",
            color: "#1BC098"
        },
        title: "Baixe o jogo",
        resume: "Jogue o jogo no seu smartphone. É necessário que você faça login na mesma conta e e-mail do seu cadastro. Os baús podem demorar até 2 min para cair na sua conta. ",
        info: "",
        button: {
            mode: "app",
            type: "download",
            title: "Download Launcher",
            done: "FEITO!",
            await: "Aguarde...",
            android: {
                url: "https://play.google.com/store/apps/details?id=com.protagames.hom",
                title: "Baixar com Google Play"
            },
            apple: {
                url: "https://apps.apple.com/us/app/heroes-of-metaverse/id6443685500",
                title: "Baixar para Apple Store"
            },
            associate: null,
            new_associate: null,
            switch_binance: null,
            href: null
        }
    }
]

export { dashHome };