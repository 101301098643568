import "./dash-login.scss";
import React, { Component } from "react";
import enviroment from "../../configuration/enviroment";

import Navigation from "../../controllers/navigation/navigation";

import SocialLoginController from "../../controllers/social-login/social-login";
import LoginDashLoginPageComponent from "../../components/dashboard/login/login";

export default class DashboardLoginPage extends Component {
    private constructor(readonly props: any) {
        super(props);
    }
    public readonly state: any = {}

    componentDidMount() {
        this.checkLiberate();
    }

    checkLiberate() {
        if (!enviroment.DASHBOARD) {
            Navigation.push("/");
        }
        if (SocialLoginController.checkLogged()) {
            Navigation.push("/dashboard/home");
        }
    }

    render() {
        return (
            <main className="dashboard-login">
                <LoginDashLoginPageComponent />
            </main>
        );
    }
}
