import "./dashboard.scss";
import React, { Component } from "react";
import enviroment from "../../configuration/enviroment";
import Navigation from "../../controllers/navigation/navigation";

import MetamaskController from "../../controllers/metamask/metamask";
import SocialLoginController from "../../controllers/social-login/social-login";
import LangController from "../../controllers/language/lang";

import BannerLandingPageComponent from "../../components/landing-page/banner/banner";
import HeaderDashboardComponent from "../../components/dashboard/header/header";
import StepsDashboardComponent from "../../components/dashboard/steps/steps";
import DialogsDashboardComponent from "../../components/shared/dialogs/dialogs";

const language = LangController.getLanguage(null);

export default class DashboardPage extends Component {
    private constructor(readonly props: any) {
        super(props);
        this.getHashParams = this.getHashParams.bind(this);
        MetamaskController.ctx = this;
        MetamaskController.emitters();
    }
    public readonly state: any = {
        hash: Navigation.getQuery("code"),
        loading: true
    }

    componentDidMount() {
        this.getHashParams();
        this.checkLiberate();
    }

    checkLiberate() {
        if (!enviroment.DASHBOARD) {
            Navigation.push("/");
        }
        if (!SocialLoginController.checkLogged() && this.state.hash === null) {
            Navigation.push("/");
        }
    }

    async getHashParams() {
        if (this.state.hash != null) {
            await SocialLoginController.validateCodeAfterLogin(this.state.hash);
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            this.state.loading ?
                <main className="loading">
                    <img src={require("../../assets/images/loading.gif")} alt="loading" />
                </main>
                :
                <main className="dashboard">
                    <HeaderDashboardComponent
                        button_connect={enviroment.SHOW_BUTTON_CONNECT}
                    />
                    <BannerLandingPageComponent
                        title={language.pages.dashboard.banner.title}
                        video={language.pages.dashboard.banner.video}
                        donwload={true}
                        go_to_id="#steps"
                    />
                    <div className="space-banner" />
                    <StepsDashboardComponent />
                </main>
        );
    }
}
