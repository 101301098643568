class homePageModel {
    banner: bannerComponent;
    header: headerComponent;
    technology: technologyComponent;
    gameplay: gameplayComponent;
    game: gameComponent;
    roadmap: roadmapComponent;
    investors: investorsComponent;
    footer: footerComponent;

    constructor(data: any) {
        this.technology = data.technology || new technologyComponent({});
        this.header = data.header || new headerComponent({});
        this.banner = data.banner || new bannerComponent({});
        this.gameplay = data.gameplay || new gameplayComponent({});
        this.game = data.game || new gameComponent({});
        this.roadmap = data.roadmap || new roadmapComponent({});
        this.investors = data.investors || new investorsComponent({});
        this.footer = data.footer || new footerComponent({});
    }
}

class headerComponent {
    menu: headerMenu[];

    constructor(data: any) {
        this.menu = data.menu || [];
    }
}
class headerMenu {
    title: string;
    target: string;
    url: string;

    constructor(data: any) {
        this.title = data.title;
        this.target = data.target;
        this.url = data.url;
    }
}

class bannerComponent {
    title: string;
    video: string;

    constructor(data: any) {
        this.title = data.title;
        this.video = data.video;
    }
}

class gameplayComponent {
    title: string;
    resume: string;
    video: string;
    button: gameplayButton;

    constructor(data: any) {
        this.title = data.title;
        this.resume = data.resume;
        this.video = data.video;
        this.button = data.button || new gameplayButton({});
    }
}
class gameplayButton {
    title: string;
    url: string;
    target: string;

    constructor(data: any) {
        this.title = data.title;
        this.url = data.url;
        this.target = data.target;
    }
}

class technologyComponent {
    title: string;
    resume: string;
    image: string;

    constructor(data: any) {
        this.title = data.title;
        this.resume = data.resume;
        this.image = data.image;
    }
}

class gameComponent {
    title: string;
    resume: string;
    carousel: Array<gameCarousel>

    constructor(data: any) {
        this.title = data.title;
        this.resume = data.resume;
        this.carousel = data.carousel || new gameCarousel({});
    }
}
class gameCarousel {
    title: string;
    image: string;
    constructor(data: any) {
        this.title = data.title;
        this.image = data.image;
    }
}

class roadmapComponent {
    title: string;
    items: Array<roadmapItem>;

    constructor(data: any) {
        this.title = data.title;
        this.items = data.items || [];
    }
}
class roadmapItem {
    title: string;
    date: string;
    active: boolean;
    list: Array<roadmapList>;
    constructor(data: any) {
        this.title = data.title;
        this.date = data.date;
        this.active = data.active || false;
        this.list = data.list || [];
    }
}
class roadmapList {
    title: string;
    active: boolean;
    constructor(data: any) {
        this.title = data.title;
        this.active = data.active || false;
    }
}

class investorsComponent {
    title: string;
    images: Array<investorsImages>
    constructor(data: any) {
        this.title = data.title;
        this.images = data.images || [];
    }
}
class investorsImages {
    image: string;
    title: string;

    constructor(data: any) {
        this.image = data.image;
        this.title = data.title;
    }
}

class footerComponent {
    menu: Array<footerMenu>;

    constructor(data: any) {
        this.menu = data.menu || [];
    }
}
class footerMenu {
    title: string;
    url: string;
    target: string;

    constructor(data: any) {
        this.title = data.title;
        this.url = data.url;
        this.target = data.target;
    }
}

export { homePageModel }