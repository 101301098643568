import "./stash.scss";
import React, { Component } from "react";
import parse from 'html-react-parser';
import enviroment from "../../configuration/enviroment";

import Navigation from "../../controllers/navigation/navigation";
import SocialLoginController from "../../controllers/social-login/social-login";
import LanguageController from "../../controllers/language/language";
import LangController from "../../controllers/language/lang";
import MetamaskController from "../../controllers/metamask/metamask";

import HeaderDashboardComponent from "../../components/dashboard/header/header";
import MenuStashComponent from "../../components/stash/menu/menu";
import ItemsStashComponent from "../../components/stash/items/items";

const language = LangController.getLanguage(null);

export default class StashPage extends Component {
    private constructor(readonly props: any){
        super(props);
    }
    public readonly state: any = {
        lang: LanguageController.language(null)
    }

    async componentDidMount(){
        await MetamaskController.CheckWalletInList();
        this.checkLiberate();
    }

    checkLiberate() {
        if (!enviroment.DASHBOARD) {
            Navigation.push("/");
        }
        if (!SocialLoginController.checkLogged()) {
            Navigation.push("/");
        }
        if (enviroment.CHECK_WITELIST && !MetamaskController.walletInList) {
            window.location.href = "/dashboard/home";
        }
    }

    render() {
        const data = language.pages;
        return (
            <main className="stash">

                <HeaderDashboardComponent
                    language={ data.dashboard.header }
                    game={ data.dashboard.game }
                    button_connect={true}
                />

                <div className="stash-title Monument padding">
                   {
                        parse(data.stash.title
                            .replaceAll("<text-yellow>", "<span class='text-yellow'>")
                            .replaceAll("</text-yellow>", "</span>")
                        )
                   }
                </div>
                
                <MenuStashComponent
                    language={ data.stash.menu }
                />

                <ItemsStashComponent />

            </main>
        );
    }
}