import {MetadataDto} from "../../services/web3/dtos/metadata.dto";

class stashItemModel {
    abilities: Array<string>;
    attributes: attributesModel;
    baseItemId: number;
    blocked: boolean;
    createdAt: string;
    description: string;
    generation: number;
    iconUrl: string;
    id: string;
    level: number;
    name: string;
    ownerId: string;
    purchased: boolean;
    rarity: string;
    subType: string;
    type: string;
    nftId: string;
    source: "backend" | "blockchain";
    contract: 'crate' | 'item'

    constructor(data: any) {
        this.abilities = data.abilities || "";
        this.attributes = data.attributes || new attributesModel({});
        this.baseItemId = data.baseItemId || 0;
        this.blocked = data.blocked || false;
        this.createdAt = data.createdAt || "";
        this.description = data.description || "";
        this.generation = data.generation || 0;
        this.iconUrl = data.imageUrl || "";
        this.id = data.id || "";
        this.level = data.level  || 0;
        this.name = data.name || "";
        this.ownerId = data.ounerId || "";
        this.purchased = data.purchased;
        this.rarity = data.rarity || "";
        this.subType = data.subType || "";
        this.type = data.type || "";
        this.source = data.source;
        this.contract = data.contract;
        this.nftId = data.nftId;
    }
}

class attributesModel {
    atkSpeed: number;
    atkSpeedBonus: number;
    damage: number;
    health: number;
    shield: number;

    constructor(data: any) {
        this.atkSpeed = data.atkSpeed || 0;
        this.atkSpeedBonus = data.atkSpeedBonus || 0;
        this.damage = data.damage || 0;
        this.health = data.health || 0;
        this.shield = data.shield || 0;
    }
}

// todo: wan see MetadataDTO  from "../../services/web3/dtos/metadata.dto";
class stashItemWallet {
    id: string;
    metadata: itemWalletMedatada;
    metadataUri: string;
    contract: "item" | "crate";
    internalId: string;

    constructor(data: any) {
        this.id = data.id;
        this.metadata = data.metadata || new itemWalletMedatada({});
        this.metadataUri = data.metadataUri;
        this.contract = data.contract
        this.internalId = data.internalId
    }
}

class itemWalletMedatada {
    attributes: itemWalletAttributes[];
    description: string;
    external_url: string;
    image: string;
    name: string;
    constructor(data: any) {
        this.attributes = data.attributes || new itemWalletAttributes({});
        this.description = data.description || "";
        this.external_url = data.external_url || "";
        this.image = data.image || "";
        this.name = data.name || "";
    }
}
class itemWalletAttributes {
    display_type: string;
    trait_type: string;
    value: string;
    constructor(data: any) {
        this.display_type = data.display_type || "";
        this.trait_type = data.trait_type || "";
        this.value = data.value || "";
    }
}

export { stashItemModel, attributesModel, stashItemWallet };
