import "./banner.scss";
import React, { Component } from "react";
import TitleEffectComponent from "../../elements/title-effect/title-effect";

export default class BannerLandingPageComponent extends Component {
  private constructor(readonly props: any) {
    super(props);
  }
  public readonly state = {};

  async componentDidMount() { }

  render() {
    const title = this.props.title;
    const video = this.props.video;
    const download: boolean = this.props.donwload;
    return (
      <section className="banner-landing-page-component">
        <div className="banner-video-box">
          <video
            className="banner-video"
            preload="true"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src={require(`../../../assets/video/${video}`)}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="banner-content">
          <div className="banner-title">
            <TitleEffectComponent
              language={title}
              fontSize={"70px"}
              fontSizeEffect={"90px"}
            />
          </div>
          <div className="banner-download">
            {
              !download ? ("") :
                (
                  <a href={this.props.go_to_id}>
                    <img
                      src={require("../../../assets/icons/ic-down.png")}
                      alt="Icon Download"
                    />
                  </a>
                )
            }
          </div>
        </div>
      </section>
    );
  }
}
