import { LanguageModel } from "../../../models/language/language";
import { homePage } from "./elements/home-page";
import { dashLogin } from "./elements/dash-login-page";
import { dashHome } from "./elements/dash-home-page";
import { stashPage } from "./elements/stash";
import { cratesPage } from "./elements/nft-crates";
import { shared } from "./elements/shared";


const languageEnUS: LanguageModel = new LanguageModel({});

languageEnUS.pages.home = homePage;
languageEnUS.pages.dash_login = dashLogin;
languageEnUS.pages.dashboard = dashHome;
languageEnUS.pages.stash = stashPage;
languageEnUS.pages.crates = cratesPage;
languageEnUS.shared = shared;

export default languageEnUS;