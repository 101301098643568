import enviroment from "../../configuration/enviroment";
import ptBR from '../../configuration/language/pt-br.json';
import enUS from '../../configuration/language/en-us.json';

export default class LanguageController {
    static lang: string = "";

    static language(type: any | null){
        const lang = localStorage.getItem('lang');
        if(lang === null){
            if(type === undefined || type === null){
                type = enviroment.LANGUAGE;
            }
            this.lang = type;
            return this.change_language(type);
        }else{
            this.lang = lang;
            return this.change_language(lang);
        }
    }
    static change_language(type: any){
        this.lang = type;
        switch(type){
            case 'pt-br':
                return ptBR;
            case 'en-us':
                return enUS;
            default: break;
        }
    }
}