import CryptoJS, { MD5 } from 'crypto-js';

export default class Crypt {

  static encrypt(txt: string) {
    if (txt === null || txt === undefined) {
      return "";
    }
    if (typeof txt === 'object') {
      txt = JSON.stringify(txt);
    }
    const code: any = this.codes();
    const cipher = CryptoJS.AES.encrypt(
      txt,
      CryptoJS.enc.Utf8.parse(code.primary),
      {
        iv: CryptoJS.enc.Utf8.parse(code.secondary),
        mode: CryptoJS.mode.CBC,
      }
    );

    return cipher.toString();
  }
  static decrypt(txt: string) {
    if (txt === null || txt === undefined) {
      return "";
    }
    const code: any = this.codes();
    const cipher = CryptoJS.AES.decrypt(
      txt,
      CryptoJS.enc.Utf8.parse(code.primary),
      {
        iv: CryptoJS.enc.Utf8.parse(code.secondary),
        mode: CryptoJS.mode.CBC,
      }
    );
    return CryptoJS.enc.Utf8.stringify(cipher).toString();
  }

  private static codes() {
    var URL = process.env.REACT_APP_SERVER_URL!;
    return {
      primary: String(MD5(URL.split('').reverse().join(''))),
      secondary: String(MD5(URL.substring(0, 10).split('').reverse().join(''))),
    };
  }
}